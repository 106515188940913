import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../js/api';
import { GenericLoading } from './helpers';
import { useError } from '../Error';

import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';

import _ from 'lodash';

const IArchiveObj = (props: {
  id: String,
  data?: { id: String; _reference: String },
  onClose: any
}) => {
  const { t } = useTranslation();

  const [disableConfirm, setDisableConfirm] = useState(false);

  let title = 'ARCHIVE';

  if (props.data) {
    title = `${ t('ARCHIVE_CONFIRM_TITLE') } ${ props.data._reference }`;
  }

  const addError = useError();

  const onArchive = async (prop: { id: String }) => {
    try {
      setDisableConfirm(true);
      const a = await API.archive(prop.id);

      addError({
        title: t('GENERIC_OBJECT_ARCHIVED'),
        text: t('GENERIC_OBJECT_ARCHIVED_DESCRIPTION'),
        color: 'success',
        iconType: 'checkInCircleFilled',
      });
      setDisableConfirm(false);
      props.onClose(prop.id);
    } catch (err) {
      addError({
        title: err.message,
        color: 'danger',
        iconType: 'help',
      });
      setDisableConfirm(false);
      props.onClose(prop.id);
    }
  };

  let Res = <></>;

  if (props.id || props.data) {
    Res = (
      <EuiOverlayMask>
        <EuiConfirmModal
          title={ title }
          onCancel={ props.onClose }
          onConfirm={ () => {
            onArchive({ id: props?.data?.id || props.id });
          } }
          confirmButtonDisabled={ disableConfirm }
          cancelButtonText={ t('ARCHIVE_CANCEL') }
          confirmButtonText={ t('ARCHIVE_CONFIRM') }
          buttonColor="danger"
          defaultFocusedButton="confirm"
        >
          <p>{ t('ARCHIVE_CONFIRM_MSG') }</p>
          <p>{ t('ARCHIVE_CONFIRM_MSG_LINE2') }</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    );
  }

  return <Fragment>{ Res }</Fragment>;
};

export { IArchiveObj as default, IArchiveObj };
