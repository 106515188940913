export default {
  settings: {
    textStyle: 'reverse',

    title: '_reference',
    i18nTitleSuffix: 'DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      width: '10%',
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'image',

      data: 'payload.metadata.sourceAttachmentId',
      i18nTitle: 'MAIN_IMAGE',
      sortable: true,
      type: 'image',
      showOnMobile: true,
      size: 'l',
      alt: 'payload.metadata.sourceAttachmentId',
      allowFullScreen: false,
      hasShadow: true
    },
    {
      field: 'recognition_status',

      data: 'payload.metadata.recognitionStatus',
      i18nTitle: 'RECOGNITION_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'faces_count',

      data: 'payload._faces',
      i18nTitle: 'FACES_COUNT',
      sortable: true,
      type: 'arrayCount',
      showOnMobile: true
    },
    {
      field: 'recognition_count',

      data: 'payload.recognitions',
      i18nTitle: 'GENERIC_RECOGNITION_COUNT',
      sortable: true,
      type: 'arrayCount',
      showOnMobile: true
    },
    {
      field: 'recognitions',

      data: 'payload.recognitions',
      i18nTitle: 'FR_MATCHES',
      sortable: 'false',
      type: 'table',
      showOnMobile: true,
      tableSchema: [
        {
          field: 'confidence',
          data: 'confidence',
          i18nTitle: 'GENERIC_CONFIDENCE',
          sortable: true,
          type: 'numberRound'
        },
        {
          field: 'detected_profile',

          data: 'detectedProfileId',
          i18nTitle: 'FR_PROFILE',
          sortable: false,
          showOnMobile: true,
          type: 'b3ObjectField',

          display: {
            field: 'detected_profile_link',
            data: [ '/forensic/fr/profiles/', 'id' ],
            type: 'link',
            label: '_reference'
          }
        }
      ]
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};
