import React, { useEffect, useState, Fragment } from 'react';
import {
  EuiSwitch
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { default as B3 } from '../../../../js/api';
import { useError } from '../../../Error';



const IFormFieldSwitch = ( { label, onChange, defaultValue } ) => {

  const [ checked, setChecked ] = useState( false );


  const { t } = useTranslation();

  const addError = useError();

  //Handle if default is set
  useEffect( () => {
    ( async () => {
      console.log( 'IFormFieldSwitch', 'defaultValue', defaultValue );
      if ( defaultValue?.toString() == 'true' ) {
        setChecked( true );
        handleChange( { target: { checked: true } } );
      }
    } )();
  }, defaultValue );

  const handleChange = async ( v ) => {

    if ( v.target.checked ) {
      setChecked( true );
    } else {
      setChecked( false );
    }
    onChange( v );

  };
  return (
    <Fragment>
      <EuiSwitch
        label={t( label )}
        checked={checked}
        onChange={( v ) => handleChange( v )} />
    </Fragment>
  );
};

export { IFormFieldSwitch as default, IFormFieldSwitch };
