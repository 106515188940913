import {ADD_ERROR, REMOVE_ERROR} from '../constants/action-types'

export default function todos(state = [], action) {
  switch (action.type) {
    case ADD_ERROR:
      return state.concat([{...action.payload, id: (state.length + 1).toString()}]);
    case REMOVE_ERROR:
        return state.filter(er => er.id !== action.payload.id);
    default:
      return state;
  }
}
