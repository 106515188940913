import React, { useEffect, useState, Fragment } from 'react';
import {
  EuiSuperSelect
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';




const IFormFieldSelect = ( { options, defaultValue, isInvalid, onChange } ) => {

  const [ value, setValue ] = useState( null );
  const [ selectOptions, setSelectOptions ] = useState( [] );

  const { t } = useTranslation();


  useEffect( () => {
    ( async () => {
      if ( options ) {
        const tmpOptions = [];
        for ( let option of options ) {
          tmpOptions.push( {
            inputDisplay: t( option.i18nTitle ),
            value: option.value,
            disabled: option.disabled || false
          } );
        }
        setSelectOptions( tmpOptions );
      }
      if ( defaultValue ) {
        setValue( defaultValue );
        handleChange( defaultValue );
      }
    } )();
  }, [ options, defaultValue ] );

  const handleChange = ( v ) => {
    setValue( v );
    onChange( { target: { value: v } } );
  };

  return (
    <EuiSuperSelect
      options={selectOptions}
      valueOfSelected={value}
      isInvalid={isInvalid}
      onChange={( v ) => handleChange( v )} />
  );
};

export { IFormFieldSelect as default, IFormFieldSelect };
