
import React, { Component } from 'react';
import { EuiToolTip } from '@elastic/eui';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';




const FormatedDate = ( { data, settings } ) => {

  const { t } = useTranslation();

  let render = <></>;

  let moment = <></>;

  try {

    moment = <Moment format={settings.format} fromNow={settings.relative}>
      {data}
    </Moment>;

    if ( settings.relative && settings.showToolTip ) {
      render = <EuiToolTip position="top" content={<Moment format={settings.toolTipFormat} local >{data}</Moment>} >
        {moment}
      </EuiToolTip>;
    } else {
      render = moment;
    }

    return ( render );
  } catch ( err ) {
    console.error( 'FormattedDate error', err );
    return ( {
      resp: <div>NA ({this.props.data})</div>
    } );
  }
};

export { FormatedDate };
