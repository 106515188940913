
import React, { Component } from 'react';
import { EuiLink } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';



const FormatedLink = ( { data, fullData, settings } ) => {

  const { t } = useTranslation();

  let render = <></>;

  try {

    let label = _.get( fullData, settings.label, settings.label || data );

    if ( settings.trimAfter ) {
      label = label.substr( 0, settings.trimAfter ) + '...';
    }

    //Handle external target
    settings.target = '';
    if ( settings.external ) {
      settings.target = '_blank';
    }
    render = <EuiLink href={data} external={settings.external} target={settings.target}>
      {label}
    </EuiLink>;


    return render;
  } catch ( err ) {
    console.error( 'FormattedDate error', err );
    return ( {
      resp: <div>NA ({this.props.data})</div>
    } );
  }
};

export { FormatedLink };
