const schema = {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'payload.tweets', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    action: {
    }
  },
  query: {
    kind: 'bsTimeline',
    project_id: 'oYsfCBg3ek44zbru0',
    orderBy: 'payload.tweets desc',
    operator: 'and',
    collapse: 'payload.entity',
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      data: 'payload.bsLevel',
      i18nTitle: 'ANOMALY_LEVEL',
      sortable: true,
      type: 'progressBar',
      showOnMobile: true,
      progressBarSettings: {
        withColors: true,
        size: 'l'
      }
    },
    {
      mame: 'payload.entity',
      data: [ 'https://twitter.com/search/?q=', 'payload.entity' ],
      i18nTitle: 'ENTITY',
      external: true,
      sortable: false,
      type: 'link',
      label: 'payload.entity',
      showOnMobile: true
    },

    {
      data: 'payload.tweets',
      i18nTitle: 'TWEETS_COUNT',
      sortable: true,
      type: 'numberRound',
      showOnMobile: true
    },
    {
      data: 'payload.accounts',
      i18nTitle: 'ACCOUNTS_COUNT',
      sortable: true,
      type: 'numberRound',
      showOnMobile: true
    },
    {
      name: 'payload.botness',
      data: [ 'payload.botness', '/5' ],
      i18nTitle: 'BOTNESS_LEVEL',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      data: 'payload.lastSeen',
      i18nTitle: 'LAST_SEEN',
      sortable: true,
      relative: true,
      type: 'formatedDate',
      //  format: 'MMMM Do YYYY, h:mm:ss a'
    }
  ]
};

export { schema as default };
