import React, { useState, useEffect } from 'react';
import PageLayout from '../../../ui/PageLayout';
import { IListObjects, FormBuilder, FilterBuilder } from '../../../jsFnI';
import {
  EuiButton
} from '@elastic/eui';

import {
  useRedirect,
} from '../../../../jas/state';

import {
  setBreadcrumbs,
} from '../../../../js/actions/index';

import { useTranslation } from 'react-i18next';

import listSchema from '../../../../repository/schemas/entities/list';
import filterSchema from '../../../../repository/schemas/entities/filter';
import createSchema from '../../../../repository/schemas/entities/create';
import describeSchema from '../../../../repository/schemas/entities/describe';

import { useError } from '../../../Error';
import store from '../../../../jas/store';


const Page = () => {
  console.log( 'page load' );
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( false );
  const [ forceReload, setForceReload ] = useState( false );
  const [ filters, setFilters ] = useState( {} );
  const addError = useError();

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'FINDER', href: '/' },
        { i18nText: 'ENTITIES', href: '/' }
      ] ) );
  }, [] );



  let flyout = <></>;

  if ( showAddPopup == true ) {
    flyout = <FormBuilder schema={createSchema} close={() => setShowAddPopup( false )} reload={( v ) => { setForceReload( v ); }} />;
  }



  const filterUpdateHandler = ( v ) => {
    setFilters( v );
  };

  const filterBuilder = <FilterBuilder schema={filterSchema} onUpdate={filterUpdateHandler} />;


  const createButton = ( <EuiButton iconType='listAdd'
    size='s' onClick={() => { setShowAddPopup( true ); }}>
    {t( 'GENERIC_CREATE' )}
  </EuiButton> );


  return (
    <PageLayout headerLeft={filterBuilder} headerRight={createButton}>
      {flyout}
      <IListObjects
        query={listSchema.query}
        filters={filters}
        listSchema={listSchema}
        describeSchema={describeSchema}
        describeSize='s'
        forceReload={forceReload}
      />
    </PageLayout>
  );
};

export { Page as default };
