const countries = [
  { nm: 'Lebanon', cod: 'LB' },
  { nm: 'Syrian Arab Republic', cod: 'SY' },
  { nm: 'Afghanistan', cod: 'AF' },
  { nm: 'land Islands', cod: 'AX' },
  { nm: 'Albania', cod: 'AL' },
  { nm: 'Algeria', cod: 'DZ' },
  { nm: 'American Samoa', cod: 'AS' },
  { nm: 'AndorrA', cod: 'AD' },
  { nm: 'Angola', cod: 'AO' },
  { nm: 'Anguilla', cod: 'AI' },
  { nm: 'Antarctica', cod: 'AQ' },
  { nm: 'Antigua and Barbuda', cod: 'AG' },
  { nm: 'Argentina', cod: 'AR' },
  { nm: 'Armenia', cod: 'AM' },
  { nm: 'Aruba', cod: 'AW' },
  { nm: 'Australia', cod: 'AU' },
  { nm: 'Austria', cod: 'AT' },
  { nm: 'Azerbaijan', cod: 'AZ' },
  { nm: 'Bahamas', cod: 'BS' },
  { nm: 'Bahrain', cod: 'BH' },
  { nm: 'Bangladesh', cod: 'BD' },
  { nm: 'Barbados', cod: 'BB' },
  { nm: 'Belarus', cod: 'BY' },
  { nm: 'Belgium', cod: 'BE' },
  { nm: 'Belize', cod: 'BZ' },
  { nm: 'Benin', cod: 'BJ' },
  { nm: 'Bermuda', cod: 'BM' },
  { nm: 'Bhutan', cod: 'BT' },
  { nm: 'Bolivia', cod: 'BO' },
  { nm: 'Bosnia and Herzegovina', cod: 'BA' },
  { nm: 'Botswana', cod: 'BW' },
  { nm: 'Bouvet Island', cod: 'BV' },
  { nm: 'Brazil', cod: 'BR' },
  { nm: 'British Indian Ocean Territory', cod: 'IO' },
  { nm: 'Brunei Darussalam', cod: 'BN' },
  { nm: 'Bulgaria', cod: 'BG' },
  { nm: 'Burkina Faso', cod: 'BF' },
  { nm: 'Burundi', cod: 'BI' },
  { nm: 'Cambodia', cod: 'KH' },
  { nm: 'Cameroon', cod: 'CM' },
  { nm: 'Canada', cod: 'CA' },
  { nm: 'Cape Verde', cod: 'CV' },
  { nm: 'Cayman Islands', cod: 'KY' },
  { nm: 'Central African Republic', cod: 'CF' },
  { nm: 'Chad', cod: 'TD' },
  { nm: 'Chile', cod: 'CL' },
  { nm: 'China', cod: 'CN' },
  { nm: 'Christmas Island', cod: 'CX' },
  { nm: 'Cocos (Keeling) Islands', cod: 'CC' },
  { nm: 'Colombia', cod: 'CO' },
  { nm: 'Comoros', cod: 'KM' },
  { nm: 'Congo', cod: 'CG' },
  { nm: 'Congo, The Democratic Republic of the', cod: 'CD' },
  { nm: 'Cook Islands', cod: 'CK' },
  { nm: 'Costa Rica', cod: 'CR' },
  { nm: 'Cote D\'Ivoire', cod: 'CI' },
  { nm: 'Croatia', cod: 'HR' },
  { nm: 'Cuba', cod: 'CU' },
  { nm: 'Cyprus', cod: 'CY' },
  { nm: 'Czech Republic', cod: 'CZ' },
  { nm: 'Denmark', cod: 'DK' },
  { nm: 'Djibouti', cod: 'DJ' },
  { nm: 'Dominica', cod: 'DM' },
  { nm: 'Dominican Republic', cod: 'DO' },
  { nm: 'Ecuador', cod: 'EC' },
  { nm: 'Egypt', cod: 'EG' },
  { nm: 'El Salvador', cod: 'SV' },
  { nm: 'Equatorial Guinea', cod: 'GQ' },
  { nm: 'Eritrea', cod: 'ER' },
  { nm: 'Estonia', cod: 'EE' },
  { nm: 'Ethiopia', cod: 'ET' },
  { nm: 'Falkland Islands (Malvinas)', cod: 'FK' },
  { nm: 'Faroe Islands', cod: 'FO' },
  { nm: 'Fiji', cod: 'FJ' },
  { nm: 'Finland', cod: 'FI' },
  { nm: 'France', cod: 'FR' },
  { nm: 'French Guiana', cod: 'GF' },
  { nm: 'French Polynesia', cod: 'PF' },
  { nm: 'French Southern Territories', cod: 'TF' },
  { nm: 'Gabon', cod: 'GA' },
  { nm: 'Gambia', cod: 'GM' },
  { nm: 'Georgia', cod: 'GE' },
  { nm: 'Germany', cod: 'DE' },
  { nm: 'Ghana', cod: 'GH' },
  { nm: 'Gibraltar', cod: 'GI' },
  { nm: 'Greece', cod: 'GR' },
  { nm: 'Greenland', cod: 'GL' },
  { nm: 'Grenada', cod: 'GD' },
  { nm: 'Guadeloupe', cod: 'GP' },
  { nm: 'Guam', cod: 'GU' },
  { nm: 'Guatemala', cod: 'GT' },
  { nm: 'Guernsey', cod: 'GG' },
  { nm: 'Guinea', cod: 'GN' },
  { nm: 'Guinea-Bissau', cod: 'GW' },
  { nm: 'Guyana', cod: 'GY' },
  { nm: 'Haiti', cod: 'HT' },
  { nm: 'Heard Island and Mcdonald Islands', cod: 'HM' },
  { nm: 'Holy See (Vatican City State)', cod: 'VA' },
  { nm: 'Honduras', cod: 'HN' },
  { nm: 'Hong Kong', cod: 'HK' },
  { nm: 'Hungary', cod: 'HU' },
  { nm: 'Iceland', cod: 'IS' },
  { nm: 'India', cod: 'IN' },
  { nm: 'Indonesia', cod: 'ID' },
  { nm: 'Iran, Islamic Republic Of', cod: 'IR' },
  { nm: 'Iraq', cod: 'IQ' },
  { nm: 'Ireland', cod: 'IE' },
  { nm: 'Isle of Man', cod: 'IM' },
  // { nm: 'Israel', cod: 'IL' },
  { nm: 'Italy', cod: 'IT' },
  { nm: 'Jamaica', cod: 'JM' },
  { nm: 'Japan', cod: 'JP' },
  { nm: 'Jersey', cod: 'JE' },
  { nm: 'Jordan', cod: 'JO' },
  { nm: 'Kazakhstan', cod: 'KZ' },
  { nm: 'Kenya', cod: 'KE' },
  { nm: 'Kiribati', cod: 'KI' },
  { nm: 'Korea, Democratic People\'S Republic of', cod: 'KP' },
  { nm: 'Korea, Republic of', cod: 'KR' },
  { nm: 'Kuwait', cod: 'KW' },
  { nm: 'Kyrgyzstan', cod: 'KG' },
  { nm: 'Lao People\S Democratic Republic', cod: 'LA' },
  { nm: 'Latvia', cod: 'LV' },
  { nm: 'Lesotho', cod: 'LS' },
  { nm: 'Liberia', cod: 'LR' },
  { nm: 'Libyan Arab Jamahiriya', cod: 'LY' },
  { nm: 'Liechtenstein', cod: 'LI' },
  { nm: 'Lithuania', cod: 'LT' },
  { nm: 'Luxembourg', cod: 'LU' },
  { nm: 'Macao', cod: 'MO' },
  { nm: 'Macedonia, The Former Yugoslav Republic of', cod: 'MK' },
  { nm: 'Madagascar', cod: 'MG' },
  { nm: 'Malawi', cod: 'MW' },
  { nm: 'Malaysia', cod: 'MY' },
  { nm: 'Maldives', cod: 'MV' },
  { nm: 'Mali', cod: 'ML' },
  { nm: 'Malta', cod: 'MT' },
  { nm: 'Marshall Islands', cod: 'MH' },
  { nm: 'Martinique', cod: 'MQ' },
  { nm: 'Mauritania', cod: 'MR' },
  { nm: 'Mauritius', cod: 'MU' },
  { nm: 'Mayotte', cod: 'YT' },
  { nm: 'Mexico', cod: 'MX' },
  { nm: 'Micronesia, Federated States of', cod: 'FM' },
  { nm: 'Moldova, Republic of', cod: 'MD' },
  { nm: 'Monaco', cod: 'MC' },
  { nm: 'Mongolia', cod: 'MN' },
  { nm: 'Montenegro', cod: 'ME' },
  { nm: 'Montserrat', cod: 'MS' },
  { nm: 'Morocco', cod: 'MA' },
  { nm: 'Mozambique', cod: 'MZ' },
  { nm: 'Myanmar', cod: 'MM' },
  { nm: 'Namibia', cod: 'NA' },
  { nm: 'Nauru', cod: 'NR' },
  { nm: 'Nepal', cod: 'NP' },
  { nm: 'Netherlands', cod: 'NL' },
  { nm: 'Netherlands Antilles', cod: 'AN' },
  { nm: 'New Caledonia', cod: 'NC' },
  { nm: 'New Zealand', cod: 'NZ' },
  { nm: 'Nicaragua', cod: 'NI' },
  { nm: 'Niger', cod: 'NE' },
  { nm: 'Nigeria', cod: 'NG' },
  { nm: 'Niue', cod: 'NU' },
  { nm: 'Norfolk Island', cod: 'NF' },
  { nm: 'Northern Mariana Islands', cod: 'MP' },
  { nm: 'Norway', cod: 'NO' },
  { nm: 'Oman', cod: 'OM' },
  { nm: 'Pakistan', cod: 'PK' },
  { nm: 'Palau', cod: 'PW' },
  { nm: 'Palestinian Territory, Occupied', cod: 'PS' },
  { nm: 'Panama', cod: 'PA' },
  { nm: 'Papua New Guinea', cod: 'PG' },
  { nm: 'Paraguay', cod: 'PY' },
  { nm: 'Peru', cod: 'PE' },
  { nm: 'Philippines', cod: 'PH' },
  { nm: 'Pitcairn', cod: 'PN' },
  { nm: 'Poland', cod: 'PL' },
  { nm: 'Portugal', cod: 'PT' },
  { nm: 'Puerto Rico', cod: 'PR' },
  { nm: 'Qatar', cod: 'QA' },
  { nm: 'Reunion', cod: 'RE' },
  { nm: 'Romania', cod: 'RO' },
  { nm: 'Russian Federation', cod: 'RU' },
  { nm: 'RWANDA', cod: 'RW' },
  { nm: 'Saint Helena', cod: 'SH' },
  { nm: 'Saint Kitts and Nevis', cod: 'KN' },
  { nm: 'Saint Lucia', cod: 'LC' },
  { nm: 'Saint Pierre and Miquelon', cod: 'PM' },
  { nm: 'Saint Vincent and the Grenadines', cod: 'VC' },
  { nm: 'Samoa', cod: 'WS' },
  { nm: 'San Marino', cod: 'SM' },
  { nm: 'Sao Tome and Principe', cod: 'ST' },
  { nm: 'Saudi Arabia', cod: 'SA' },
  { nm: 'Senegal', cod: 'SN' },
  { nm: 'Serbia', cod: 'RS' },
  { nm: 'Seychelles', cod: 'SC' },
  { nm: 'Sierra Leone', cod: 'SL' },
  { nm: 'Singapore', cod: 'SG' },
  { nm: 'Slovakia', cod: 'SK' },
  { nm: 'Slovenia', cod: 'SI' },
  { nm: 'Solomon Islands', cod: 'SB' },
  { nm: 'Somalia', cod: 'SO' },
  { nm: 'South Africa', cod: 'ZA' },
  { nm: 'South Georgia and the South Sandwich Islands', cod: 'GS' },
  { nm: 'Spain', cod: 'ES' },
  { nm: 'Sri Lanka', cod: 'LK' },
  { nm: 'Sudan', cod: 'SD' },
  { nm: 'Surinam', cod: 'SR' },
  { nm: 'Svalbard and Jan Mayen', cod: 'SJ' },
  { nm: 'Syria', cod: 'SY' },
  { nm: 'Swaziland', cod: 'SZ' },
  { nm: 'Sweden', cod: 'SE' },
  { nm: 'Switzerland', cod: 'CH' },
  { nm: 'Taiwan, Province of China', cod: 'TW' },
  { nm: 'Tajikistan', cod: 'TJ' },
  { nm: 'Tanzania, United Republic of', cod: 'TZ' },
  { nm: 'Thailand', cod: 'TH' },
  { nm: 'Timor-Leste', cod: 'TL' },
  { nm: 'Togo', cod: 'TG' },
  { nm: 'Tokelau', cod: 'TK' },
  { nm: 'Tonga', cod: 'TO' },
  { nm: 'Trinidad and Tobago', cod: 'TT' },
  { nm: 'Tunisia', cod: 'TN' },
  { nm: 'Turkey', cod: 'TR' },
  { nm: 'Turkmenistan', cod: 'TM' },
  { nm: 'Turks and Caicos Islands', cod: 'TC' },
  { nm: 'Tuvalu', cod: 'TV' },
  { nm: 'Uganda', cod: 'UG' },
  { nm: 'Ukraine', cod: 'UA' },
  { nm: 'United Arab Emirates', cod: 'AE' },
  { nm: 'United Kingdom', cod: 'GB' },
  { nm: 'United States', cod: 'US' },
  { nm: 'United States Minor Outlying Islands', cod: 'UM' },
  { nm: 'Uruguay', cod: 'UY' },
  { nm: 'Uzbekistan', cod: 'UZ' },
  { nm: 'Vanuatu', cod: 'VU' },
  { nm: 'Venezuela', cod: 'VE' },
  { nm: 'Viet Nam', cod: 'VN' },
  { nm: 'Virgin Islands, British', cod: 'VG' },
  { nm: 'Virgin Islands, U.S.', cod: 'VI' },
  { nm: 'Wallis and Futuna', cod: 'WF' },
  { nm: 'Western Sahara', cod: 'EH' },
  { nm: 'Yemen', cod: 'YE' },
  { nm: 'Zambia', cod: 'ZM' },
  { nm: 'Zimbabwe', cod: 'ZW' }
];

//   const countriesOption = countries.map(c => ({
//     value: c.cod,
//     text: c.nm
//   }))

export default countries.map(c => ({
  value: c.cod,
  text: c.nm
}));
