import React, { useState, useEffect, Fragment } from 'react';
import PageLayout from '../../../ui/PageLayout';
import { IListObjects, FormBuilder, IDesribeOb } from '../../../jsFnI';
import { FieldParser, GenericLoading } from '../../../jsFnI/helpers';

import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiTitle,
  EuiButton,
  EuiFlyoutFooter,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFieldText,
  EuiFormRow,
  EuiSwitch,
  EuiDescriptionList,
  EuiSpacer,
  EuiButtonGroup

} from '@elastic/eui';
import {
  useParams
} from 'react-router-dom';
import {
  useRedirect,
} from '../../../../jas/state';


import {
  setBreadcrumbs,
} from '../../../../js/actions/index';

import { useTranslation } from 'react-i18next';
import API from '../../../../js/api';


import schema from '../../../../repository/schemas/forensic/profiles/describe';
import voiceprintsSchema from '../../../../repository/schemas/forensic/profiles/listVoiceprints';
import faceVariationsSchema from '../../../../repository/schemas/forensic/profiles/listFaceVariations';

import voiceprintCreateSchema from '../../../../repository/schemas/forensic/profiles/createVoiceprint';
import faceVariationCreateSchema from '../../../../repository/schemas/forensic/profiles/createFaceVariation';

import { useError } from '../../../Error';
import store from '../../../../jas/store';


const Page = () => {
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( null );
  const [ forceReload, setForceReload ] = useState( false );
  const [ title, setTitle ] = useState( t( 'GENERIC_LOADING' ) );
  const [ body, setBody ] = useState( <GenericLoading /> );

  const [ flyout, setFlyout ] = useState( <></> );
  const addError = useError();
  const addRedirect = useRedirect();
  const [ id, setId ] = useState( null );
  let { objectId } = useParams();


  //Close Flyout Action
  const closeView = () => {
    setId( null );
    addRedirect( './' );
  };




  useEffect( () => {
    switch ( showAddPopup ) {
      case 'voiceprint':
        setFlyout( <FormBuilder
          schema={voiceprintCreateSchema}
          reload={() => setForceReload( true )}
          close={() => setShowAddPopup( false )}
          prefillData={{ parent: objectId }} /> );
        break;
      case 'faceVariation':
        setFlyout( <FormBuilder
          schema={faceVariationCreateSchema}
          reload={() => setForceReload( true )}
          close={() => setShowAddPopup( false )}
          prefillData={{ parent: objectId }} /> );
        break;

      default:
        setFlyout( <></> );

    }
  }, [ showAddPopup ] );


  const buttonNewVoiceprint = (
    <EuiFlexGroup
      wrap
      gutterSize="s"
      alignItems="center"
      className="">
      <EuiFlexItem grow={true}>
        <EuiButtonEmpty iconType='listAdd'
          fullWidth={true}
          size='s' onClick={() => { setShowAddPopup( 'voiceprint' ); }}>
          {t( 'ADD_NEW' )}
        </EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  );


  const buttonNewFaceVariation = (
    <EuiFlexGroup
      wrap
      gutterSize="s"
      alignItems="center"
      className="">
      <EuiFlexItem grow={true}>
        <EuiButtonEmpty iconType='listAdd'
          fullWidth={true}
          size='s' onClick={() => { setShowAddPopup( 'faceVariation' ); }}>
          {t( 'ADD_NEW' )}
        </EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  useEffect( () => {
    let bodyTmp;
    ( async () => {
      if ( objectId ) {
        let res;
        try {
          res = await API.describe( objectId, { skipCache: false } );
          let subTablesFilters = [ {
            field: 'parent',
            operator: 'IN',
            value: [ objectId ]
          } ];
          if ( res.data ) {

            let tmpItems = [];
            for ( let item of schema.fields ) {
              tmpItems.push( {
                title: t( item.i18nTitle ),
                description: <FieldParser field={item} data={res.data} />
              } );
            }
            if ( !_.isEmpty( res.data[ schema.settings.title ] ) ) {
              // @ts-ignore

              setTitle( `${ res.data[ schema.settings.title ] } ${ t( schema.settings.i18nTitleSuffix ) }` );
            } else {
              setTitle( t( schema.settings.i18nTitleSuffix ) );
            }
            setBody( (
              <Fragment>
                <EuiDescriptionList type="column"
                  textStyle={schema.settings.textStyle}
                  listItems={tmpItems}
                />

                <EuiSpacer />
                <EuiTitle size="xs"><h6>{t( 'FORENSIC_PROFILE_FACE_VARIATIONS' )}</h6></EuiTitle>
                <IListObjects
                  query={faceVariationsSchema.query}
                  listSchema={faceVariationsSchema}
                  filters={subTablesFilters}
                  forceReload={forceReload}
                />
                {buttonNewFaceVariation}
                <EuiSpacer />
                <EuiTitle size="xs"><h6>{t( 'FORENSIC_PROFILE_VOICEPRINTS' )}</h6></EuiTitle>
                <IListObjects
                  query={voiceprintsSchema.query}
                  listSchema={voiceprintsSchema}
                  filters={subTablesFilters}
                  forceReload={forceReload}
                />
                {buttonNewVoiceprint}
              </Fragment> ) );

          }
        } catch ( er ) {
          console.log( er );
          addError( {
            title: er.message,
            color: 'danger',
            iconType: 'help'
          } );
        }
      };
    } )();
  }, [ objectId ] );


  return (
    <Fragment>
      <EuiFlyout
        ownFocus
        onClose={closeView}
        size={'l'}>
        {flyout}
        <EuiFlyoutHeader hasBorder>
          <EuiTitle>
            <h2>{title}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {body}
        </EuiFlyoutBody>
      </EuiFlyout>
    </Fragment>
  );
};

export { Page as default };
