export default {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: false
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc', //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
    },
    actions: [ 'archive' ]
  },
  query: {
    kind: 'forensicVoiceprint',
    project_id: '{{CONSOLE_SELECTED_PROJECT}}',
    orderBy: 'createdAt desc',
    operator: 'and',
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      field: 'reference',

      data: '_reference',
      i18nTitle: 'GENERIC_REFERENCE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'identification_status',

      data: 'payload.metadata.enrollmentStatus',
      i18nTitle: 'ENROLLMENT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'speech_record_time',

      data: 'payload.metadata.speechRecordTime',
      i18nTitle: 'VRP_RECORD_TIME',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'source_audio',

      data: 'payload.metadata.sourceAttachmentId',
      i18nTitle: 'SOURCE_AUDIO',
      sortable: false,
      align: 'center',
      showOnMobile: true,
      type: 'audioPlayer'
    },
    {
      field: 'vrp_createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'vrp_record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};
