
import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import _ from 'lodash';

import api from '../../../../js/api';
import { EuiLoadingContent, EuiText, EuiBadge } from '@elastic/eui';
import FieldParser from '.';




class B3ObjectFieldV2 extends Component {
  constructor () {
    // @ts-ignore
    super();
    this.state = { resp: ( <EuiLoadingContent lines={2} /> ) };

  }

  parseItem = async ( data, display ) => {
    try {
      const a = await api.describe( data );

      return ( <FieldParser field={display} data={a.data} /> );

    } catch ( err ) {
      return <div>NA ({data})</div>;
    }
  };

  async componentDidMount () {
    if ( _.isEmpty( this.props.data ) ) {
      this.setState( {
        resp: (
          <EuiText>NA</EuiText>
        )
      } );
    } else {
      if ( this.props.settings?.display_multiple == true ) {

        let domArr = [];
        for ( let item of this.props.data ) {
          const a = await this.parseItem( item, this.props.settings );
          domArr.push( <EuiBadge href={`${ this.props.settings?.preHref }/${ item }`}>{a}</EuiBadge> );
        }
        this.setState( {
          resp: domArr
        } );


      } else {
        let c = await this.parseItem( this.props.data, this.props.settings );
        this.setState( {
          resp: c
        } );
      }

    }
  }

  render ( {
    data,
    display
  } = this.props ) {

    return (
      this.state.resp
    );
  }
}

export { B3ObjectFieldV2 };
