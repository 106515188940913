
import React, { Component } from 'react';
import _ from 'lodash';

import api from '../../../../js/api';
import { EuiLoadingContent, EuiText } from '@elastic/eui';
import FieldParser from '.';


class B3ObjectField extends Component {
  constructor () {
    // @ts-ignore
    super();
    this.state = { resp: ( <EuiLoadingContent lines={1} /> ) };

  }

  async componentDidMount () {
    if ( _.isEmpty( this.props.data ) ) {
      this.setState( {
        resp: (
          <EuiText>NA</EuiText>
        )
      } );
    } else {
      try {
        const a = await api.describe( this.props.data );
        this.setState( {
          resp: (
            <FieldParser field={this.props.display} data={a.data} />
          )
        } );
      } catch ( err ) {
        this.setState( {
          resp: <div>NA ({this.props.data})</div>
        } );
      }
    }
  }

  render ( {
    data,
    display
  } = this.props ) {

    return (
      this.state.resp
    );
  }
}

export { B3ObjectField };
