import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import b3 from '../../js/api';

import { FieldBuilder, GenericLoading } from './helpers';
import { useError } from '../Error';
import { IFormFieldButton } from './helpers/fieldBuilder/fieldButton';

import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiTitle,
  EuiForm,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFieldText,
  EuiFormRow,
  EuiFlyoutFooter,
  EuiFlexItem
} from '@elastic/eui';
import {
  useRedirect,
} from '../../jas/state';
import { getConsoleProjects } from '../../jas/state/consoleOptions';


import _ from 'lodash';

/**
 *
 * Form Builder
 * Return multi field react form.
 * @param  {schema} schema The schema file of the form
 */
const FormBuilder = ( { schema, close, reload, size, b3Project, prefillData } ) => {

  const { t } = useTranslation();
  //  let { itemId } = useParams();

  const [ formFields, setFormFields ] = useState();
  const [ formValues, setFormValues ] = useState( {} );
  const [ title, setTitle ] = useState( t( 'GENERIC_LOADING' ) );
  const [ formValid, setFormValid ] = useState( {} );
  const [ submitLoader, setSubmitLoader ] = useState( { loading: false, locked: true, i18nText: 'SAVE' } );

  const addError = useError();
  const goRedirect = useRedirect();


  const allTrue = ( obj ) => {
    for ( var o in obj )
      if ( !obj[ o ] ) return false;

    return true;
  };

  const onUpdateHandler = ( fieldName, value, uiValue ) => {

    _.set( formValues, fieldName, value );
    setFormValues( formValues );
    //console.log( 'form update handler 2', formValues );

    //return formValues;
  };

  /**
   * @param {{ loading: Boolean; locked: Boolean; text: String; }} v
   */
  const lockParent = ( v ) => {
    if ( !_.isEmpty( v ) ) {
      // @ts-ignore
      setSubmitLoader( { loading: v.loading, locked: v.locked, text: v.text } );
    } else {
      //Delegate the butto status update to formFieldsValid
      formFieldsValid( 'fileUpload', true );
    }
  };

  const formFieldsValid = ( fieldName, valid ) => {
    //console.log( 'fields Valid', formValid );
    _.set( formValid, fieldName.toString(), valid );
    if ( allTrue( formValid ) ) {
      setSubmitLoader( { loading: false, locked: false, i18nText: 'SAVE' } );
    } else {
      setSubmitLoader( { loading: false, locked: true, i18nText: 'SAVE' } );
    };
  };

  const submitForm = async () => {
    let create;
    try {
      setSubmitLoader( { loading: true, locked: true, i18nText: 'GENERIC_CREATING' } );
      create = await b3.createObject( {
        reference: schema.settings.b3Object.reference,
        project_id: getConsoleProjects().selected,
        kind: schema.settings.b3Object.kind,
        parent: formValues.parent,
        payload: formValues.payload,
        classification: schema.settings.b3Object.defaultClassification,
        source: window.location.href || 'https://godview.tactint.com/current/',
        status: schema.settings.b3Object.status,
        createdAt: new Date().getTime(),
        expiresAt: new Date().getTime()
      }, { instant: 'true' } );

      addError( {
        title: t( 'GENERIC_OBJECT_CREATED' ),
        text: t( 'GENERIC_OBJECT_CREATED_DESCRIPTION' ),
        color: 'success',
        iconType: 'checkInCircleFilled'
      } );

      if ( close ) {
        close( true );
      }

    } catch ( err ) {
      setSubmitLoader( { loading: false, locked: false, i18nText: 'GENERIC_CREATING' } );
      console.error( 'FormBuilder Error', err );
      addError( {
        title: t( 'GENERIC_OBJECT_ERROR' ),
        text: t( 'GENERIC_OBJECT_ERROR_DESCRIPTION' ),
        color: 'danger',
        iconType: 'crossInACircleFilled'
      } );
    }

    try {

      if ( schema.settings.refreshOnCreate ) {
        if ( reload ) {
          reload( true );
          //Fire a second time to set to false, fix/give the ability to reload when creating multiple items in the same view
          reload( false );
        }
      }

      if ( schema.settings.showOnCreate ) {
        let redirectUrl = `./${ create.data.id }`;

        if ( schema.settings.reloadUntilActive ) {
          redirectUrl += `?reloadUntilActive=true&reloadField=${ schema.settings.reloadUntilActive.name.join( '.' ) }&reloadValues=${ schema.settings.reloadUntilActive.value || schema.settings.reloadUntilActive.values.join( ',' ) }&retryCount=${ schema.settings.reloadUntilActive.retries }&retryTime=${ schema.settings.reloadUntilActive.maxTime }&loadingMsg=GENERIC_PROCESSING_MSG_CLOSE`;
        }
        goRedirect( redirectUrl );
      }

    } catch ( err ) {
      console.error( 'FormBuilder Error', err );
      addError( {
        title: t( 'FORM_CONFIG_ERROR' ),
        text: t( 'FORM_CONFIG_ERROR_DESCRIPTION' ),
        color: 'danger',
        iconType: 'crossInACircleFilled'
      } );
    }



  };

  //Handle prefillData
  useEffect( () => {
    console.log( 'prefill data', prefillData );
    if ( _.isObject( prefillData ) ) {
      for ( let prefillDataKey in prefillData ) {
        _.set( formValues, prefillDataKey, prefillData[ prefillDataKey ] );
        setFormValues( formValues );
      }
    }

  }, [ prefillData ] );

  // field, data, onUpdate, b3Project, lockParent, isValid, type
  useEffect( () => {
    ( async () => {
      if ( schema.fields ) {

        try {
          let tmpItems = [];
          for ( let item of schema.fields ) {
            tmpItems.push( (
              <FieldBuilder field={item} onUpdate={onUpdateHandler} b3Project={b3Project} lockParent={lockParent} isValid={formFieldsValid} type={'form'} data={{}} />
            ) );
          }
          setFormFields( tmpItems );
          setTitle( t( schema.settings.i18nTitle ) );
        } catch ( err ) {
          //console.error( 'Form Builder Error', err );
          addError( {
            title: t( 'GENERIC_FORM_BUILD_ERROR' ),
            text: err.message,
            color: 'danger',
            iconType: 'help'
          } );
        }
      }
    } )();
  }, schema.fields );

  let Res = <EuiFlyout
    onClose={close}
    size={size || 's'}
    aria-labelledby='flyoutSmallTitle'
  >
    <EuiFlyoutHeader hasBorder>
      <EuiTitle size='s'>
        <h2 id='flyoutSmallTitle'>{title}</h2>
      </EuiTitle>
    </EuiFlyoutHeader>
    <EuiFlyoutBody>
      {formFields}
    </EuiFlyoutBody>
    <EuiFlyoutFooter>
      <EuiFlexGroup justifyContent='spaceBetween'>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty
            iconType='cross'
            onClick={close}
            flush='left'>
            {t( 'GENERIC_CANCEL' )}
          </EuiButtonEmpty>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <IFormFieldButton onClick={() => submitForm()} updateLoader={submitLoader} buttonName={t( 'SAVE' )} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlyoutFooter>
  </EuiFlyout>;



  return <Fragment>{Res}</Fragment>;
};

export { FormBuilder as default, FormBuilder };
