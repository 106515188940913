const schema = {
  settings: {
    i18nTitle: 'SOCIAL_ACCOUNT',
    refreshOnCreate: true,
    showOnCreate: true,
    reloadUntilActive: {
      name: [ 'payload', 'metadata', 'processingStatus' ],
      values: [ 'COMPLETED', 'ERROR' ],
      retries: 15,
      maxTime: 10000,
      timeOut: 300000 // in ms
    },
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'hcMessage',
      reference: '$.message',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'metadata', 'type' ],
      type: 'select',
      i18nLabel: 'CWSM_ACCOUNT_TYPE',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: 'tw',
      showOnMobile: true,
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'Twitter',
            value: 'tw',
          },
          {
            i18nTitle: 'Other',
            value: 'oth'
          }
        ]
      },
    },

    {
      name: [ 'parent' ],
      type: 'comboBox',
      i18nLabel: 'CWSM_ACTION_FROM_ACCOUNT',
      i18nHelp: 'CWSM_ACTION_FROM_ACCOUNT_HELP',
      i18nPlaceHolder: '',
      default: [],
      listSettings: {
        allowExclusions: true,
        class: 'noFlexWrap',
        type: 'remote',
        singleSelection: true,
        query: {
          project_id: '{{CONSOLE_SELECTED_PROJECT}}',
          orderBy: 'createdAt desc',
          operator: 'or',
          query: [
            {
              operator: 'and',
              query: [
                {
                  field: 'kind',
                  operator: 'IN',
                  value: [
                    'entityV2'
                  ]
                },
                {
                  field: 'status',
                  operator: 'IN',
                  value: [
                    'active',
                    'private',
                    'unlisted'
                  ]
                },
                {
                  field: 'payload.name',
                  operator: 'multiBf*',
                  value: '{{value}}'
                }
              ]
            }, {
              operator: 'and',
              query: [
                {
                  field: 'kind',
                  operator: 'IN',
                  value: [
                    'hcAccount'
                  ]
                },
                {
                  field: 'status',
                  operator: 'IN',
                  value: [
                    'active',
                    'private',
                    'unlisted'
                  ]
                },
                {
                  field: 'payload.metadata.processingStatus',
                  operator: 'IN',
                  value: [
                    'COMPLETED'
                  ]
                },
                {
                  field: '*',
                  operator: 'multiBf',
                  value: '{{value}}'
                }
              ]
            }
          ]
        }
      },
      showOnMobile: true
    },
    {
      name: [ 'payload', 'message' ],
      type: 'textarea',
      i18nLabel: 'CWSM_POST',
      i18nHelp: 'CWSM_POST_HELP',
      i18nPlaceHolder: 'CWSM_POST_PLACEHOLDER',
      default: '',
      showOnMobile: true,
    },
    {
      name: [ 'payload', 'reply_to' ],
      type: 'text',
      i18nLabel: 'IN_REPLY_TO',
      i18nHelp: 'IN_REPLY_TO_HELP',
      i18nPlaceHolder: 'twitter.com/Twitter/status/126320',
      default: '',
      showOnMobile: true,
    },
    /*  {
       name: [ 'payload', 'geo_lat' ],
       type: 'text',
       i18nLabel: 'GEO_LAT',
       i18nHelp: 'GEO_LAT_HELP',
       i18nPlaceHolder: '',
       default: '',
       showOnMobile: true,
     },
     {
       name: [ 'payload', 'geo_long' ],
       type: 'text',
       i18nLabel: 'GEO_LONG',
       i18nHelp: 'GEO_LONG_HELP',
       i18nPlaceHolder: '',
       default: '',
       showOnMobile: true,
     },
     {
       name: [ 'payload', 'displayGeo' ],
       type: 'switch',
       i18nLabel: 'DISPLAY_GEOLOCATION',
       i18nHelp: 'DISPLAY_GEOLOCATION_HELP',
       i18nPlaceHolder: '',
       default: '',
       showOnMobile: true,
     }, */
    {
      name: [ 'payload', 'follow_before' ],
      type: 'switch',
      i18nLabel: 'CWSM_FOLLOW_BEFORE',
      i18nHelp: 'CWSM_FOLLOW_BEFORE_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true,
    },

    {
      name: [ 'payload', 'attached_media' ],
      type: 'fileUpload',
      i18nLabel: 'ATTACH_MEDIA',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: '',
      fileUploadSettings: {
        accept: [ 'image/*', 'video/*' ],
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true,
    },
    {
      name: [ 'payload', 'metadata', 'status' ],
      type: 'select',
      i18nLabel: 'STATUS',
      i18nHelp: 'CWSM_ACTION_STATUS',
      i18nPlaceHolder: '',
      default: 'draft',
      showOnMobile: true,
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'DRAFT',
            value: 'draft',
          },
          {
            i18nTitle: 'READY',
            value: 'ready'
          }
        ]
      },
    },
    {
      name: [ 'payload', 'target', 'time' ],
      type: 'select',
      i18nLabel: 'CWSM_TARGET_TIME',
      i18nHelp: 'CWSM_TARGET_TIME_HELP',
      i18nPlaceHolder: '',
      default: 'auto',
      showOnMobile: true,
      validation: {
        presence: true
      },
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'AUTO',
            value: 'auto',
          },
          {
            i18nTitle: 'DELAYED',
            value: 'delayyed',
          },
          {
            i18nTitle: 'IMEEDIATE',
            value: 'immediate',
          }
        ]
      },
    },
    {
      name: [ 'payload', 'metadata', 'reference' ],
      type: 'text',
      i18nLabel: 'GENERIC_REFERENCE',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'GENERIC_REFERENCE_PLACEHOLDER',
      default: '',
      showOnMobile: true,
    },
    {
      name: [ 'payload', 'metadata', 'processingStatus' ],
      type: 'hidden',
      i18nLabel: '',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: 'QUEUED'
    }
  ]
};

export { schema as default };
