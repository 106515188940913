import { combineReducers } from 'redux';
import {
  SET_ENTRY_PORT,
  SET_PRINTER,
} from '../constants/action-types';


function entryPorts (state = null, action) {
  switch (action.type) {
    case SET_ENTRY_PORT:
      return [...action.payload];
    default:
      return state;
  }
}

function printer (state = null, action) {
  switch (action.type) {
    case SET_PRINTER:
      return { ...action.payload };
    default:
      return state;
  }
}

export default combineReducers({
  printer,
  entryPorts
});
