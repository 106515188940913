
import _ from 'lodash';



const setConsoleProjects = ( user_id, projectObjs ) => {

  localStorage.setItem( `cp_${ user_id }`, JSON.stringify( projectObjs ) );

  if ( projectObjs.selected ) {
    localStorage.setItem( 'L1_B3_DEFAULT_PROJECT', projectObjs.selected );

  }

  return projectObjs;
};


const getConsoleProjects = ( user_id ) => {

  if ( _.isEmpty( user_id ) ) {
    user_id = getConsoleUser();
  }

  const a = JSON.parse( localStorage.getItem( `cp_${ user_id }` ) );

  return a;

};

const setConsoleProject = ( user_id, project_id ) => {
  const a = getConsoleProjects( user_id );

  const b = {
    ...a,
    ...{ selected: project_id }
  };

  setConsoleProjects( user_id, b );

  return b;
};

const setConsoleUser = ( user_id ) => {
  localStorage.setItem( 'cu', user_id );

  return user_id;
};

const getConsoleUser = () => {
  const a = localStorage.getItem( 'cu' );

  return a;
};

const getConsoleSettings = ( user_id, project_id ) => {

  if ( _.isEmpty( user_id ) ) {
    user_id = getConsoleUser();
  }

  if ( _.isEmpty( project_id ) ) {
    project_id = getConsoleProjects( user_id )?.selected;
  }

  const a = JSON.parse( localStorage.getItem( `cs_${ user_id }_${ project_id }` ) );

  return a;

};

const setConsoleSettings = ( user_id, project_id, settings ) => {


  localStorage.setItem( `cs_${ user_id }_${ project_id }`, JSON.stringify( settings ) );


  return settings;
};



const queryParseReplace = ( query ) => {
  let a = JSON.stringify( query );

  const b = a.replace( /{{CONSOLE_SELECTED_PROJECT}}/g, getConsoleProjects().selected );

  return JSON.parse( b );

};

// queryParseReplaceV2 was coded but never tested or implemented beyond the function below.
// It was supposed to act as a parser, however at the current time, IListObjects accepted filters and didn't have other needs.
const queryParseReplaceV2 = ( query, data ) => {
  let a = JSON.stringify( query );

  let b = a.replace( /{{CONSOLE_SELECTED_PROJECT}}/g, getConsoleProjects().selected );

  //Todo: Replace data {{items with their value}}
  if ( _.isObject( data ) ) {
    for ( let dataItemKey in data ) {
      b = b.replace( `/{{${ dataItemKey }}}/g`, dataItem[ dataItemKey ] );
    }
  }

  return JSON.parse( b );

};

export { getConsoleProjects, setConsoleProjects, setConsoleProject, setConsoleUser, getConsoleUser, queryParseReplace, queryParseReplaceV2, getConsoleSettings, setConsoleSettings };
