export default {
  settings: {
    textStyle: 'reverse',

    title: '_reference',
    i18nTitleSuffix: 'DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'firstName',
      data: 'payload.firstName',
      i18nTitle: 'FIRST_NAME',
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'lastName',
      data: 'payload.lastName',
      i18nTitle: 'LAST_NAME',
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',
      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'id',
      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      width: '10%',
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    }
  ]
};
