import moment from 'moment';

const formFields = {};
const isDev = process.env.NODE_ENV === 'development';

reset();

const updateForm = form => {
  Object.keys(formFields).forEach(k => {
    formFields[k] = form[k];
  });
};

function reset () {
  formFields['family_name'] = isDev ? 'Jhon' : '';
  formFields['given_name'] = isDev ? 'Doe' : '';
  formFields['father_name'] = isDev ? 'Micho' : '';
  formFields['mother_name'] = isDev ? 'Micha' : '';
  formFields['sex'] = isDev ? 'male' : '';
  formFields['birth_date'] = isDev ? moment('1987-09-27') : null;
  formFields['id_type'] = isDev ? 'nationalId' : '';
  formFields['id_number'] = isDev ? '13090006851' : '';
  formFields['id_issue_date'] = isDev ? moment('1995-09-27') : null;
  formFields['id_expiry_date'] = isDev ? moment('2040-09-27') : null;
  formFields['id_issue_city'] = isDev ? 'Dekwaneh' : '';
  formFields['telephone_number'] = isDev ? '25874123236' : '';
  formFields['email_address'] = isDev ? 'dasd@sdds.sds' : '';
  formFields['entry_port'] = isDev ? 'ocKftibSek5dv45a2' : '';
  formFields['nationality'] = isDev ? 'LB' : '';
  formFields['comments'] = '';
  formFields['background_check'] = true;
  formFields['history_check'] = true;
  formFields['auto_fees'] = true;
  formFields['entry_length'] = isDev ? '10' : '';
  formFields['barcode'] = '';
};

export default {
  formFields,
  updateForm,
  reset
};
