import React, { useState, useEffect } from 'react';
import PageLayout from '../../../ui/PageLayout';
import { IListObjects, FormBuilder } from '../../../jsFnI';
import {
  EuiFlyout,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui';
import {
  setBreadcrumbs
} from '../../../../js/actions/index';
import { useTranslation } from 'react-i18next';


import listSchema from '../../../../repository/schemas/forensic/voiceRecognition/identification/list';
import createSchema from '../../../../repository/schemas/forensic/voiceRecognition/identification/create';
import describeSchema from '../../../../repository/schemas/forensic/voiceRecognition/identification/describe';


import { connect } from 'react-redux';
import { useError } from '../../../Error';
import { default as api } from '../../../../js/api';
import store from '../../../../jas/store';
import { useRedirect } from '../../../../jas/state';

const VoiceRecognitionList = ( { queryList } ) => {
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( false );
  const [ forceReload, setForceReload ] = useState( '' );
  const addError = useError();
  const addRedirect = useRedirect();

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'FORENSIC', href: '/forensic/' },
        { i18nText: 'VR_IDENTIFICATIONS', href: '/' } ] ) );
  }, [] );

  let flyout = <></>;
  if ( showAddPopup == true ) {
    flyout = <FormBuilder
      schema={createSchema}

      reload={() => setForceReload( true )}
      close={() => setShowAddPopup( false )} />;
  }

  const createButton = (
    <EuiFlexGroup
      wrap
      gutterSize="s"
      alignItems="center"
      className="guideDemo__ghostBackground">
      <EuiFlexItem grow={false}>
        <EuiButton iconType='user'
          size='s' onClick={() => { addRedirect( '../profiles/' ); }}>
          {t( 'VR_PROFILES' )}
        </EuiButton>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton iconType='listAdd'
          size='s' onClick={() => { setShowAddPopup( true ); }}>
          {t( 'GENERIC_CREATE' )}
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  return (
    <PageLayout title={t( 'VR_IDENTIFICATIONS' )} headerRight={createButton}>
      {flyout}

      <IListObjects query={queryList[ 'voiceRecognitionIdentification' ]} listSchema={listSchema} describeSchema={describeSchema} forceReload={forceReload} />
    </PageLayout>
  );
};

const mapStateToProps = state => ( {
  queryList: state.queryList,
  setBreadcrumbs
} );
export default connect( mapStateToProps )( VoiceRecognitionList );
