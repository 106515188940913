const schema = {
  settings: {
    i18nTitle: 'SOCIAL_ACCOUNT',
    refreshOnCreate: true,
    showOnCreate: true,
    reloadUntilActive: {
      name: [ 'payload', 'metadata', 'processingStatus' ],
      values: [ 'ENROLLED', 'ERROR' ],
      retries: 15,
      maxTime: 10000,
      timeOut: 300000 // in ms
    },
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'hcAccount',
      reference: '$.metadata.reference',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'metadata', 'reference' ],
      type: 'text',
      i18nLabel: 'GENERIC_REFERENCE',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'GENERIC_REFERENCE_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'type' ],
      type: 'select',
      i18nLabel: 'ACCOUNT_TYPE',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: 'tw',
      showOnMobile: true,
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'Twitter',
            value: 'tw',
          },
          {
            i18nTitle: 'Other',
            value: 'oth'
          }
        ]
      },
    },
    {
      name: [ 'payload', 'source', 'username' ],
      type: 'text',
      i18nLabel: 'SOURCE_ACCOUNT_USERNAME',
      i18nHelp: 'SOURCE_ACCOUNT_USERNAME_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'source', 'password' ],
      type: 'text',
      i18nLabel: 'SOURCE_ACCOUNT_PASSWORD',
      i18nHelp: 'SOURCE_ACCOUNT_PASSWORD_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'source', 'email' ],
      type: 'text',
      i18nLabel: 'SOURCE_ACCOUNT_EMAIL',
      i18nHelp: 'SOURCE_ACCOUNT_EMAIL_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'source', 'emailPassword' ],
      type: 'text',
      i18nLabel: 'SOURCE_ACCOUNT_EMAIL_PASSWORD',
      i18nHelp: 'SOURCE_ACCOUNT_EMAIL_PASSWORD_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'source', 'telephone' ],
      type: 'number',
      i18nLabel: 'SOURCE_ACCOUNT_TELEPHONE',
      i18nHelp: 'SOURCE_ACCOUNT_TELEPHONE_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'proxy', 'host' ],
      type: 'text',
      i18nLabel: 'PROXY_HOST',
      i18nHelp: 'PROXY_HOST_HELP',
      i18nPlaceHolder: 'PROXY_HOST_HELP_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'proxy', 'port' ],
      type: 'number',
      i18nLabel: 'PROXY_PORT',
      i18nHelp: 'PROXY_PORT_HELP',
      i18nPlaceHolder: 'PROXY_PORT_HELP_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'username' ],
      type: 'text',
      i18nLabel: 'TARGET_ACCOUNT_USERNAME',
      i18nHelp: 'TARGET_ACCOUNT_USERNAME_HELP',
      i18nPlaceHolder: 'TARGET_ACCOUNT_USERNAME_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'name' ],
      type: 'text',
      i18nLabel: 'TARGET_NAME',
      i18nHelp: 'TARGET_NAME_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'location' ],
      type: 'text',
      i18nLabel: 'TARGET_LOCATION',
      i18nHelp: 'TARGET_LOCATION_HELP',
      i18nPlaceHolder: 'TARGET_LOCATION_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'description' ],
      type: 'text',
      i18nLabel: 'TARGET_DESCRIPTION',
      i18nHelp: 'TARGET_DESCRIPTION_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'link' ],
      type: 'text',
      i18nLabel: 'TARGET_LINK',
      i18nHelp: 'TARGET_LINK_HELP',
      i18nPlaceHolder: 'TARGET_LINK_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'country' ],
      type: 'select',
      i18nLabel: 'ACCOUNT_COUNTRY',
      i18nHelp: 'ACCOUNT_COUNTRY_HELP',
      i18nPlaceHolder: '',
      default: 'lb',
      showOnMobile: true,
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'LEBANON',
            value: 'lb',
          },
          {
            i18nTitle: 'KUWAIT',
            value: 'kw',
          }
        ]
      }
    },
    {
      name: [ 'payload', 'target', 'language' ],
      type: 'select',
      i18nLabel: 'ACCOUNT_LANGUAGE',
      i18nHelp: 'ACCOUNT_LANGUAGE_HELP',
      i18nPlaceHolder: '',
      default: 'en',
      showOnMobile: true,
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'English',
            value: 'en',
          },
          {
            i18nTitle: 'Arabic',
            value: 'ar'
          }
        ]
      },
    },
    {
      name: [ 'payload', 'metadata', 'tags' ],
      type: 'comboBox',
      i18nLabel: 'HGB_ACCOOUNT_TAGS',
      i18nHelp: 'HGB_ACCOOUNT_TAGS_HELP',
      i18nPlaceHolder: 'HGB_ACCOOUNT_TAGS_HOLDER',
      default: [],
      listSettings: {
        allowExclusions: true,
        class: 'noFlexWrap',
        type: 'remote',
        singleSelection: false,
        query: {
          kind: 'entityV2',
          project_id: '{{CONSOLE_SELECTED_PROJECT}}',
          orderBy: 'payload.bsLevel desc',
          operator: 'and',
          //collapse: 'payload.entity',
          query: [
            {
              field: 'status',
              operator: 'IN',
              value: [
                'active',
                'private',
                'unlisted'
              ]
            },
            {
              field: 'payload.name',
              operator: 'multiBf*',
              value: '{{value}}'
            }
          ]
        }
      },
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'profilePicture' ],
      type: 'fileUpload',
      i18nLabel: 'PROFILE_PICTURE_FILE',
      i18nHelp: 'GENERIC_IMAGE_FILE_HELP',
      i18nPlaceHolder: '',
      default: '',
      fileUploadSettings: {
        accept: 'image/*',
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true
    },
    {
      name: [ 'payload', 'target', 'coverPicture' ],
      type: 'fileUpload',
      i18nLabel: 'COVER_PICTURE_FILE',
      i18nHelp: 'GENERIC_IMAGE_FILE_HELP',
      i18nPlaceHolder: '',
      default: '',
      fileUploadSettings: {
        accept: 'image/*',
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'status' ],
      type: 'select',
      i18nLabel: 'STATUS',
      i18nHelp: 'ACCOUNT_STATUS_HELP',
      i18nPlaceHolder: 'ACCOUNT_STATUS_PLACEHOLDER',
      default: 'draft',
      showOnMobile: true,
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'Draft',
            value: 'draft',
          },
          {
            i18nTitle: 'Ready',
            value: 'ready'
          }
        ]
      },
    },
    {
      name: [ 'payload', 'metadata', 'processingStatus' ],
      type: 'hidden',
      i18nLabel: '',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: 'PENDING'
    }
  ]
};

export { schema as default };
