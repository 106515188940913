import React from 'react';
import { EuiStepsHorizontal } from '@elastic/eui';
import { useTranslation } from 'react-i18next';

const MultiStep = ({ step }) => {
  const { t } = useTranslation();

  const horizontalSteps = [
    {
      title: t('Traveler Details'),
      isSelected: step === 1 ? true : false,
      isComplete: step !== 1 ? true : false,
      onClick: () => {}
    },
    {
      title: t('Background Check'),
      isSelected: step === 2 ? true : false,
      isComplete: step !== 1 && step !== 2 ? true : false,
      onClick: () => {}
    },
    {
      title: t('CONFIRMATION'),
      isSelected: step === 3 ? true : false,
      onClick: () => {}
    }
  ];

  return (
    <div>
      <EuiStepsHorizontal steps={horizontalSteps} />
    </div>
  );
};

export default MultiStep;
