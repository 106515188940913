import React, { useEffect, useState, Fragment } from 'react';
import {
  EuiComboBox
} from '@elastic/eui';

import b3 from '../../../../js/api';


import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { useError } from '../../../Error';
import { queryParseReplace } from '../../../../jas/state/consoleOptions';


const IFormFieldComboBox = ( { settings, defaultValue, isInvalid, onChange } ) => {

  //console.log( 'IFormFieldComboBox default', defaultValue.join( ',' ) );
  const [ value, setValue ] = useState( [] );
  const [ dynamicSearch, setDynamicSearch ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ options, setOptions ] = useState( [] );

  const addError = useError();
  const { t } = useTranslation();

  //console.log( 'IFormFieldComboBox', 'default', defaultValue );

  useEffect( () => {
    ( async () => {
      try {

        if ( settings.options ) {
          const tmpOptions = [];

          for ( let option of settings.options ) {
            tmpOptions.push( {
              label: t( option.i18nTitle ),
              value: option.value,
              disabled: option.disabled || false
            } );
          }
          setOptions( tmpOptions );
        }

        if ( settings.type == 'remote' && settings.query ) {
          setDynamicSearch( true );
        }

      } catch ( err ) {
        //console.error( 'Form Builder Error', err );
        addError( {
          title: t( 'GENERIC_FORM_BUILD_ERROR' ),
          text: err.message,
          color: 'danger',
          iconType: 'help'
        } );
      }
    } )();

  }, [ settings ] );

  const handleChange = ( v ) => {
    setValue( v );

    let tmpSelection = [];

    if ( settings.singleSelection ) {
      tmpSelection = v[ 0 ]?.value;
    } else if ( _.isEmpty( v ) ) {
      tmpSelection.push( '' );
    } else {
      for ( const item of v ) {
        tmpSelection.push( item.value );
      }
    }

    onChange( {
      target: {
        value: tmpSelection,
        uiValue: v
      }
    } );

  };

  const handleSearchChange = async ( value ) => {
    if ( dynamicSearch && value.length > 0 ) {
      setLoading( true );
      //console.log( value );
      let tmpQuery = JSON.parse( JSON.stringify( settings.query ).replace( /{{value}}/g, value ) );

      const a = await b3.queryObject( queryParseReplace( tmpQuery ) );
      setLoading( false );

      if ( a.data ) {
        const tmpOptions = [];
        for ( let option of a.data ) {
          tmpOptions.push( {
            label: t( option._reference ),
            value: option.id
          } );
        }
        setOptions( tmpOptions );
      }
    } else {
      setLoading( false );
    }
  };

  const handleSearchChangeThrottled = _.throttle( handleSearchChange, 1000, { leading: false } );

  useEffect( () => {
    if ( _.isArray( defaultValue ) ) {
      setValue( defaultValue );
      handleChange( defaultValue );
    }
  }, [ defaultValue ] );


  return (
    <EuiComboBox
      options={options}
      selectedOptions={value}
      isInvalid={isInvalid}
      onChange={handleChange}
      className={settings.class}
      onSearchChange={handleSearchChangeThrottled}
      isLoading={loading}
      async={dynamicSearch}
      singleSelection={settings.singleSelection || false}
    />
  );
};

export { IFormFieldComboBox as default, IFormFieldComboBox };
