const schema = {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: {
        type: 'none',
        view: 'flyout',
        schema: 'none',
        featureRequired: 'none'
      }
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    actions: [ 'archive' ]
  },
  query: {
    kind: 'faceRecognition',
    project_id: '{{CONSOLE_SELECTED_PROJECT}}',
    orderBy: 'createdAt desc',
    operator: 'and',
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      field: 'reference',

      data: '_reference',
      i18nTitle: 'GENERIC_REFERENCE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'image',

      data: 'payload.metadata.sourceAttachmentId',
      i18nTitle: 'SOURCE_IMAGE',
      sortable: true,
      type: 'image',
      showOnMobile: true,
      size: 's',
      alt: 'payload.metadata.sourceAttachmentId',
      hasShadow: true
    },
    {
      field: 'recognition_status',

      data: 'payload.metadata.recognitionStatus',
      i18nTitle: 'RECOGNITION_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'recognition_count',

      data: 'payload.recognitions',
      i18nTitle: 'GENERIC_RECOGNITION_COUNT',
      sortable: true,
      type: 'arrayCount',
      showOnMobile: true
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};

export { schema as default };
