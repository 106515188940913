import React, { useState, useEffect } from 'react';
import PageLayout from '../ui/PageLayout';
import {

} from '@elastic/eui';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
  useLocation
} from 'react-router-dom';
import {
  EuiButton
} from '@elastic/eui';
import {
  setBreadcrumbs
} from '../../js/actions/index';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { setUser } from '../../js/actions/index';
import store from '../../jas/store';
import { EuiSpacer } from '@elastic/eui';

const SignIn = () => {
  const { t } = useTranslation();
  const rqPath = new URLSearchParams( useLocation().search ).get( 'path' );
  const rqCode = new URLSearchParams( useLocation().search ).get( 'code' );


  const redirectToLogin = () => {
    let serviceId;

    switch ( window.location.hostname ) {
      case 'console.tactint.com':
        serviceId = 'console';
        break;
      case 'console-beta.tactint.com':
        serviceId = 'console-beta';
        break;
      case 'console.local.tactint.com':
        serviceId = 'console-local';
        break;
      default:
        serviceId = 'console';
    }

    console.log( 'should redirect', `https://auth.tactint.com/ServiceLogin?service_id=${ serviceId }&redirect_uri=${ encodeURIComponent( '/signin?path=' + rqPath ) }` );

    // window.location.replace(`https://auth.tactint.com/ServiceLogin?service_id=${ serviceId }&redirect_uri=${ encodeURIComponent('/signin?path=' + rqPath) }`);
  };

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'CONSOLE', href: '/' },
        { i18nText: 'AUTHENTICATE', href: '?' } ] ) );
  }, [] );


  function storeAndRedirect () {
    localStorage.setItem( 'apiKey', rqCode );
    window.location.replace( rqPath || '/' );
    store.dispatch( setUser() ).then( () => {
    } );
  }

  if ( rqPath && rqCode ) {
    localStorage.setItem( 'apiKey', rqCode );
    window.location.replace( rqPath || '/' );
  } else if ( rqPath ) {
    redirectToLogin();
  } else {
    //window.location.replace( '/signin' );
  }

  return (
    <PageLayout title={t( 'AUTHENTICATE' )} >
      <p>This page was replaced with /authenticate.html.</p>
      <EuiSpacer />
      <EuiButton onClick={() => { window.location.replace( '/authenticate.html' ); }}> Authenticate</EuiButton>
    </PageLayout>
  );
};

export { SignIn as default, SignIn };
