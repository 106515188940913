
import React, { Component } from 'react';
import api from '../../../../js/api';
import { EuiLoadingSpinner, EuiImage, EuiHealth } from '@elastic/eui';
import { LazyLoadComponent } from 'react-lazy-load-image-component';



class Image extends Component {
  _isMounted = false;
  constructor () {
    // @ts-ignore
    super();
    this.state = {
      resp: ( <EuiLoadingSpinner size='s' /> ),
      firstRun: 0

    };

  }

  async componentDidMount () {
    this._isMounted = true;
    try {
      let a;
      if ( this.props.data.startsWith( 'http' ) ) {
        a = { data: { payload: { download: { url: this.props.data } } } };
      } else {
        a = await api.describeAttachment( this.props.data );
      }
      if ( this._isMounted ) {
        this.setState( {
          resp: (
            <LazyLoadComponent placeholder={( <EuiLoadingSpinner size='s' /> )}>
              <EuiImage
                size={this.props.settings.size || 'l'}
                hasShadow={this.props.settings.shadow || 'false'}
                alt={this.props.settings.alt}
                allowFullScreen={this.props.settings.allowFullScreen}
                url={a.data.payload.download.url}
              />
            </LazyLoadComponent>
          ),
          firstRun: 1
        } );
      }
    } catch ( err ) {
      if ( this._isMounted ) {
        this.setState( {
          resp: <EuiHealth color="danger" textSize="xs">Image Not Found.</EuiHealth>
        } );
      }
    }
  }

  shouldComponentUpdate ( nextProps, nextState ) {
    return ( nextProps.data != this.props.data ) || nextState.firstRun != 0;
  }

  render ( {
    data,
    settings
  } = this.props ) {

    return (
      this.state.resp
    );
  }
}

export { Image };
