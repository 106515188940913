import React, { useState, useEffect } from 'react';
import PageLayout from '../../ui/PageLayout';
import { IListObjects, FormBuilder } from '../../jsFnI';
import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiTitle,
  EuiButton,
  EuiFlyoutFooter,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFieldText,
  EuiFormRow,
  EuiSwitch
} from '@elastic/eui';

import {
  useRedirect,
} from '../../../jas/state';

import {
  setBreadcrumbs,
} from '../../../js/actions/index';

import { useTranslation } from 'react-i18next';

import listSchema from '../../../repository/schemas/listGeneral';
import createSchema from '../../../repository/schemas/listGeneral';
import describeSchema from '../../../repository/schemas/forensic/voiceRecognition/identification/describe';

import { useError } from '../../Error';
import store from '../../../jas/store';


const Page = () => {
  console.log( 'page load' );
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( false );
  const [ forceReload, setForceReload ] = useState( false );
  const addError = useError();

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'EXPLORER', href: '/' }
      ] ) );
  }, [] );



  let flyout = <></>;

  if ( showAddPopup == true ) {
    flyout = <FormBuilder schema={createSchema} close={() => setShowAddPopup( false )} />;
  }


  const createButton = ( <EuiButton iconType='listAdd'
    size='s' onClick={() => { setShowAddPopup( true ); }}>
    {t( 'GENERIC_CREATE' )}
  </EuiButton> );


  return (
    <PageLayout title={t( 'EXPLORER' )} headerRight={createButton}>
      {flyout}
      <IListObjects
        query={listSchema.query}
        listSchema={listSchema}
        describeSchema={describeSchema}
        describeSize='m'
        forceReload={forceReload}
      />
    </PageLayout>
  );
};

export { Page as default };
