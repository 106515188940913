const schema = {
  settings: {
    i18nTitle: 'IE_CREATE',
    refreshOnCreate: true,
    showOnCreate: true,
    reloadUntilActive: {
      name: [ 'payload', 'metadata', 'status' ],
      value: 'active',
      holdTime: 30000, // in ms
      timeOut: 300000 // in ms
    },
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'imageEnhancement',
      reference: '$.metadata.reference',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'metadata', 'reference' ],
      type: 'text',
      i18nLabel: 'GENERIC_REFERENCE',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'GENERIC_REFERENCE_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'sourceAttachmentId' ],
      type: 'fileUpload',
      i18nLabel: 'GENERIC_IMAGE_FILE',
      i18nHelp: 'GENERIC_IMAGE_FILE_HELP',
      i18nPlaceHolder: 'GENERIC_IMAGE_FILE_PLACEHOLDER',
      default: '',
      fileUploadSettings: {
        accept: 'image/*',
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'level' ],
      type: 'select',
      i18nLabel: 'IE_LEVEL',
      i18nHelp: 'IE_LEVEL_HELP',
      i18nPlaceHolder: 'IE_LEVEL_HELP',
      default: '4',
      listSettings: {
        options: [
          {
            i18nTitle: 'GENERIC_LEVEL_1',
            value: '1'
          },
          {
            i18nTitle: 'GENERIC_LEVEL_4',
            value: '4'
          },
          {
            i18nTitle: 'GENERIC_LEVEL_8',
            value: '8'
          },
          {
            i18nTitle: 'GENERIC_LEVEL_16',
            value: '16'
          }
        ]
      },
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      name: 'payload.metadata.3dVersion',
      type: 'switch',
      i18nLabel: 'IE_3DRENDER',
      i18nHelp: 'IE_3DRENDER_HELP',
      i18nPlaceHolder: 'IE_3DRENDER_PLACEHOLDER',
      default: false,
      showOnMobile: true
    },
  ]
};

export { schema as default };
