import React, { useState, useEffect } from 'react';
import PageLayout from '../../../ui/PageLayout';
import { IListObjects, FormBuilder } from '../../../jsFnI';
import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiTitle,
  EuiButton,
  EuiFlyoutFooter,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFieldText,
  EuiFormRow,
  EuiSwitch
} from '@elastic/eui';

import {
  useRedirect,
} from '../../../../jas/state';

import {
  setBreadcrumbs,
} from '../../../../js/actions/index';

import { useTranslation } from 'react-i18next';

import listSchema from '../../../../repository/schemas/cw/sm/messages/list';
import createSchema from '../../../../repository/schemas/cw/sm/messages/create';
import describeSchema from '../../../../repository/schemas/cw/sm/messages/describe';

import { useError } from '../../../Error';
import store from '../../../../jas/store';


const Page = () => {
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( false );
  const [ forceReload, setForceReload ] = useState( false );
  const addError = useError();
  const addRedirect = useRedirect();


  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'CONSOLE', href: '/' },
        { i18nText: 'CWSM', href: '/cwsm/' },
        { i18nText: 'MESSAGES', href: '?' }
      ] ) );
  }, [] );

  useEffect( () => {
    if ( forceReload ) {
      setForceReload( false );
    }

  }, [ forceReload ] );


  let flyout = <></>;

  if ( showAddPopup == true ) {
    flyout = <FormBuilder
      schema={createSchema}

      reload={() => setForceReload( true )}
      close={() => setShowAddPopup( false )} />;
  }


  const createButton = (
    <EuiFlexGroup
      wrap
      gutterSize="s"
      alignItems="center"
      className="guideDemo__ghostBackground">
      <EuiFlexItem grow={false}>
        <EuiButton iconType='refresh'
          size='s' onClick={() => { setForceReload( true ); }} isDisabled={forceReload}>
          {t( 'GENERIC_RELOAD' )}
        </EuiButton>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton iconType='listAdd'
          size='s' onClick={() => { setShowAddPopup( true ); }}>
          {t( 'GENERIC_CREATE' )}
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  );


  return (
    <PageLayout title={t( 'MESSAGES' )} headerRight={createButton}>
      {flyout}
      <IListObjects
        query={listSchema.query}
        listSchema={listSchema}
        describeSchema={describeSchema}
        describeSize='s'
        forceReload={forceReload}
      />
    </PageLayout>
  );
};

export { Page as default };
