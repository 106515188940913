import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState, Fragment } from 'react';
import { setConfig } from 'react-hot-loader';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import { useTranslation } from 'react-i18next';
import {
  EuiHeader,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiCollapsibleNavGroup,
  EuiCollapsibleNav,
  EuiShowFor,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiText,
  EuiListGroupItem,
  EuiListGroup,
  EuiPinnableListGroup,
  EuiHorizontalRule
} from '@elastic/eui';

import nav from './js/nav';
import HeaderUserMenu from './components/layout/HeaderUserMenu';
import HeaderProjectSelector from './components/layout/HeaderProjectSelector';

import Router from './components/Router';
import SwitchLanguage from './components/layout/SwitchLanguage';
import PrinterStatus from './components/layout/PrinterStatus';
import Error from './components/Error';
import { Redirect, useRedirect } from './jas/state/redirect';
import { Breadcrumbs } from './jas/state/breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { getConsoleSettings } from './jas/state/consoleOptions';


import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
import './App.css';
import Logo from './svg/Forensic.svg';

import store from './jas/store';

if ( process.env.NODE_ENV === 'development' ) {
  /*  const whyDidYouRender = require( '@welldone-software/why-did-you-render' );
   whyDidYouRender( React, {
     trackAllPureComponents: false,
   } ); */
}

setConfig( { disableHotRenderer: false } );



const App = () => {
  const [ navItems, setNavItems ] = useState( null );

  const consoleSettings = getConsoleSettings();

  const { t } = useTranslation();
  const addRedirect = useRedirect();
  const location = useLocation();


  useEffect( () => {
    setNavItems( nav( addRedirect, consoleSettings, t ) );
  }, [ location ] );


  const renderLogo = () => {
    return (
      <EuiHeaderLogo
        iconType={Logo}
        aria-label='Home'
        href="/"
      />
    );
  };

  let navDrawerRef = React.createRef();

  /**
    * Accordion toggling
    */
  const [ openGroups, setOpenGroups ] = useState(
    JSON.parse( String( localStorage.getItem( 'openNavGroups' ) ) ) || [
      'Kibana',
      'Learn',
    ]
  );

  // Save which groups are open and which are not with state and local store
  const toggleAccordion = ( isOpen, title ) => {
    if ( !title ) return;
    const itExists = openGroups.includes( title );
    if ( isOpen ) {
      if ( itExists ) return;
      openGroups.push( title );
    } else {
      const index = openGroups.indexOf( title );
      if ( index > -1 ) {
        openGroups.splice( index, 1 );
      }
    }
    setOpenGroups( [ ...openGroups ] );
    localStorage.setItem( 'openNavGroups', JSON.stringify( openGroups ) );
  };

  /**
   * Pinning
   */
  const [ pinnedItems, setPinnedItems ] = useState( JSON.parse( String( localStorage.getItem( 'pinnedItems' ) ) ) || [] );

  const addPin = ( item ) => {
    if ( !item || find( pinnedItems, { label: item.label } ) ) {
      return;
    }
    item.pinned = true;
    const newPinnedItems = pinnedItems ? pinnedItems.concat( item ) : [ item ];
    setPinnedItems( newPinnedItems );
    localStorage.setItem( 'pinnedItems', JSON.stringify( newPinnedItems ) );
  };

  const removePin = ( item ) => {
    const pinIndex = findIndex( pinnedItems, { label: item.label } );
    if ( pinIndex > -1 ) {
      item.pinned = false;
      const newPinnedItems = pinnedItems;
      newPinnedItems.splice( pinIndex, 1 );
      setPinnedItems( [ ...newPinnedItems ] );
      localStorage.setItem( 'pinnedItems', JSON.stringify( newPinnedItems ) );
    }
  };

  function alterLinksWithCurrentState (
    links,
    showPinned
  ) {
    return links.map( ( link ) => {
      const { pinned, ...rest } = link;
      return {
        pinned: showPinned ? pinned : false,
        ...rest,
      };
    } );
  }

  function addLinkNameToPinTitle ( listItem ) {
    return `Pin ${ listItem.label } to top`;
  }

  function addLinkNameToUnpinTitle ( listItem ) {
    return `Unpin ${ listItem.label }`;
  }


  /**
     * Collapsible Nav
     */
  const [ navIsOpen, setNavIsOpen ] = useState(
    JSON.parse( String( localStorage.getItem( 'navIsDocked' ) ) ) || false
  );
  const [ navIsDocked, setNavIsDocked ] = useState(
    JSON.parse( String( localStorage.getItem( 'navIsDocked' ) ) ) || false
  );

  const collapsibleNav = (
    <EuiCollapsibleNav
      id="guideCollapsibleNavAllExampleNav"
      aria-label="Main navigation"
      isOpen={navIsOpen}
      isDocked={navIsDocked}
      button={
        <EuiHeaderSectionItemButton
          aria-label="Toggle main navigation"
          onClick={() => setNavIsOpen( !navIsOpen )}>
          <EuiIcon type={'menu'} size="m" aria-hidden="true" />
        </EuiHeaderSectionItemButton>
      }
      onClose={() => setNavIsOpen( false )}>
      {/* Dark deployments section */}


      {/* Shaded pinned section always with a home item */}
      <EuiFlexItem grow={false} style={{ flexShrink: 0 }}>
        <EuiCollapsibleNavGroup
          background="light"
          className="eui-yScroll"
          style={{ maxHeight: '40vh' }}>
          <EuiPinnableListGroup
            aria-label="Pinned links" // A11y : Since this group doesn't have a visible `title` it should be provided an accessible description
            listItems={alterLinksWithCurrentState( navItems || [] ).concat(
              alterLinksWithCurrentState( pinnedItems, true )
            )}
            unpinTitle={addLinkNameToUnpinTitle}
            onPinClick={removePin}
            maxWidth="none"
            color="text"
            gutterSize="none"
            size="s"
          />
        </EuiCollapsibleNavGroup>
      </EuiFlexItem>

      <EuiHorizontalRule margin="none" />

      {/* BOTTOM */}
      <EuiFlexItem className="eui-yScroll">

        {/* Docking button only for larger screens that can support it*/}
        <EuiShowFor sizes={[ 'l', 'xl' ]}>
          <EuiCollapsibleNavGroup>
            <EuiListGroupItem
              size="xs"
              color="subdued"
              label={`${ navIsDocked ? 'Undock' : 'Dock' } navigation`}
              onClick={() => {
                setNavIsDocked( !navIsDocked );
                localStorage.setItem(
                  'navIsDocked',
                  JSON.stringify( !navIsDocked )
                );
              }}
              iconType={navIsDocked ? 'lock' : 'lockOpen'}
            />
          </EuiCollapsibleNavGroup>
        </EuiShowFor>
      </EuiFlexItem>
    </EuiCollapsibleNav>
  );

  return <Fragment>

    <EuiHeader
      position="fixed"
      sections={[
        {
          // Disable navigation button and menu
          //items: [ collapsibleNav ],
        },

        {
          items: [ renderLogo() ],
          breadcrumbs: Breadcrumbs(),
          borders: 'right',
        },

        {
          align: 'right',
          items: [
            <HeaderProjectSelector />,
            <SwitchLanguage />,
            <HeaderUserMenu />
          ],
        }
      ]}
    />

    <Router />
    <Error />
    <Redirect />
  </Fragment>;
};

export default hot( App );
