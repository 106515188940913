export default {
  settings: {
    textStyle: 'reverse',
    title: '_reference',
    i18nTitleSuffix: ' ',
    actions: [
    ]
  },
  fields: [
    {
      data: 'payload.body',
      i18nTitle: 'TEXT',
      sortable: true,
      truncateText: true,
      //width: '10%',
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },

    {
      data: [ 'payload.source' ],
      i18nTitle: 'VIDEO',
      sortable: true,
      type: 'videoPlayer',
      showOnMobile: true,
      size: 's',
      hasShadow: true
    },
    {
      data: 'payload.publisher',
      i18nTitle: 'PUBLISHER',
      sortable: false,
      showOnMobile: true,
      type: 'text'
    },
    {
      field: 'link',
      i18nTitle: 'LINK',
      showOnMobile: true,
      data: [ 'payload.url' ],
      type: 'link',
      label: 'payload.url',
      external: true,
    },
    {
      field: 'record_status',
      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};
