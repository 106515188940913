const schema = {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: {
        type: 'none',
        view: 'flyout',
        schema: 'none',
        featureRequired: 'none'
      }
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    actions: [ 'archive' ],
    queryFields: [ 'payload.metadata.reference' ]
  },
  fields: [
    {
      field: 'reference',

      data: '_reference',
      i18nTitle: 'GENERIC_REFERENCE',
      sortable: false,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'payload.metadata.recognitionStatus',

      data: 'payload.metadata.recognitionStatus',
      i18nTitle: 'VRI_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'payload.metadata.advancedProcessing',

      data: 'payload.metadata.advancedProcessing',
      i18nTitle: 'VRI_ADVANCED_LABEL',
      sortable: true,
      showOnMobile: true,
      align: 'center',
      type: 'modernBool'
    },
    {
      field: 'payload.uniqueRecognitions.0.confidence',

      data: 'payload.uniqueRecognitions.0.confidence',
      i18nTitle: 'GENERIC_CONFIDENCE',
      sortable: false,
      type: 'text',
      showOnMobile: true,
      default: 'NA'
    },
    {
      field: 'detectedProfile',

      data: 'payload.uniqueRecognitions.0.detectedProfileId',
      i18nTitle: 'VRP_PROFILE',
      i18nDescription: 'VRP_LIST_DETECTED_PROFILE',
      sortable: false,
      showOnMobile: true,
      type: 'b3ObjectField',

      display: {
        field: 'detected_profile_link',
        data: [ '/forensic/vr/profiles/?id=', 'id' ],
        type: 'link',
        label: '_reference'
      }
    },
    {
      field: 'payload.uniqueRecognitions',

      data: 'payload.uniqueRecognitions',
      i18nTitle: 'GENERIC_RECOGNITION_COUNT',
      sortable: true,
      showOnMobile: true,
      type: 'arrayCount',
      default: 0
    },
    {
      field: 'payload.uniqueRecognitions.0.processingTime',

      data: 'payload.uniqueRecognitions.0.processingTime',
      i18nTitle: 'GENERIC_PROCESSING_TIME',
      sortable: true,
      showOnMobile: false,
      type: 'duration'
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};

export { schema as default };
