import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  EuiButtonIcon,
  EuiPopover,
  EuiDescriptionList,
  EuiLoadingSpinner
} from '@elastic/eui';

import { flattenObject, capitalizeFirstLetter } from '../../js/functions';
import { setPrinter } from '../../js/actions/index';

const mapStateToProps = state => {
  return { printer: state.printer, userMeta: state.userMeta };
};

const mapDispatchToProps = dispatch => ({
  setPrinter,
  dispatch
});

const btnStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '48px',
  width: '48px',
  textDecoration: 'none'
};

const PrinterStatus = ({ printer, userMeta, setPrinter, dispatch }) => {
  const [isPrinterPopoverOpen, setIsPrinterPopoverOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!userMeta) return;
    if (
      !userMeta['lc_default_printer_id'] &&
      userMeta['lc_default_printer_id'] === ''
    ) {
      setIsDisabled(true);
      return;
    }
    dispatch(setPrinter());
    const printerInterval = window.setInterval(
      () => dispatch(setPrinter()),
      30000
    );
    return () => clearInterval(printerInterval);
  }, [userMeta]);

  if (isDisabled) {
    return <span></span>;
  }

  if (!printer && !isDisabled)
    return (
      <div style={btnStyle}>
        <EuiLoadingSpinner size='m' />
      </div>
    );

  const flatPrinterObj = flattenObject(printer);
  // remove status.status which is always null (not usefull for printing)
  delete flatPrinterObj['status.status'];
  const printerInfo = Object.keys(flatPrinterObj)
    .map(k => ({
      title: capitalizeFirstLetter(k).replace('.', ' '),
      description: flatPrinterObj[k]
    }))
    .filter(item =>
      [
        'Printer name',
        'Status cover',
        'Status cutter',
        'Status paper',
        'Status network'
      ].includes(item.title)
    );

  const onTogglePrinterPopover = () => {
    setIsPrinterPopoverOpen(!isPrinterPopoverOpen);
  };

  const closePopover = () => {
    setIsPrinterPopoverOpen(false);
  };

  const PopoverIcon = (
    <EuiButtonIcon
      style={btnStyle}
      iconSize='l'
      iconType={printer.status.general}
      aria-label={'Printer status is' + printer.status.general}
      color={printer.status.general === 'online' ? 'success' : 'danger'}
      onClick={onTogglePrinterPopover}
    />
  );

  return (
    <EuiPopover
      id='printerPopover'
      ownFocus
      button={PopoverIcon}
      isOpen={isPrinterPopoverOpen}
      closePopover={closePopover}
      initialFocus='[id=asdf3]'
    >
      <div style={{ width: '300px' }}>
        <EuiDescriptionList
          type='column'
          textStyle='reverse'
          listItems={printerInfo.map(p => ({
            title: t(p.title),
            description: t(p.description)
          }))}
        />
      </div>
    </EuiPopover>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrinterStatus);
