import React, { useState, useEffect } from 'react';
import PageLayout from '../../ui/PageLayout';
import { IListObjects, FormBuilder } from '../../jsFnI';
import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiTitle,
  EuiButton,
  EuiFlyoutFooter,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFieldText,
  EuiFormRow,
  EuiSwitch
} from '@elastic/eui';

import {
  useRedirect,
} from '../../../jas/state';

import {
  setBreadcrumbs,
} from '../../../js/actions/index';

import { useTranslation } from 'react-i18next';

import listSchema from '../../../repository/schemas/av/dvr/list';
import describeSchema from '../../../repository/schemas/av/dvr/describe';

import { useError } from '../../Error';
import store from '../../../jas/store';


const Page = () => {
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( false );
  const [ forceReload, setForceReload ] = useState( false );
  const addError = useError();
  const addRedirect = useRedirect();


  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'CONSOLE', href: '/' },
        { i18nText: 'AV', href: '/av/' },
        { i18nText: 'AV_DVR', href: '/?' }
      ] ) );
  }, [] );



  let flyout = <> </>;



  return (
    <PageLayout title={t( 'STREAMS' )} >
      {flyout}
      <IListObjects
        query={listSchema.query}
        listSchema={listSchema}
        describeSchema={describeSchema}
        describeSize='s'
        forceReload={forceReload}
      />
    </PageLayout>
  );
};

export { Page as default };
