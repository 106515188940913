const schema = {
  settings: {
    updateQueryParameter: true,
    parseQueryParameter: false
  },
  fields: [
    {
      name: 'payload.description',
      type: 'search',
      i18nLabel: 'GENERIC_SEARCH',
      i18nHelp: 'GENERIC_SEARCH_HELP',
      i18nPlaceHolder: 'SW_SEARCH_PLACEHOLDER',
      default: '',
      operator: 'multiBf*',
      showOnMobile: true,
      validatio: {
        presence: true
      }
    },
    {
      name: 'payload.source',
      type: 'comboBox',
      i18nLabel: 'SW_ENTITIES_TYPE',
      i18nHelp: 'SW_ENTITIES_TYPE_HELP',
      i18nPlaceHolder: 'SW_ENTITIES_TYPE_HOLDER',
      default: [
        { label: 'Facebook', value: 'fb', disabled: false },
        { label: 'Twitter', value: 'tw', disabled: false },
        { label: 'Instagram', value: 'ig', disabled: false } ],
      operator: 'inv2',
      listSettings: {
        allowExclusions: true,
        class: 'noFlexWrap',
        options: [
          {
            i18nTitle: 'FACEBOOK',
            value: 'fb',
            default: true
          },
          {
            i18nTitle: 'TWITTER',
            value: 'tw'
          },
          {
            i18nTitle: 'INSTAGRAM',
            value: 'ig',
            default: true
          }
        ]
      },
      showOnMobile: true
    },
    {
      name: 'createdAt',
      type: 'superDate',
      i18nLabel: 'SW_LAST_SEEN',
      i18nHelp: 'SW_LAST_SEEN_HELP',
      i18nPlaceHolder: 'SW_LAST_SEEN_HOLDER',
      default: 'now-1d>-<now',
      operator: 'between',
      showOnMobile: true
    }
  ]
};

export { schema as default };
