const schema = {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: false
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
    },
    actions: [ 'archive' ]

  },
  query: {
    kind: 'forensicFace',
    project_id: '{{CONSOLE_SELECTED_PROJECT}}',
    orderBy: 'createdAt desc',
    operator: 'and',
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      field: 'reference',
      data: '_reference',
      i18nTitle: 'GENERIC_REFERENCE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'image',
      data: 'payload.sourceAttachmentId',
      i18nTitle: 'MAIN_IMAGE',
      sortable: true,
      type: 'image',
      showOnMobile: true,
      size: 's',
      alt: 'payload.sourceAttachmentId',
      hasShadow: true,
      allowFullScreen: true
    },
    {
      field: 'staticEnrollmentStatus',

      data: 'payload.staticEnrollmentStatus',
      i18nTitle: 'ENROLLMENT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'realtimeEnrollmentStatus',

      data: 'payload.realtimeEnrollmentStatus',
      i18nTitle: 'REALTIME_SERVICE_ENROLLMENT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};

export { schema as default };
