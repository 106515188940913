const schema = {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: {
        type: 'none',
        view: 'flyout',
        schema: 'none',
        featureRequired: 'none'
      }
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    actions: [ 'edit', 'archive' ]

  },
  query: {
    kind: 'avDvrEmbed',
    orderBy: 'createdAt desc',
    operator: 'and',
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'reference',
      data: '_reference',
      i18nTitle: 'GENERIC_REFERENCE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'sourceStream',
      data: 'payload.source.url',
      i18nTitle: 'AV_SOURCE_URL',
      sortable: true,
      type: 'text',
      showOnMobile: true
    }

  ]
};

export { schema as default };
