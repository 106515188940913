export default {
  settings: {
    textStyle: 'reverse',

    title: '_reference',
    i18nTitleSuffix: 'DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'identification_status',

      data: 'payload.metadata.recognitionStatus',
      i18nTitle: 'VRI_STATUS',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'advanced_processing',

      data: 'payload.metadata.advancedProcessing',
      i18nTitle: 'VRI_ADVANCED_LABEL',
      sortable: true,
      showOnMobile: true,
      align: 'center',
      type: 'modernBool'
    },
    {
      field: 'source_audio',

      data: 'payload.metadata.sourceAttachmentId',
      i18nTitle: 'SOURCE_AUDIO',
      sortable: false,
      align: 'center',
      showOnMobile: true,
      type: 'audioPlayer',
      playerSettings: {
        volumeControl: false
      }
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'uniqueRecognitions',

      data: 'payload.uniqueRecognitions',
      i18nTitle: 'VR_RECOGNITIONS',
      sortable: false,
      type: 'table',
      showOnMobile: true,
      tableSchema: [
        {
          field: 'detected_profile',

          data: 'detectedProfileId',
          i18nTitle: 'VRP_PROFILE',
          sortable: false,
          showOnMobile: true,
          type: 'b3ObjectField',

          display: {
            field: 'detected_profile_link',
            data: [ '/forensic/vr/profiles/?id=', 'id' ],
            type: 'link',
            label: '_reference'
          }
        },
        {
          field: 'confidence',

          data: 'confidence',
          i18nTitle: 'GENERIC_CONFIDENCE',
          sortable: false,
          type: 'number',
          showOnMobile: true,
          default: 'NA'
        }
      ]
    },
    {
      field: 'defailedRecognition',

      data: 'payload.recognitions',
      i18nTitle: 'VR_RECOGNITION_BREAKDOWN',
      sortable: false,
      type: 'table',
      showOnMobile: true,
      tableSchema: [
        {
          field: 'detected_profile',

          data: 'detectedProfileId',
          i18nTitle: 'VRP_PROFILE',
          sortable: false,
          showOnMobile: true,
          type: 'b3ObjectField',

          display: {
            field: 'detected_profile_link',
            data: [ '/forensic/vr/profiles/?id=', 'id' ],
            type: 'link',
            label: '_reference'
          }
        },
        {
          field: 'confidence',

          data: 'confidence',
          i18nTitle: 'GENERIC_CONFIDENCE',
          sortable: false,
          type: 'number',
          showOnMobile: true,
          default: 'NA'
        }
      ]
    }
  ]
};
