const schema = {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: {
        type: 'none',
        view: 'flyout',
        schema: 'none',
        featureRequired: 'none'
      }
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    actions: [ 'edit', 'archive' ]

  },
  query: {
    kind: 'hcMessage',
    project_id: '{{CONSOLE_SELECTED_PROJECT}}',
    orderBy: 'createdAt desc',
    operator: 'and',
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'reference',
      data: '_reference',
      i18nTitle: 'GENERIC_REFERENCE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'sourceUser',
      data: 'parent',
      i18nTitle: 'CWSM_ACTION_FROM_ACCOUNT',
      sortable: true,
      type: 'b3ObjectFieldV2',
      showOnMobile: true,
      settings: {
        display_multiple: false,
        preHref: '../accounts',
        data: [ '_reference' ],
        type: 'text'
      }
    },
    {
      field: 'platformLink',
      data: [ 'https://twitter.com/twitter/statuses/', 'payload.metadata.platform_id' ],
      i18nTitle: 'PLATFORM_LINK',
      sortable: true,
      type: 'link',
      showOnMobile: true,
      label: 'twitter.com',
      external: true,
    },
    {
      field: 'processing_status',
      data: 'payload.metadata.processingStatus',
      i18nTitle: 'PROCESSING_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',
      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};

export { schema as default };
