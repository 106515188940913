import React from 'react';
import Moment from 'react-moment';
import { EuiLink, EuiText, EuiIcon, EuiHealth } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { AudioPlayer } from './audioPlayer';
import { VideoPlayer } from './videoPlayer';
import { FormatedDate } from './formatedDate';
import { B3ObjectField } from './b3ObjectField';
import { Image } from './image';
import { Table } from './table';
import { ProgressBar } from './progressBar';
import { FormatedLink } from './formatedLink';
import { B3ObjectFieldV2 } from './b3ObjectFieldV2';
import JsonViewer from 'react-json-view';


import _ from 'lodash';

const FieldParser = ( { data, field } ) => {
  //console.log('data', data);
  //console.log('field', field);

  const { t } = useTranslation();

  let fieldData = '';

  if ( _.isArray( field?.data ) ) {
    for ( const part of field.data ) {
      fieldData += _.get( data, part, field.default || part );
    }
  } else {
    fieldData = _.get( data, field?.data, field?.default || '' );
  }

  if ( !field || !data || data.length <= 0 ) return <div></div>;

  switch ( field.type ) {
    case 'formatedDate':
      return (
        <Moment format={field.format} fromNow={field.relative} local>
          {fieldData}
        </Moment>
      );
    //Also should have been know as formatedDateV2
    case 'time':
      return (
        <FormatedDate data={fieldData} settings={field.timeSettings} />
      );
    case 'linkV2':
      return (
        <FormatedLink data={fieldData} fullData={data} settings={field.linkSettings} />
      );
    case 'duration':
      const value = parseFloat( fieldData ) / 100;
      return <span>{value} ms</span>;
    case 'length':
      const tmpTime = new Date( null );
      tmpTime.setSeconds( Number( fieldData ) );
      return <span>{tmpTime.toISOString().substr( 11, 8 )}</span>;
    case 'audioPlayer':
      return (
        <AudioPlayer src={fieldData} settings={field.playerSettings} />
      );
    case 'videoPlayer':
      return (
        <VideoPlayer src={fieldData} settings={field.playerSettings} />
      );
    case 'progressBar':
      return (
        <ProgressBar data={fieldData} settings={field.progressBarSettings} />
      );
    case 'b3ObjectField':
      return (
        <B3ObjectField data={fieldData} display={field.display} />
      );
    case 'b3ObjectFieldV2':
      return (
        <B3ObjectFieldV2 data={fieldData} settings={field.settings} />
      );
    case 'image':
      return (
        <Image data={fieldData} settings={field} />
      );
    case 'table':
      return (
        <Table data={fieldData} schema={field.tableSchema} />
      );
    case 'arrayCount':
      if ( _.isArray( fieldData ) ) {
        return ( <span>{fieldData.length}</span> );
      } else {
        return ( <span>{field.default || 0}</span> );
      }
    case 'numberRound':
      if ( !_.get( field, 'numberRound.decimal' ) ) {
        _.set( field, 'numberRound.decimal', 100 );
      }
      return ( <span>{Math.round( ( Number( fieldData ) + Number.EPSILON ) * Number( field.numberRound.decimal ) ) / Number( field.numberRound.decimal ) || 0}</span> );
    case 'jsonViewer':
      return ( <JsonViewer
        src={fieldData}
        theme={'shapeshifter:inverted'}
        displayDataTypes={false}
        enableClipboard={true}
        collapsed={field?.jsonViewerSettings?.collapsed} /> );
    case 'status':
      switch ( fieldData?.toLowerCase() ) {
        case 'active':
          return ( <EuiHealth color="success">{t( fieldData )}</EuiHealth> );
        case 'completed':
          return ( <EuiHealth color="success">{t( fieldData )}</EuiHealth> );
        case 'enrolled':
          return ( <EuiHealth color="success">{t( fieldData )}</EuiHealth> );
        case 'processing':
          return ( <EuiHealth color="orange">{t( fieldData )}</EuiHealth> );
        case 'queued':
          return ( <EuiHealth color="orange">{t( fieldData )}</EuiHealth> );
        case 'error':
          return ( <EuiHealth color="danger">{t( fieldData )}</EuiHealth> );
        default:
          return ( <EuiHealth color="subdued">{t( fieldData )}</EuiHealth> );
      }

    case 'modernBool':
      if ( fieldData.toString() == 'true' ) {
        return (
          <EuiIcon type={'checkInCircleFilled'} />
        );
      } else {
        return (
          <EuiIcon type={'check'} />
        );
      };
    case 'link':
      field.target = '';
      if ( field.external ) {
        field.target = '_blank';
      }
      return (
        <EuiLink href={fieldData} external={field.external}>
          {_.get( data, field.label, field.label || fieldData )}
        </EuiLink>
      );
    default:
      return <span>{fieldData}</span>;
  }
};

export { FieldParser as default, FieldParser };
