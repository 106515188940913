import React, { Fragment, useEffect, useState } from 'react';
import {
  EuiEmptyPrompt,
  EuiTitle,
  EuiLoadingSpinner,
  EuiIcon,
  EuiSpacer,
  EuiButton,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import API from '../../../../js/api';
import { useError } from '../../../../components/Error';

const Confirm = ({ data, reset }) => {
  const [status, setStatus] = useState('queued');

  const { t } = useTranslation();
  const addError = useError();

  useEffect(() => {
    const checkPrint = setInterval(async () => {
      try {
        const res = await API.describe(data && data.payload.receipt_id);
        if (res.data.payload.print.status !== 'queued')
          clearInterval(checkPrint);
        setStatus(res.data.payload.print.status);
      } catch (er) {
        console.log(er.message);
        addError({
          title: er.code,
          text: er.message,
          color: 'danger',
          iconType: 'crossInACircleFilled'
        });
      }
    }, 5000);

    return () => clearInterval(checkPrint);
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <EuiTitle>
        <h4>{t('CONFIRMATION')}</h4>
      </EuiTitle>
      <EuiEmptyPrompt
        iconType='document'
        title={<h2>{t('LANDING_CONFIRMED')}.</h2>}
        body={
          <Fragment>
            <p>{t('PLEASE_HOLD_FOR_THE_RECEIPT')}.</p>
            {status === 'queued' ? (
              <div>
                <p>
                  {t('Printing status')}: {t(status.toUpperCase())}
                </p>
                <EuiLoadingSpinner />
              </div>
            ) : (
              <div>
                <p>{t('Receipt Printed')}</p>
                <EuiIcon type='checkInCircleFilled' />
              </div>
            )}
          </Fragment>
        }
      />
      <EuiSpacer />
      <EuiButton size='s' onClick={reset}>
        {t('New Landing Card')}
      </EuiButton>
    </div>
  );
};

export default Confirm;
