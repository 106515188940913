import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from 'react-router-dom';

import _ from 'lodash';

import { setUser, setQueryList } from '../js/actions/index';
import store from '../jas/store/index';


// import auth from '../js/auth';

const PrivateRoute = ( { children, ...rest } ) => {

  return (
    <Route
      {...rest}
      render={( { location } ) => {
        if ( _.isEmpty( store.getState().user ) ) {
          window.location.replace( '/authenticate.html?path=' + encodeURIComponent( location.pathname ) );
        } else {
          return ( children );
        }
      }
      }
    />
  );
};

export default PrivateRoute;
