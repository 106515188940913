
import React, { useState } from 'react';
import { EuiProgress } from '@elastic/eui';

import { useTranslation } from 'react-i18next';

const ProgressBar = ( { data, settings } ) => {

  let valueData = data || 0;
  //const [ valueData, setValueData ] = useState( data | 0 );
  //const [ color, setColor ] = useState( 'secondary' );

  let color = 'secondary';

  const { t } = useTranslation();

  try {


    if ( settings.multiplyValue ) {
      valueData = valueData * settings.multiplyValue;
    }

    if ( settings.withColors ) {

      color = 'danger';

      if ( valueData < 80 ) {
        color = 'warning';
      }

      if ( valueData < 60 ) {
        color = 'secondary';
      }

    }

    return (
      <EuiProgress value={valueData} max={settings.max || 100} color={color} size={settings.size || 'm'} />
    );
  } catch ( err ) {
    console.log( 'ProgressBar error', err );
    return ( {
      resp: <div>NA {data}</div>
    } );
  }
};

export { ProgressBar };
