import React, { useState, useEffect } from 'react';
import {
  EuiFlexItem,
  EuiButton
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

const IFormFieldButton = ({ onClick, updateLoader, buttonName }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [displayName, setDisplayName] = useState(buttonName);
  const [locked, setLocked] = useState(false);

  const defaulLoaderValues = {
    loading: false,
    text: buttonName,
    locked: false
  };

  const { t } = useTranslation();

  useEffect(() => {
    updateLoader = {
      ...defaulLoaderValues,
      ...updateLoader
    };
    setIsLoading(updateLoader.loading || false);
    setDisplayName(updateLoader.text);
    setLocked(updateLoader.locked || false);

  }, [updateLoader]);


  const actionClick = () => {
    setIsLoading(true);
    setDisplayName(t('GENERIC_LOADING'));
    onClick();
  };

  return (
    <EuiFlexItem grow={false}>
      <EuiButton isLoading={isLoading} onClick={actionClick} isDisabled={locked}>{t(displayName)}</EuiButton>
    </EuiFlexItem>
  );
};

export { IFormFieldButton as default, IFormFieldButton };
