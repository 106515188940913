import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EuiSuperSelect, EuiHealth, EuiShowFor, EuiPopover, EuiHeaderLink, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui';
import { setConsoleProject, getConsoleProjects, getConsoleUser } from '../../jas/state/consoleOptions';
import { B3ObjectFieldV2 } from '../../components/jsFnI/helpers/fieldParser/b3ObjectFieldV2';

const HeaderProjectSelector = ( props ) => {
  const { t } = useTranslation();
  const [ projectSelected, setProjectSelected ] = useState();
  const [ projectOptions, setProjectOptions ] = useState( [] );
  const [ popoverOpen, setPopoverOpen ] = useState( false );


  //Parse Projects
  useEffect( () => {

    const fieldSettings = {
      display_multiple: false,
      data: [ '_reference' ],
      type: 'text'
    };

    const a = getConsoleUser();
    const b = getConsoleProjects( a );

    const projectOptions = [];
    for ( const project of b.options ) {
      projectOptions.push( {
        value: project.id,
        inputDisplay: ( <EuiHealth color="primary" style={{ lineHeight: 'inherit' }}>
          <B3ObjectFieldV2 data={project.id} settings={fieldSettings} />
        </EuiHealth> ),
        disabled: false
      } );
    }

    if ( b.selected ) {
      setProjectSelected( b.selected );
    }

    setProjectOptions( projectOptions );


  }, [] );


  const onChange = ( value ) => {
    setProjectSelected( value );
    setConsoleProject( getConsoleUser(), value );
    window.location.reload( false );

  };

  const projectSelector = <EuiSuperSelect
    options={projectOptions}
    valueOfSelected={projectSelected}
    hasDividers={true}
    prepend={t( 'PROJECT' )}
    onChange={value => onChange( value )}
  />;

  const button = (
    <EuiHeaderLink iconType="database" href="#" onClick={( click ) => { setPopoverOpen( true ); }} selected={popoverOpen} /> );

  const display =
    <>
      { // Handling for mobile
      }
      <EuiShowFor sizes={[ 'xs', 's' ]}>

        <EuiPopover
          id='projectSelectorPopover'
          ownFocus
          button={button}
          isOpen={popoverOpen}
          closePopover={() => setPopoverOpen( false )}      >
          {projectSelector}
        </EuiPopover>

      </EuiShowFor>
      { // Handling for desktop
      }

      <EuiShowFor sizes={[ 'm', 'l', 'xl' ]} display={'inlineBlock'}>

        <EuiText></EuiText>
        {projectSelector}

      </EuiShowFor>
    </>;

  return ( display );
};

export { HeaderProjectSelector as default, HeaderProjectSelector };
