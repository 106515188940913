const schema = {
  settings: {
    maxDisplayLimit: 100,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
      click: {
        type: 'none',
        view: 'flyout',
        schema: 'none',
        featureRequired: 'none'
      }
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    actions: [ 'edit', 'archive' ],
    queryFields: [ 'payload.name' ]
  },
  query: {
    kind: 'entityV2',
    project_id: '{{CONSOLE_SELECTED_PROJECT}}',
    operator: 'and',
    //collapse: 'payload.entity',
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      data: '_reference',
      i18nTitle: 'REFERENCE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      data: 'payload.type',
      i18nTitle: 'TYPE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },

    {
      data: 'createdAt',
      i18nTitle: 'CREATED_AT',
      sortable: true,
      relative: true,
      type: 'formatedDate',
      //  format: 'MMMM Do YYYY, h:mm:ss a'
    }
  ]
};

export { schema as default };
