import React, { useState, useEffect } from 'react';
import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiLoadingSpinner,
  EuiTitle,
  EuiButton,
  EuiSpacer
} from '@elastic/eui';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import api from '../../js/api';
import { useError } from '../Error';

let editor;

const IListEdit = ({ id, close }) => {
  const [item, setItem] = useState(null);
  const addError = useError();
  let container = React.createRef();

  useEffect(() => {
    if (id)
      (async () => {
        try {
          const res = await api.describe(id);
          setItem(res.data.payload);
        } catch (er) {
          addError({
            title: er.message,
            color: 'danger',
            iconType: 'help'
          });
        }
      })();
  }, [id]);

  useEffect(() => {
    if (!item) return;
    editor = new JSONEditor(container.current, {
      onChangeJSON: (json) => {
        console.log(json)
      }
    });
    editor.set(item);
    // setItem(editor.get());
  }, [item]);

  const onSave = async () => {
    // console.log(editor.get())
    try {
      const res = await api.updateObject(id, { payload: editor.get() })
      console.log(res);
      close()
    } catch (er) {
      addError({
        title: er.message,
        color: 'danger',
        iconType: 'help'
      });
    }
  }

  return id ? (
    <EuiFlyout
      ownFocus
      onClose={close}
      size='s'
      aria-labelledby='flyoutSmallTitle'
    >
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size='s'>
          <h2 id='flyoutSmallTitle'>Edit item</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        {item ? (
          <div>
            <div ref={container} />
            <EuiSpacer />
            <EuiButton onClick={onSave} fill>Save</EuiButton>
          </div>
        ) : (
            <EuiLoadingSpinner />
          )}
      </EuiFlyoutBody>
    </EuiFlyout>
  ) : '';
};

export default IListEdit;
