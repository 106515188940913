
import React, { Component } from 'react';
import { EuiLoadingSpinner, EuiInMemoryTable } from '@elastic/eui';

import { useTranslation } from 'react-i18next';

import api from '../../../../js/api';
import FieldParser from '.';




const Table = ( { data, schema } ) => {

  const { t } = useTranslation();


  try {
    const columns = [];

    for ( const column of schema ) {

      columns.push( {
        field: column.field,
        name: t( column.i18nTitle ),
        description: t( column.i18nTitle ),
        sortable: column.sortable,
        truncateText: column.truncateText,
        textOnly: column.truncateText,
        render: ( hold, item ) => (
          <FieldParser field={column} data={item} />
        ),
      } );



    }

    const sorting = {
      sort: {
        field: 'createdAt',
        direction: 'desc',
      },
    };

    return (
      <EuiInMemoryTable
        items={data}
        columns={columns}
        sorting={sorting}
        tableLayout="fixed"
      />
    );
  } catch ( err ) {
    console.log( 'Table error', err );
    return ( {
      resp: <div>NA ({this.props.data})</div>
    } );
  }
};

export { Table };
