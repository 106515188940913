import { combineReducers } from 'redux';
import errors from '../../js/reducers//errors';

function user ( state = null, action ) {
  switch ( action.type ) {
    case 'SET_USER':
      return { ...action.payload };
    default:
      return state;
  }
}

function userMeta ( state = null, action ) {
  switch ( action.type ) {
    case 'SET_USER_META':
      return { ...action.payload };
    default:
      return state;
  }
}

function breadcrumbs ( state = [
  { i18nText: 'FORENSIC', href: '/#' }
], action ) {
  switch ( action.type ) {
    case 'SET_BREADCRUMBS':
      return [ ...action.payload ];
    default:
      return state;
  }
}

function redirect ( state = null, action ) {
  switch ( action.type ) {
    case 'SET_REDIRECT':
      return action.payload;
    default:
      return state;
  }
}


function queryList ( state = null, action ) {
  switch ( action.type ) {
    case 'SET_QUERY_LIST':
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers( {
  user,
  userMeta,
  breadcrumbs,
  redirect,
  queryList,
  errors
} );
