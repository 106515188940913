/**
 *  Functions list:
 *  -----------------
 *  flattenObject
 *  capitalizeFirstLetter
 *  byteDataToArray
 *
 */

export const flattenObject = ob => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const capitalizeFirstLetter = s => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const arabicEncoding = array => {
  let tmpArray = array;
  let splitArrayIdx = tmpArray.indexOf(0x23);
  let resultArray = [];
  let isodecoder = new TextDecoder('windows-1256');

  while (splitArrayIdx > 0) {
    var str = isodecoder.decode(
      new Uint8Array(tmpArray.slice(0, splitArrayIdx))
    );
    resultArray.push(str);
    tmpArray = tmpArray.slice(splitArrayIdx + 1);
    splitArrayIdx = tmpArray.indexOf(0x23);
  }
  return resultArray;
};

export const defaultEncoding = array => {
  let utf8decoder = new TextDecoder();
  return utf8decoder.decode(array).split(';');
};

export const handleScroll = cb => {
  return () => {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      cb();
    }
  };
};
