import React, { useState } from 'react';
import {
  EuiSpacer,
  EuiSwitch,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiToast
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import BackgroundCheck from './BackgroundCheck';
import HistoryCheck from './HistoryCheck';
import AutoFees from './AutoFees';
import { useError } from '../../../../Error';
import API from '../../../../../js/api';

const Validate = ( { data, setStep, onSubmit } ) => {
  const payload = data && data.payload;

  const [ submitting, setSubmitting ] = useState( false );
  const [ error, setError ] = useState( false );
  const [ paymentCollected, setPaymentCollected ] = useState( false );
  const [ feesSchema, setFeesSchema ] = useState(
    payload && payload.auto_fees.selection
  );

  const { t } = useTranslation();
  const addError = useError();

  if ( !payload ) return <div>{t( 'Loading' )}</div>;

  const onChange = e => {
    setPaymentCollected( Boolean( e.target.checked ) );
  };

  const submit = async () => {
    try {
      setError( false );
      setSubmitting( true );
      const res = await API.updateObject(
        data.id,
        {
          project_id: data.project_id,
          kind: data.kind,
          payload: {
            fees_scheme: feesSchema,
            payment_collected: paymentCollected.toString()
          },
          status: 'active'
        },
        {
          instant: true
        }
      );
      onSubmit( res.data );
    } catch ( er ) {
      console.log( er );
      setSubmitting( false );
      setError( er.message );
      addError( {
        title: er.code,
        text: er.message,
        color: 'danger',
        iconType: 'crossInACircleFilled'
      } );
    }
  };

  return (
    <div>
      {/* {payload.background_check.enabled !== 'false' && <BackgroundCheck bc={payload.background_check} />} */}

      {payload.history_check.enabled !== 'false' && (
        <HistoryCheck hc={payload.history_check} />
      )}

      <AutoFees
        af={payload.auto_fees}
        setFeesSchema={setFeesSchema}
        feesSchema={feesSchema}
      />
      <EuiSpacer size='xl' />
      <EuiSwitch
        data-id='payment_collected'
        name='payment_collected'
        label={t( 'Payment Collected' )}
        checked={paymentCollected}
        onChange={onChange}
      />
      <EuiSpacer size='xxl' />
      <EuiFlexGroup gutterSize='s' alignItems='center'>
        <EuiFlexItem grow={false}>
          <EuiButton fill onClick={submit} disabled={submitting}>
            {t( 'Proceed' )}
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={() => setStep( 1 )}>
            {t( 'GENERIC_BACK' )}
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {error && (
        <EuiToast title={t( 'ERROR_OCCURED' )} color='danger' iconType='alert'>
          <p>{error}</p>
        </EuiToast>
      )}
    </div>
  );
};

export default Validate;
