import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRedirect } from './redirect';
import _ from 'lodash';

import {
  EuiHeaderBreadcrumbs,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

const useBreadcrumbs = () => {
  const dispatch = useDispatch();
  return payload => {
    if ( _.isEmpty( payload ) ) {
      payload = { i18nText: 'FORENSIC', href: '/#' };
    }
    dispatch( { type: 'SET_REDIRECT', payload } );
  };
};


const Breadcrumbs = ( props ) => {
  const addRedirect = useRedirect();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breadcrumbs = useSelector( state => state.breadcrumbs );
  let breadcrumbsReturn = [];

  for ( let crumb of breadcrumbs ) {

    let bread = {
      text: t( crumb?.i18nText )
    };

    if ( crumb?.href ) {
      bread.onClick = () => { addRedirect( crumb?.href ); };
    }

    breadcrumbsReturn.push( bread );
  }
  return breadcrumbsReturn;

};

export { Breadcrumbs as default, Breadcrumbs, useBreadcrumbs };
