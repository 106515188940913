export default {
  settings: {
    textStyle: 'reverse',

    title: '_reference',
    i18nTitleSuffix: 'DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      width: '10%',
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'image',

      data: 'payload.metadata.sourceAttachmentId',
      i18nTitle: 'SOURCE_IMAGE',
      sortable: true,
      type: 'image',
      showOnMobile: true,
      size: 'l',
      alt: 'payload.metadata.sourceAttachmentId',
      allowFullScreen: false,
      hasShadow: true
    },
    {
      field: 'recognitionStatus',

      data: 'payload.metadata.recognitionStatus',
      i18nTitle: 'RECOGNITION_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'recognitionsTotalCount',

      data: 'payload.metadata.recognitionsTotalCount',
      i18nTitle: 'GENERIC_RECOGNITION_COUNT',
      sortable: true,
      type: 'text',
      showOnMobile: true,
      default: 'NA'
    },
    {
      field: 'matchingImagesTable',

      data: 'payload.recognitions.0.results.matchingImages',
      i18nTitle: 'IR_MATCHING_IMAGES',
      sortable: 'false',
      type: 'table',
      showOnMobile: true,
      tableSchema: [
        {
          field: 'url',

          data: 'url',
          i18nTitle: 'WEBLINK',
          truncateText: true,
          type: 'link',
          showOnMobile: true,
          label: 'url'
        },
        {
          field: 'description',

          data: 'weblinkMetadata.description',
          i18nTitle: 'GENERIC_DESCRIPTION',
          truncateText: true,
          type: 'text',
          showOnMobile: true,
        },
        {
          field: 'createdAt',
          data: 'weblinkMetadata.date',
          i18nTitle: 'GENERIC_CREATED_AT',
          sortable: true,
          type: 'formatedDate',
          format: 'MMMM Do YYYY, h:mm:ss a'
        }
      ]
    },
    {
      field: 'matchingPages',

      data: 'payload.recognitions.0.results.webPages',
      i18nTitle: 'IR_MATCHING_WEBPAGES',
      sortable: 'false',
      type: 'table',
      showOnMobile: true,
      tableSchema: [
        {
          field: 'url',

          data: 'url',
          i18nTitle: 'WEBLINK',
          truncateText: true,
          type: 'link',
          showOnMobile: true,
          label: 'url'
        },
        {
          field: 'description',

          data: 'weblinkMetadata.description',
          i18nTitle: 'GENERIC_DESCRIPTION',
          truncateText: true,
          type: 'text',
          showOnMobile: true,
        },
        {
          field: 'createdAt',
          data: 'weblinkMetadata.date',
          i18nTitle: 'GENERIC_CREATED_AT',
          sortable: true,
          type: 'formatedDate',
          format: 'MMMM Do YYYY, h:mm:ss a'
        }
      ]
    },
    {
      field: 'matchingLabels',

      data: 'payload.recognitions.0.results.labels',
      i18nTitle: 'IR_MATCHING_LABELS',
      sortable: 'false',
      type: 'table',
      showOnMobile: true,
      tableSchema: [
        {
          field: 'description',

          data: [ 'description', ' ', '(', 'entityId', ')' ],
          i18nTitle: 'GENERIC_DESCRIPTION',
          truncateText: true,
          type: 'text',
          showOnMobile: true,
          label: 'url',
          default: ''
        },
        {
          field: 'confidence',

          data: 'confidence',
          i18nTitle: 'GENERIC_CONFIDENCE',
          truncateText: true,
          type: 'numberRound',
          showOnMobile: true,
        }
      ]
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};
