import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EuiFlexGrid,
  EuiFlexItem,
  EuiCard,
  EuiIcon
} from '@elastic/eui';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setBreadcrumbs } from '../../../js/actions/index';
import PageLayout from '../../ui/PageLayout';
import nav from '../../../js/nav';
import { useRedirect } from '../../../jas/state/redirect';
import { getConsoleSettings } from '../../../jas/state/consoleOptions';
import store from '../../../jas/store';


import { useLocation } from 'react-router-dom';


const Page = () => {
  const [ navItems, setNavItems ] = useState( null );
  let history = useHistory();
  const { t } = useTranslation();
  const addRedirect = useRedirect();
  const location = useLocation();
  const consoleSettings = getConsoleSettings();


  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'CONSOLE', href: '/' },
        { i18nText: 'FORENSIC' }
      ] ) );
    setNavItems( nav( addRedirect, consoleSettings, t ) );
  }, [] );

  const displayCardNav = () => {
    return (
      navItems &&
      navItems.map( item => {
        if ( !item.featured ) return null;
        return (
          <EuiFlexItem key={item.id}>
            <EuiCard
              layout='horizontal'
              icon={<EuiIcon size='xl' type={item.iconType} />}
              title={item.label}
              description={item.desc}
              onClick={item.onClick}
            />
          </EuiFlexItem>
        );
      } )
    );
  };

  return (
    <PageLayout title={t( 'FORENSIC' )}>
      <EuiFlexGrid gutterSize='l' columns={2}>{displayCardNav()}</EuiFlexGrid>
    </PageLayout>
  );
};

export { Page as default };
