export const SET_USER = 'SET_USER';
export const SET_ENTRY_PORT = 'SET_ENTRY_PORT';
export const SET_PRINTER = 'SET_PRINTER';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

export const SET_REDIRECT = 'SET_REDIRECT';

export const SET_USER_META = 'SET_USER_META';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_QUERY_LIST= 'SET_QUERY_LIST';
