import API from '../api';
import {
  SET_USER,
  SET_ENTRY_PORT,
  SET_PRINTER,
  SET_BREADCRUMBS,
  SET_USER_META,
  SET_QUERY_LIST
} from '../constants/action-types';
import store from '../../jas/store/index';
import { addError } from './errors';
import { get } from 'axios';
import _ from 'lodash';
import { useConsoleProject, getConsoleProjects, setConsoleProjects, setConsoleUser, setConsoleSettings } from '../../jas/state/consoleOptions';

export function setBreadcrumbs ( payload ) {
  return { type: SET_BREADCRUMBS, payload };
}

export function setPrinter () {
  return async function ( dispatch ) {
    try {
      const res = await API.describe( 'oC3u9av9ek5dz5jrc' );
      dispatch( { type: SET_PRINTER, payload: res.data.payload } );
    } catch ( er ) {
      console.log( er );
      store.dispatch(
        addError( {
          title: er.code,
          text: er.message,
          color: 'danger',
          iconType: 'crossInACircleFilled'
        } )
      );
    }
  };
}

function setUserMeta ( id ) {
  return async function ( dispatch ) {
    try {
      const res = await API.describe( id );
      dispatch( { type: SET_USER_META, payload: res.data.payload } );
    } catch ( er ) {
      console.log( er );
      store.dispatch(
        addError( {
          title: er.code,
          text: er.message,
          color: 'danger',
          iconType: 'crossInACircleFilled'
        } )
      );
    }
  };
}

export function setEntryPorts () {
  return async function ( dispatch ) {
    try {
      const res = await API.query( 'object', {
        kind: 'borderexcsport',
        project_id: 'o3WWDQHpek5dtbode',
        operator: 'and',
        query: [
          {
            field: 'status',
            operator: 'IN',
            value: [ 'active', 'private', 'unlisted' ]
          }
        ]
      } );
      dispatch( { type: SET_ENTRY_PORT, payload: res.data } );
    } catch ( er ) {
      console.log( er.code );
      store.dispatch(
        addError( {
          title: er.code,
          text: er.message,
          color: 'danger',
          iconType: 'crossInACircleFilled'
        } )
      );
    }
  };
}

export function setUser () {
  return async function ( dispatch ) {
    try {
      const tokenInfo = await API.tokeninfo();

      dispatch( { type: SET_USER, payload: tokenInfo.data.user } );

      const consoleProject = await getConsoleProjects( tokenInfo.data.user.id );

      setConsoleUser( tokenInfo.data.user.id );

      let preSelectedProject = consoleProject?.selected;

      await setConsoleProjects( tokenInfo.data.user.id, {
        options: tokenInfo.data.user.projects,
        primaryProjectId: tokenInfo.data.user.primaryProjectId,
        selected: preSelectedProject || tokenInfo.data.user.primaryProjectId
      } );

      tokenInfo.data.user.projects.forEach( project => {
        if ( project?.consoleSettings ) {
          setConsoleSettings( tokenInfo.data.user.id, project.id, project.consoleSettings );
        }
      } );
    } catch ( er ) {
      console.log( 'er.code', er );
      store.dispatch(
        addError( {
          title: er.code,
          text: er.message,
          color: 'danger',
          iconType: 'crossInACircleFilled'
        } )
      );
    }
  };
}

export function setQueryList () {
  return async ( dispatch ) => {
    const data = await get( '/img/queries.json' );
    dispatch( { type: SET_QUERY_LIST, payload: data.data } );
  };
}
