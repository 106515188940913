import React, { useEffect, useState, Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import b3 from '../../js/api';

import { FieldBuilder, GenericLoading } from './helpers';
import { useError } from '../Error';

import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuggest,
  EuiSuperDatePicker
} from '@elastic/eui';


import _ from 'lodash';

import { useRedirect, useQuery, GetQueryParameter } from '../../jas/state/redirect';


const FilterBuilder = ( props ) => {
  //{ schema: any, onUpdate: FunctionComponent, lockParent: FunctionComponent, formFieldsValid: FunctionComponent, b3Project?: String, data?: any; }

  const [ fields, setFields ] = useState();
  const [ formValues, setFormValues ] = useState( [] );
  const [ data, setData ] = useState( props.data || {} );
  const manageQuery = useQuery();
  const getUrlQuery = GetQueryParameter();
  const addError = useError();
  let { t } = useTranslation();

  const onUpdateHandler = ( fieldName, value, operator, uiValue ) => {
    console.log( 'FilterBuilder', 'updateHandler', fieldName, value, operator );

    _.remove( formValues, function ( n ) {
      return n.field == fieldName;
    } );
    if ( value ) {
      formValues.push( {
        field: fieldName,
        operator: operator,
        value: value,
        uiValue: uiValue
      } );
    }
    setFormValues( formValues );


    throttled();

  };

  const throttled = _.throttle( () => {
    if ( props.schema.settings.updateQueryParameter ) {
      manageQuery( 'set', 'filters', JSON.stringify( formValues ) );
    }
    console.log( 'FilterBuilder', 'throlled called' );


    props.onUpdate( formValues );


  }, 1000, { leading: false, trailing: true } );

  useEffect( () => {
    ( async () => {
      if ( props.schema.fields ) {
        try {
          let tmpItems = [];
          for ( let item of props.schema.fields ) {
            tmpItems.push(
              ( <FieldBuilder field={item}
                onUpdate={( name, value, uiValue ) => { onUpdateHandler( name, value, item.operator, uiValue ); }}
                lockParent={props.lockParent}
                type={'inline'}
                data={data} /> )
            );
          }
          setFields( tmpItems );
        } catch ( err ) {
          //console.error( 'Form Builder Error', err );
          addError( {
            title: t( 'GENERIC_FORM_BUILD_ERROR' ),
            text: err.message,
            color: 'danger',
            iconType: 'help'
          } );
        }
      }
    } )();
  }, [ props.schema, data ] );

  // Handle query filters

  useEffect( () => {
    if ( props.schema.settings.parseQueryParameter ) {
      ( async () => {
        try {
          if ( getUrlQuery( 'filters' ) ) {
            const filterParsed = JSON.parse( getUrlQuery( 'filters' ) );
            let tmpData = {};
            for ( const filter of filterParsed ) {
              if ( filter.uiValue ) {
                tmpData[ filter.field ] = filter.uiValue;
              } else {
                tmpData[ filter.field ] = filter.value;
              }
              onUpdateHandler( filter.field, filter.value, filter.operator, filter.uiValue );
            }
            setData( tmpData );
          }

        } catch ( err ) {
          //console.error( 'Form Builder Error', err );
          addError( {
            title: t( 'GENERIC_FORM_BUILD_ERROR' ),
            text: err.message,
            color: 'danger',
            iconType: 'help'
          } );
        }
      } )();
    }
  }, [ getUrlQuery( 'filters' ) ] );

  return (
    <div className="savedQueriesInput">
      <EuiFlexGroup
        gutterSize="s"
        className={
          //this.state.hideDatepicker
          false ? 'savedQueriesInput__hideDatepicker' : ''
        }>
        {fields}
      </EuiFlexGroup>
    </div>
  );

  //return (<></>)
};

export { FilterBuilder as default, FilterBuilder };
