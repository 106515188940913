import React, { useEffect, useState } from 'react';
import { EuiFormRow, EuiFieldText, EuiFieldNumber, EuiFieldSearch, EuiFlexItem, EuiTextArea } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import IFileUpload from './IFileUpload';
import { IFormFieldSwitch } from './fieldSwitch';
import { IFormFieldSelect } from './fieldSelect';
import { IFormFieldRange } from './fieldRange';
import { IFormFieldTimeRange } from './fieldTimeRange';
import { IFormFieldSuperDate } from './fieldSuperDate';
import { IFormFieldComboBox } from './fieldComboBox';
import validatejs from 'validate.js';


import _ from 'lodash';


const FieldBuilder = ( { field, data, onUpdate, lockParent, isValid, type } ) => {
  const [ invalid, setInvalid ] = useState( false );
  const [ render, setRender ] = useState( null );
  const [ defaultValue, setDefaultValue ] = useState();

  const [ error, setError ] = useState( 'ERROR' );

  const isUrl = ( str ) => {
    var pattern = new RegExp( '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i' ); // fragment locator
    return !!pattern.test( str );
  };


  const { t } = useTranslation();

  const validateAndUpdate = ( name, value, validationRules, uiValue, changeDefault ) => {
    //console.log( 'FieldBuilder', 'validateAndUpdate', name, value );
    if ( changeDefault ) {
      setDefaultValue( value );
    }

    if ( _.isEmpty( validationRules ) ) {
      onUpdate( name, value, uiValue );
    } else {
      for ( let rule in validationRules ) {
        switch ( rule ) {
          case 'presence':
            if ( validationRules[ rule ] === true && _.isEmpty( value ) ) {
              setInvalid( true );
              if ( isValid ) {
                isValid( name, false );
              }
              onUpdate( name, value, uiValue );
              setError( t( 'FIELD_REQUIRED' ) );
            } else {
              if ( isValid ) {
                isValid( name, true );
              }
              onUpdate( name, value, uiValue );
              setInvalid( false );
            }
            break;
          case 'url':
            if ( isUrl( value ) ) {
              if ( isValid ) {
                isValid( name, true );
              }
              setInvalid( false );
              onUpdate( name, value, uiValue );
            } else {
              setInvalid( true );
              if ( isValid ) {
                isValid( name, false );
              }
              onUpdate( name, value, uiValue );
              setError( t( 'FIELD_INVALID_URL' ) );
            }
            break;
          default:
            if ( isValid ) {
              isValid( name, true );
            }
            onUpdate( name, value, uiValue );
        }

      }

    }
  };

  useEffect( () => {


    //console.log( 'DATA11', data );

    setDefaultValue( _.get( data, field.name, field.default ) );

    if ( isValid ) {
      //Return default valid fields from initiliasation
      if ( _.isEmpty( field.validation ) ) {
        isValid( field.name, true );
      } else {
        isValid( field.name, false );
      }
    };



  }, [ data ] );

  let renderedField;
  switch ( field.type ) {
    case 'text':
      renderedField =
        <EuiFieldText
          placeholder={t( field.i18nPlaceHolder )}
          value={defaultValue}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation, null, true )}
        />;
      break;

    case 'textarea':
      renderedField =
        <EuiTextArea
          placeholder={t( field.i18nPlaceHolder )}
          value={defaultValue}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation, null, true )}
        />;
      break;

    case 'number':
      renderedField =
        <EuiFieldNumber
          placeholder={t( field.i18nPlaceHolder )}
          value={defaultValue}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation, null, true )}
        />;
      break;
    case 'fileUpload':
      renderedField =
        <IFileUpload
          {...field.fileUploadSettings}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation )}
          lockParent={( v ) => lockParent( v )} />;
      break;
    case 'switch':
      renderedField =
        <IFormFieldSwitch
          label={t( field.label )}
          defaultValue={defaultValue}
          onChange={( v ) => validateAndUpdate( field.name, v.target.checked, field.validation )} />;
      break;
    case 'select':
      renderedField =
        <IFormFieldSelect
          options={field.listSettings.options}
          defaultValue={defaultValue}
          isInvalid={invalid}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation )} />;
      break;
    case 'comboBox':
      renderedField =
        <IFormFieldComboBox
          settings={field.listSettings}
          defaultValue={defaultValue}
          isInvalid={invalid}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation, v.target.uiValue )} />;
      break;
    case 'search':
      renderedField =
        <EuiFieldSearch
          placeholder={t( field.i18nPlaceHolder )}
          value={defaultValue}
          fullWidth={field?.settings?.fullwidth || true}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation, null, true )}
          isClearable={field?.settings?.clearable} />;
      break;
    case 'range':
      renderedField =
        <IFormFieldRange
          config={field.rangeSettings}
          defaultValue={defaultValue}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation )} />;
      break;
    case 'timeRange':
      renderedField =
        <IFormFieldTimeRange
          config={field.rangeSettings}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation )} />;
      break;
    case 'superDate':
      renderedField =
        <IFormFieldSuperDate
          config={field.rangeSettings}
          defaultValue={defaultValue}
          onChange={( v ) => validateAndUpdate( field.name, v.target.value, field.validation )} />;
      break;
    case 'hidden':
      onUpdate( field.name, defaultValue );
      renderedField = <></>;
      break;
    default:
      renderedField = <div>Form Field Type not supported.</div>;
  }


  switch ( type ) {
    case 'form':
      return (
        <EuiFormRow
          label={t( field.i18nLabel )}
          helpText={t( field.i18nHelp )}
          error={t( error )}
          isInvalid={invalid}>
          {renderedField}
        </EuiFormRow> );
      break;
    case 'inline':
      return ( <EuiFlexItem>
        {renderedField}
      </EuiFlexItem> );
      break;
  }

  //return render;

};

export { FieldBuilder as default, FieldBuilder };
