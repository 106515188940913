export default {
  settings: {
    textStyle: 'reverse',

    title: ' ',
    i18nTitleSuffix: 'POST_DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'video',

      data: [ 'payload.videoKEY' ],
      i18nTitle: 'VIDEO',
      sortable: true,
      type: 'videoPlayer',
      showOnMobile: true,
      size: 's',
      hasShadow: true
    },
    {
      data: '_reference',
      i18nTitle: 'GENERIC_DESCRIPTION',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true
    },
    {
      data: 'payload.length',
      i18nTitle: 'VIDEO_LENGTH',
      sortable: true,
      type: 'numberRound',
      showOnMobile: true
    },
    {
      field: 'publisher',

      data: 'parent',
      i18nTitle: 'PUBLISHER',
      sortable: false,
      showOnMobile: true,
      type: 'b3ObjectField',

      display: {
        field: 'id',
        data: [ 'https://facebook.com/', 'payload.id' ],
        type: 'link',
        label: 'payload.name'
      }
    },
    {
      field: 'likes',

      data: 'payload.metrics.likes',
      i18nTitle: 'LIKES_COUNT',
      sortable: true,
      type: 'numberRound',
      showOnMobile: true
    },
    {
      field: 'comments',

      data: 'payload.metrics.comments',
      i18nTitle: 'COMMENTS_COUNT',
      sortable: true,
      type: 'numberRound',
      showOnMobile: true
    },
    {
      field: 'link',

      i18nTitle: 'VIEW_ON_FACEBOOK',
      showOnMobile: true,
      data: [ 'https://facebook.com/', 'payload.fbId' ],
      type: 'link',
      label: 'facebook.com/...',
      external: true,
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};
