const schema = {
  settings: {
    maxDisplayLimit: 1000,
    refreshButton: false,
    row: {
      selectable: false, //Map to isSelectable
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'desc',  //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    action: {
    }
  },
  query: {
    kind: 'newsArticle',
    project_id: 'oYsfCBg3ek44zbru0',
    orderBy: 'createdAt desc',
    operator: 'and',
    maxResults: 50,
    query: [
      {
        field: 'status',
        operator: 'IN',
        value: [
          'active',
          'private',
          'unlisted'
        ]
      }
    ]
  },
  fields: [
    {
      field: 'reference',
      data: '_reference',
      i18nTitle: 'GENERIC_DESCRIPTION',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true,
      width: '50vw'
    },
    {
      data: 'payload.publisher',
      i18nTitle: 'PUBLISHER',
      sortable: false,
      type: 'text',
      showOnMobile: true,
      width: '10vw'
    },
    {
      field: 'link',
      i18nTitle: 'LINK',
      showOnMobile: true,
      data: [ 'payload.url' ],
      type: 'linkV2',
      linkSettings: {
        label: 'payload.url',
        external: true,
        trimAfter: '45'
      }
    },
    {
      // field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'time',
      timeSettings: {
        relative: true,
        showToolTip: true,
        toolTipFormat: 'MMMM Do YYYY, h:mm a'
      },
      width: '10vw'

      //format: 'MMMM Do YYYY, h:mm:ss a'
    }
  ]
};

export { schema as default };
