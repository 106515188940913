import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPageContent,
  EuiPageContentBody,
  EuiSpacer
} from '@elastic/eui';

import {
  setEntryPorts,
  setBreadcrumbs
} from '../../../../js/actions/index';

import Multistep from './MultiStep';
import CreatePassenger from './CreatePassenger/Index';
import Validate from './validate/Validate';
import Confirm from './Confirm';
import formManager from '../../../../js/formManager';

const mapStateToProps = ( { userMeta } ) => ( {
  userMeta
} );

const mapDispatchToProps = dispatch => ( {
  setEntryPorts,
  setBreadcrumbs,
  dispatch
} );

const CreateTransit = ( { userMeta, setEntryPorts, setPrinter, dispatch } ) => {
  const [ step, setStep ] = useState( 1 );
  const [ step2data, setStep2Data ] = useState( null );
  const [ step3data, setStep3Data ] = useState( null );

  useEffect( () => {
    if ( !userMeta ) return;
    if ( userMeta[ 'lc_default_entry_port' ] ) {
      formManager.formFields[ 'entry_port' ] = userMeta[ 'lc_default_entry_port' ];
    }
    dispatch( setEntryPorts() );
    dispatch(
      setBreadcrumbs( [
        { i18nText: 'Border Management', href: '/' },
        { i18nText: 'EXCS', href: '/' },
        { i18nText: 'New Landing', href: '/' }
      ] )
    );
    const printerInterval = window.setInterval( setPrinter, 30000 );
    document.title = 'New Landing - L1 Border Management';
    return () => clearInterval( printerInterval );
  }, [ userMeta ] );

  const onStepOneSubmit = ( data, form ) => {
    formManager.updateForm( form );
    setStep( 2 );
    setStep2Data( data );
  };

  const onStepTwoSubmit = data => {
    setStep3Data( data );
    setStep( 3 );
  };

  const reset = () => {
    setStep( 1 );
    formManager.reset();
    const scrollAnimation = setInterval( () => {
      if ( window.pageYOffset > 0 ) window.scrollBy( 0, -window.pageYOffset / 25 );
      else {
        clearInterval( scrollAnimation );
        window.scrollTo( 0, 0 );
      }
    }, 8 );
  };

  const StepContent = () => {
    if ( step === 1 )
      return (
        <CreatePassenger
          onSubmit={onStepOneSubmit}
          formFields={formManager.formFields}
        />
      );
    else if ( step === 2 )
      return (
        <Validate
          data={step2data}
          setStep={setStep}
          onSubmit={onStepTwoSubmit}
        />
      );
    else return <Confirm data={step3data} reset={reset} />;
  };

  return (
    <EuiPage className='euiNavDrawerPage' restrictWidth={600}>
      <EuiPageBody className='euiNavDrawerPage__pageBody'>
        <EuiPageHeader>
          <EuiPageHeaderSection style={{ width: '100%' }}>
            <EuiSpacer />
            <Multistep step={step} />
            <EuiSpacer />
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPageContent>
          {/* <EuiPageContentHeader>
            <EuiPageContentHeaderSection style={{ width: '100%' }}>
            </EuiPageContentHeaderSection>
          </EuiPageContentHeader> */}
          <EuiPageContentBody>
            <StepContent />
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default connect( mapStateToProps, mapDispatchToProps )( CreateTransit );
