
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import api from '../../../../js/api';
import { EuiLoadingSpinner } from '@elastic/eui';
import _ from 'lodash';



class VideoPlayer extends Component {
  constructor () {
    // @ts-ignore
    super();
    this.state = { player: ( <EuiLoadingSpinner size='s' /> ) };

  }

  async componentDidMount () {
    try {
      let a;
      if ( this.props.src.startsWith( 'http' ) ) {
        a = { data: { payload: { download: { url: this.props.src } } } };
      } else {
        a = await api.describeAttachment( this.props.src );
      }
      this.setState( {
        player: (
          <ReactPlayer
            url={a.data.payload.download.url}
            controls
            width='100%'
            height='100%'
          />
        )
      } );
    } catch ( err ) {
      console.error( 'VideoPlayer Error:', err );
      this.setState( {
        player: <div>Player loading failed.</div>
      } );
    }
  }

  render ( {
    src,
    settings
  } = this.props ) {

    return (
      this.state.player
    );
  }
}

export { VideoPlayer };
