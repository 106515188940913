import { v3b4 as B3 } from '@l1/b3';

const apiKey = localStorage.getItem( 'apiKey' );

let b3Host = 'databook.tactint.com';
let b3ServiceVersion = 'v35';

switch ( window.location.hostname ) {
  case 'console-beta.tactint.com':
    b3Host = 'databook.tactint.com';
    b3ServiceVersion = 'latest';
    break;
  case 'console.local.tactint.com':
    b3Host = 'databook.tactint.com';
    b3ServiceVersion = 'latest';
    break;
}


const api = new B3( { apiKey, host: b3Host, version: b3ServiceVersion, uploadHost: 'databook-attachments.tactint.com' } );

export { api as default };
