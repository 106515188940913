export default {
  settings: {
    textStyle: 'reverse',

    title: '_reference',
    i18nTitleSuffix: 'DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'id',

      data: '_reference',
      i18nTitle: 'GENERIC_REFERENCE',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'query',

      data: 'payload.metadata.query',
      i18nTitle: 'QUERY',
      sortable: true,
      truncateText: true,
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'recognition_status',

      data: 'payload.metadata.proccessingStatus',
      i18nTitle: 'SEARCH_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'results_count',

      data: 'payload.credentials',
      i18nTitle: 'GENERIC_RESULT_COUNT',
      sortable: true,
      type: 'arrayCount',
      showOnMobile: true
    },
    {
      field: 'continuousCheck',

      data: 'payload.metadata.continuousCheck',
      i18nTitle: 'LS_CONTINUOUS',
      sortable: true,
      showOnMobile: true,
      align: 'center',
      type: 'modernBool'
    },
    {
      field: 'credentials',

      data: 'payload.credentials',
      i18nTitle: 'LS_CREDENTIALS',
      sortable: 'false',
      type: 'table',
      showOnMobile: true,
      tableSchema: [
        {
          field: 'email',
          data: 'email',
          i18nTitle: 'GENERIC_EMAIL',
          sortable: true,
          truncateText: true,
          type: 'text'
        },
        {
          field: 'username',
          data: 'username',
          i18nTitle: 'GENERIC_USERNAME',
          sortable: true,
          truncateText: true,
          type: 'text'
        },
        {
          field: 'password',
          data: 'password',
          i18nTitle: 'GENERIC_PASSWORD',
          sortable: true,
          truncateText: true,
          type: 'text',
        },
        {
          field: 'hashed_password',
          data: 'hashedPassword',
          i18nTitle: 'HASHED_PASSWORD',
          sortable: true,
          truncateText: true,
          type: 'text',
        },
        {
          field: 'ip_address',
          data: 'ipAddress',
          i18nTitle: 'GENERIC_IP_ADDRESS',
          sortable: true,
          truncateText: true,
          type: 'text',
        },
        {
          field: 'source',
          data: [ '_source' ],
          i18nTitle: 'LS_SOURCE',
          sortable: true,
          truncateText: true,
          type: 'text',
        }
      ]
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};
