import React, { useState, useEffect } from 'react';
import {
  EuiTitle,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiPageContent,
  EuiPageContentBody,
  EuiDescriptionList,
  EuiSpacer,
  EuiButton
} from '@elastic/eui';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PdfScanner from './PdfScanner';
import { defaultEncoding } from '../../../js/functions';
import api from '../../../js/api';
import { setBreadcrumbs } from '../../../js/actions/index';
import { useError } from '../../Error';

const mapDispatchToProps = dispatch => ( {
  setBreadcrumbs,
  dispatch
} );

const CheckIn = ( { dispatch } ) => {
  const [ openCamera, setOpenCamera ] = useState( true );
  const [ prevUsage, setPrevUsage ] = useState( null );

  const { t } = useTranslation();
  const addError = useError();

  useEffect( () => {
    dispatch(
      setBreadcrumbs( [
        { i18nText: 'Border Management', href: '/' },
        { i18nText: 'EXCS', href: '/' },
        { i18nText: 'Check In', href: '/' }
      ] )
    );
  }, [] );

  const handleScan = async data => {
    try {
      console.log( data );
      const res = await api.createObject(
        {
          reference: 'Landing Receipt',
          project_id: 'o3WWDQHpek5dtbode',
          kind: 'borderEXCSReceiptCheck',
          parent: data[ 0 ],
          payload: {
            landing_id: data[ 1 ],
            checked: 'true'
          },
          classification: 'confidential',
          source: 'https://borders.tactint.com',
          status: 'active',
          createdAt: new Date().toISOString(),
          expiresAt: new Date().toISOString()
        },
        {
          instant: true
        }
      );
      console.log( 'checkin res.data:', res.data );
      addError( {
        title: 'Receipt validated successfully',
        color: 'success',
        iconType: 'help',
        toastLifeTimeMs: 2000
      } );
    } catch ( er ) {
      console.log( 'checkin error', er );
      addError( {
        title: er.message,
        color: 'danger',
        iconType: 'help'
      } );
      setOpenCamera( false );
      if ( er.attributes ) {
        setPrevUsage( {
          list: Object.keys( er.error.attributes ).map( d => ( {
            title: t( d ),
            description: t( er.error.attributes[ d ][ 0 ] )
          } ) ),
          msg: t( er.message )
        } );
      }
    }
  };

  return (
    <EuiPage className='euiNavDrawerPage' restrictWidth={600}>
      <EuiPageBody className='euiNavDrawerPage__pageBody'>
        <EuiPageHeader>
          <EuiPageHeaderSection style={{ width: '100%' }}>
            <EuiTitle>
              <h2>{t( 'CHECK_IN' )}</h2>
            </EuiTitle>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPageContent>
          <EuiPageContentBody>
            <PdfScanner
              onScan={handleScan}
              openCamera={openCamera}
              resultsDecoder={defaultEncoding}
            />
            {prevUsage && (
              <div>
                <EuiTitle size='s'>
                  <h4>{prevUsage.msg}</h4>
                </EuiTitle>
                <EuiSpacer />
                <EuiDescriptionList type='column' listItems={prevUsage.list} />
              </div>
            )}
            <EuiButton onClick={() => setOpenCamera( true )} size='s'>
              Continue Scanning
            </EuiButton>
          </EuiPageContentBody>
        </EuiPageContent>
      </EuiPageBody>
    </EuiPage>
  );
};

export default connect( null, mapDispatchToProps )( CheckIn );
