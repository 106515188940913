const schema = {
  settings: {
    i18nTitle: 'FORENSIC_PROFILE_CREATE',
    refreshOnCreate: true,
    showOnCreate: true,
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'forensicProfile',
      reference: '$.reference',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'reference' ],
      type: 'text',
      i18nLabel: 'GENERIC_REFERENCE',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'REFERENCE_PLACEHOLDER',
      default: '',
      showOnMobile: true,
      validation: {
        presence: true
      }
    },
    {
      name: [ 'payload', 'firstName' ],
      type: 'text',
      i18nLabel: 'FIRST_NAME',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'OPTIONAL',
      default: '',
      showOnMobile: true,
      validation: {
        presence: false
      }
    },
    {
      name: [ 'payload', 'lastName' ],
      type: 'text',
      i18nLabel: 'LAST_NAME',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'OPTIONAL',
      default: '',
      showOnMobile: true,
      validation: {
        presence: false
      }
    }
  ]
};

export { schema as default };
