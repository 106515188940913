import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { EuiButtonEmpty, EuiPopover, EuiLink } from '@elastic/eui';

// const lang = localStorage.getItem('i18nextLng') || 'en';
import { ReactComponent as UKFlag } from '../../svg/en.svg';
import { ReactComponent as LBFlag } from '../../svg/lb.svg';

const mapStateToProps = ( { userMeta } ) => ( {
  userMeta
} );

const SwitchLanguage = ( { userMeta } ) => {
  const [ isPopoverOpen, setPopoverOpen ] = useState( false );
  const { i18n } = useTranslation();

  useEffect( () => {
    if ( userMeta && userMeta[ 'app_language' ] )
      i18n.changeLanguage( userMeta[ 'app_language' ] === 'ar-lb' ? 'ar' : 'en' );
  }, [ userMeta ] );

  const lang = i18n.language;

  const button = (
    <EuiLink
      onClick={() => setPopoverOpen( true )}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '48px',
        width: '48px',
        textDecoration: 'none'
      }}
    >
      {lang.toUpperCase() === 'AR' ? <LBFlag style={{ width: '22px' }} /> : <UKFlag style={{ width: '22px' }} />}
    </EuiLink>
  );

  return (
    <EuiPopover
      id='trapFocus'
      ownFocus
      button={button}
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen( false )}
      initialFocus='[id=asdf3]'
    >
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <EuiButtonEmpty
          isDisabled={lang === 'en'}
          onClick={() => i18n.changeLanguage( 'en' )}
          size='xs'
        >
          <UKFlag style={{ width: '20px' }} />
        </EuiButtonEmpty>

        <EuiButtonEmpty
          isDisabled={lang === 'ar'}
          onClick={() => i18n.changeLanguage( 'ar' )}
          size='xs'
        >
          <LBFlag style={{ width: '20px' }} />
        </EuiButtonEmpty>
      </div>
    </EuiPopover>
  );
};

export default connect( mapStateToProps )( SwitchLanguage );
