import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  EuiLink,
  EuiAvatar,
  EuiPopover,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui';


const logout = ev => {
  ev.preventDefault();
  localStorage.removeItem( 'apiKey' );
  window.location.href = 'https://auth.tactint.com/auth/v3/logout';
};

const HeaderUserMenu = ( props ) => {
  const user = useSelector( state => state.user );
  const { t } = useTranslation();
  const [ isPopoverOpen, setPopoverOpen ] = useState( false );

  if ( !user ) return ( <div /> );

  const button = (
    <EuiLink
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '48px',
        width: '48px',
        textDecoration: 'none'
      }}
    >
      <EuiAvatar
        size='s'
        name={user.name}
        imageUrl={user.imageUrl}
        onClick={() => setPopoverOpen( true )}
      />
    </EuiLink>
  );

  return (
    <EuiPopover
      id='trapFocus'
      ownFocus
      button={button}
      isOpen={isPopoverOpen}
      closePopover={() => setPopoverOpen( false )}
      initialFocus='[id=random]'
    >
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <a href='/'>
            <EuiAvatar size='xl' name={user.name} imageUrl={user.imageUrl} />
          </a>
        </EuiFlexItem>
        <EuiFlexItem>
          <p>{user.name}</p>
          <EuiSpacer />
          <EuiFlexGroup justifyContent='spaceEvenly'>
            <EuiFlexItem grow={false}>
              <a href='/'>{t( 'ONEID_VIEW_ACCOUNT' )}</a>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <a href='https://id.tactint.com/signout' onClick={logout}>Logout</a>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};

export { HeaderUserMenu as default, HeaderUserMenu };
