import React, { useState, useEffect } from 'react';
import PageLayout from '../../../ui/PageLayout';
import { IListObjects, FilterBuilder } from '../../../jsFnI';

import {
  setBreadcrumbs,
} from '../../../../js/actions/index';

import { useTranslation } from 'react-i18next';

import listSchema from '../../../../repository/schemas/monitoring/socialwatch/list';
import filterSchema from '../../../../repository/schemas/monitoring/socialwatch/filter';
import describeSchema from '../../../../repository/schemas/forensic/faceRecognition/profiles/describe';


import { useError } from '../../../Error';
import { useQuery, GetQueryParameter } from '../../../../jas/state/redirect';


import store from '../../../../jas/store';


const Page = () => {
  const { t } = useTranslation();
  const [ filters, setFilters ] = useState( {} );
  const [ reloadList, setReloadList ] = useState( false );

  const manageQuery = useQuery();
  const getUrlQuery = GetQueryParameter();
  const addError = useError();

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'MONITORING', href: '/' },
        { i18nText: 'SOCIALWATCH', href: '/' } ] ) );
  }, [] );



  const filterUpdateHandler = ( v ) => {
    setFilters( v );
  };

  const filterBuilder = <FilterBuilder schema={filterSchema} onUpdate={filterUpdateHandler} />;


  return (
    <PageLayout header={filterBuilder}>
      <IListObjects
        query={listSchema.query}
        filters={filters}
        listSchema={listSchema}
        describeSchema={describeSchema}
        describeSize='s'
        forceReload={reloadList}
      />
    </PageLayout>
  );
};

export { Page as default };
