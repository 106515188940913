import React, { useState, useEffect } from 'react';
import {
  EuiDatePickerRange,
  EuiDatePicker
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import moment from 'moment';


const IFormFieldTimeRange = ( { config, data, onChange } ) => {

  const [ startDate, setStartDate ] = useState( moment().toISOString() );
  const [ endDate, setEndDate ] = useState( moment().add( 10, 'd' ).useTranslation );




  const { t } = useTranslation();


  const handleChange = ( field, v ) => {
    console.log( 'IFormFieldTimeRange Value', v );
    if ( field == 'startDate' ) {
      setStartDate( v.toISOString() );
    }

    if ( field == 'endDate' ) {
      setEndDate( v.toISOString() );
    }

    onChange( { target: { value: `${ startDate }-${ endDate }` } } );
  };

  return (
    <EuiDatePickerRange
      startDateControl={
        <EuiDatePicker
          selected={new moment( startDate )}
          onChange={( v ) => { handleChange( 'startDate', v ); }}
          startDate={startDate}
          endDate={endDate}
          isInvalid={startDate > endDate}
          aria-label="Start date"
          showTimeSelect
        />
      }
      endDateControl={
        <EuiDatePicker
          selected={new moment( endDate )}
          onChange={( v ) => { handleChange( 'startDate', v ); }}
          startDate={startDate}
          endDate={endDate}
          isInvalid={startDate > endDate}
          aria-label="End date"
          showTimeSelect
        />
      }
    />
  );
};

export { IFormFieldTimeRange as default, IFormFieldTimeRange };
