const schema = {
  settings: {
    i18nTitle: 'AV_DVR_EMBED_NEW',
    refreshOnCreate: true,
    showOnCreate: true,
    reloadUntilActive: false,
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'avDvrEmbed',
      reference: '$.metadata.reference',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'metadata', 'reference' ],
      type: 'text',
      i18nLabel: 'GENERIC_REFERENCE',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'GENERIC_REFERENCE_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'captureLocation' ],
      type: 'text',
      i18nLabel: 'AV_DVR_EMBED_CAPTURE_LOCATION',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'server' ],
      type: 'text',
      i18nLabel: 'AV_SERVER',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'stream' ],
      type: 'text',
      i18nLabel: 'AV_STREAM',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'availability' ],
      type: 'text',
      i18nLabel: 'AV_DVR_EMBED_AVAILABILITY',
      i18nHelp: 'AV_DVR_EMBED_AVAILABILITY_HELP',
      i18nPlaceHolder: 'AV_DVR_EMBED_AVAILABILITY_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'logoKEY' ],
      type: 'fileUpload',
      i18nLabel: 'AV_DVR_EMBED_CHANNEL_LOGO',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: '',
      fileUploadSettings: {
        accept: 'image/*',
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true,
    },
    {
      name: [ 'payload', 'source', 'embedUri' ],
      type: 'text',
      i18nLabel: 'AV_DVR_EMBED_PLAYER_URL',
      i18nHelp: 'AV_DVR_EMBED_PLAYER_URL_HELP',
      i18nPlaceHolder: 'AV_DVR_EMBED_PLAYER_URL_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'source', 'liveThumbnailUri' ],
      type: 'text',
      i18nLabel: 'AV_DVR_EMBED_THUMBNAIL_URL',
      i18nHelp: 'AV_DVR_EMBED_THUMBNAIL_URL_HELP',
      i18nPlaceHolder: 'AV_DVR_EMBED_PLAYER_URL_PLACEHOLDER',
      default: '',
      showOnMobile: true
    }
  ]
};

export { schema as default };
