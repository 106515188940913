const schema = {
  settings: {
    i18nTitle: 'AV_ONDEMAND_NEW',
    refreshOnCreate: true,
    showOnCreate: true,
    reloadUntilActive: false,
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'avOnDemand',
      reference: '$.metadata.title',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'metadata', 'title' ],
      type: 'text',
      i18nLabel: 'AV_ONDEMAND_TITLE',
      i18nHelp: 'AV_ONDEMAND_TITLE_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'description' ],
      type: 'text',
      i18nLabel: 'AV_ONDEMAND_DESCRIPTION',
      i18nHelp: 'AV_ONDEMAND_TITLE_HELP',
      i18nPlaceHolder: '',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'thumbnailKEY' ],
      type: 'fileUpload',
      i18nLabel: 'AV_ONDEMAND_THUMBNAIL',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: '',
      fileUploadSettings: {
        accept: 'image/*',
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true,
    },
    {
      name: [ 'payload', 'metadata', 'videoKEY' ],
      type: 'fileUpload',
      i18nLabel: 'AV_ONDEMAND_VIDEO',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: '',
      fileUploadSettings: {
        accept: 'video/*',
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true,
    },
    {
      name: 'payload.metadata.transcode',
      type: 'switch',
      i18nLabel: 'AV_ONDEMAND_TRANSCODE',
      i18nHelp: 'AV_ONDEMAND_TRANSCODE_HELP',
      i18nPlaceHolder: '',
      default: false,
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'transcodeStatus' ],
      type: 'hidden',
      i18nLabel: '',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: 'QUEUED'
    }

  ]
};

export { schema as default };
