import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiTextArea,
  EuiFormRow,
  EuiSpacer,
  EuiSelect,
  EuiDatePicker,
  EuiSwitch,
  EuiToast
} from '@elastic/eui';

import countryOtions from '../../../../../js/options/countries';

const mapStateToProps = state => {
  return { entryPorts: state.entryPorts };
};

function CreateTransitForm ( {
  entryPorts,
  scannedResults,
  submitForm,
  formFields,
  formErrors
} ) {
  const [ form, setForm ] = useState( { ...formFields } );
  const [ submitting, setSubmitting ] = useState( false );

  useEffect( () => {
    if ( scannedResults ) {
      const newForm = { ...form };
      newForm.given_name = scannedResults[ 0 ];
      newForm.family_name = scannedResults[ 1 ];
      newForm.father_name = scannedResults[ 2 ];
      newForm.mother_name = scannedResults[ 3 ];
      newForm.id_issue_city = scannedResults[ 4 ].split( ' ' )[ 0 ];
      newForm.nationality = 'SY';
      newForm.birth_date = moment(
        scannedResults[ 4 ].split( ' ' )[ 1 ],
        'DD-MM-YYYY'
      );
      newForm.id_type = 'nationalId';
      newForm.id_number = scannedResults[ 5 ];
      newForm.barcode = scannedResults;
      setForm( newForm );
    }
  }, [ scannedResults ] );

  useEffect( () => {
    setSubmitting( false );
  }, [ formErrors ] );

  const { t } = useTranslation();

  const entryPortOptions = entryPorts
    ? entryPorts.map( port => ( {
      value: port.id,
      text: t( port.payload.name )
    } ) )
    : [];

  const sexOptions = [
    { value: 'male', text: t( 'GENERIC_GENDER_MALE' ) },
    { value: 'female', text: t( 'GENERIC_GENDER_FEMALE' ) },
    { value: 'other', text: t( 'GENERIC_GENDER_OTHER' ) }
  ];

  const idTypeOptions = [
    { value: 'passport', text: t( 'Passport' ) },
    { value: 'nationalId', text: t( 'National ID' ) },
    { value: 'drivingLicense', text: t( 'Driving License' ) }
  ];

  const onChange = e => {
    const target = e.target;
    const value =
      target.type === 'checkbox' || target.type === 'button'
        ? Boolean( target.checked )
        : target.value;
    form[ target.dataset.id ] = value;
    setForm( { ...form } );
  };

  const handleDateChange = date => {
    form.birth_date = date;
    setForm( { ...form } );
  };

  const handleIdIssueDateChange = date => {
    form.id_issue_date = date;
    setForm( { ...form } );
  };

  const handleIdExpiryDateChange = date => {
    form.id_expiry_date = date;
    setForm( { ...form } );
  };

  const handleSubmit = () => {
    submitForm( { ...form } );
    setSubmitting( true );
  };

  return (
    <EuiForm>
      <EuiFormRow label={t( 'entry port' )} display='rowCompressed'>
        <EuiSelect
          hasNoInitialSelection
          data-id='entry_port'
          options={entryPortOptions}
          value={form.entry_port}
          onChange={onChange}
          aria-label='Entry Port'
          isLoading={!entryPortOptions.length}
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'ID Type' )} display='rowCompressed'>
        <EuiSelect
          hasNoInitialSelection
          data-id='id_type'
          options={idTypeOptions}
          value={form.id_type}
          onChange={onChange}
          aria-label='ID Type'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Nationality' )} display='rowCompressed'>
        <EuiSelect
          hasNoInitialSelection
          data-id='nationality'
          options={countryOtions}
          value={form.nationality}
          onChange={onChange}
          aria-label='Nationality'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Family Name' )} display='rowCompressed'>
        <EuiFieldText
          data-id='family_name'
          name='family_name'
          value={form.family_name}
          onChange={onChange}
          aria-label='Family Name'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'First (Given) Name' )} display='rowCompressed'>
        <EuiFieldText
          data-id='given_name'
          name='given_name'
          value={form.given_name}
          onChange={onChange}
          aria-label='First (Given) Name'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Father Name' )} display='rowCompressed'>
        <EuiFieldText
          data-id='father_name'
          name='father_name'
          value={form.father_name}
          onChange={onChange}
          aria-label='Father Name'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Mother Name' )} display='rowCompressed'>
        <EuiFieldText
          data-id='mother_name'
          name='mother_name'
          value={form.mother_name}
          onChange={onChange}
          aria-label='Mother Name'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Sex' )} display='rowCompressed'>
        <EuiSelect
          hasNoInitialSelection
          data-id='sex'
          options={sexOptions}
          value={form.sex}
          onChange={onChange}
          aria-label='Sex'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Birth Date' )} display='rowCompressed'>
        <EuiDatePicker
          selected={form.birth_date}
          onChange={handleDateChange}
          aria-label='Birth Date'
          className='euiFieldText--compressed'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'ID Number' )} display='rowCompressed'>
        <EuiFieldText
          data-id='id_number'
          name='id_number'
          value={form.id_number}
          onChange={onChange}
          aria-label='ID Number'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'ID Issue Date' )} display='rowCompressed'>
        <EuiDatePicker
          selected={form.id_issue_date}
          onChange={handleIdIssueDateChange}
          aria-label='ID Issue Date'
          className='euiFieldText--compressed'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'ID Expiry Date' )} display='rowCompressed'>
        <EuiDatePicker
          selected={form.id_expiry_date}
          onChange={handleIdExpiryDateChange}
          aria-label='ID Expiry Date'
          className='euiFieldText--compressed'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'ID Issue City' )} display='rowCompressed'>
        <EuiFieldText
          data-id='id_issue_city'
          name='id_issue_city'
          value={form.id_issue_city}
          onChange={onChange}
          aria-label='ID Issue City'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Telephone Number' )} display='rowCompressed'>
        <EuiFieldText
          data-id='telephone_number'
          name='telephone_number'
          value={form.telephone_number}
          onChange={onChange}
          aria-label='Telephone Number'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Entry Length' )} display='rowCompressed'>
        <EuiFieldText
          data-id='entry_length'
          name='entry_length'
          value={form.entry_length}
          onChange={onChange}
          aria-label='Entry Length'
          type='number'
          max={365}
          min={1}
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'GENERIC_EMAIL' )} display='rowCompressed'>
        <EuiFieldText
          data-id='email_address'
          name='email_address'
          value={form.email_address}
          onChange={onChange}
          aria-label='Email Address'
          compressed
        />
      </EuiFormRow>
      <EuiFormRow label={t( 'Comments' )} display='rowCompressed'>
        <EuiTextArea
          data-id='comments'
          name='comments'
          aria-label='Comments'
          value={form.comments}
          onChange={onChange}
          compressed
        />
      </EuiFormRow>

      <EuiSpacer />

      <EuiSwitch
        data-id='background_check'
        name='background_check'
        label={t( 'Background Check' )}
        checked={form.background_check}
        onChange={onChange}
      />
      <EuiSpacer />
      <EuiSwitch
        data-id='history_check'
        name='history_check'
        label={t( 'History Check' )}
        checked={form.history_check}
        onChange={onChange}
      />
      <EuiSpacer />
      <EuiSwitch
        data-id='auto_fees'
        name='auto_fees'
        label={t( 'LC_AUTO_FEES' )}
        checked={form.auto_fees}
        onChange={onChange}
      />
      <EuiSpacer />
      <EuiButton
        disabled={submitting}
        type='submit'
        fill
        onClick={handleSubmit}
      >
        {t( 'Save form' )}
      </EuiButton>
      <EuiSpacer />
      {formErrors && (
        <EuiToast
          title={t( 'ERROR_OCCURED' )}
          color='danger'
          iconType='alert'
        >
          <div>{formErrors.message}</div>
          {Object.keys( formErrors.attributes ).map( e => (
            <div style={{ marginTop: '10px' }}>
              <strong>{e}</strong>:{' '}
              {formErrors.attributes[ e ].map( msg => (
                <span>{msg} <br /></span>
              ) )}
            </div>
          ) )}
        </EuiToast>
      )}
    </EuiForm>
  );
}

export default connect( mapStateToProps )( CreateTransitForm );
