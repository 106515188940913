
import FaceRecognitionSvg from '../svg/faceRecognition.svg';
import VoiceRecognitionSvg from '../svg/voiceRecognition.svg';

const faveExtraAction = {
  color: 'subdued',
  iconType: 'starEmpty',
  iconSize: 's',
  'aria-label': 'Add to favorites'
};

const pinExtraAction = {
  color: 'subdued',
  iconType: 'pin',
  iconSize: 's'
};


const pinExtraActionFn = val => {
  pinExtraAction[ 'aria-label' ] = `Pin ${ val } to top`;
  return pinExtraAction;
};

export default ( addRedirect, userConsoleSettings = [], t ) => {
  const topLinks = [];

  const browserUrlService = new URL( window.location.href ).pathname.split( '/' )[ 1 ];

  if ( userConsoleSettings?.features?.includes( 'LC_NEW_DISABLED' ) ) {
    topLinks.push( {
      id: 'LC_NEW',
      featured: 1,
      label: t( 'LC_NEW_CARD' ),
      desc: t( 'LC_NEW_CARD_DESCRIPTION' ),
      href: '#',
      onClick: () => addRedirect( '/excs/landing/new/' ),
      iconType: 'canvasApp',
      isActive: false,
      /*
      extraAction: {
        ...pinExtraActionFn('Canvas'),
        alwaysShow: false
      }
      */
    } );
  }

  if ( userConsoleSettings?.features?.includes( 'LC_CHECK_DISABLED' ) ) {
    topLinks.push( {
      id: 'LC_CHECKIN',
      featured: 1,
      desc: t( 'LC_CHECKIN_DESCRIPTION' ),
      label: t( 'LC_CHECKIN' ),
      href: '#',
      onClick: () => addRedirect( '/excs/landing/check/' ),
      iconType: 'canvasApp',
      isActive: false
    } );
  }

  if ( userConsoleSettings?.features?.includes( 'LC_CHECK_DISABLEDD' ) ) {
    topLinks.push( {
      id: 'LC_LANDING_LIST',
      featured: 1,
      desc: t( 'LANDING_LIST_CARD_DESC' ),
      label: t( 'LANDING_LIST_NAV_LABEL' ),
      onClick: () => addRedirect( '/excs/landings/' ),
      iconType: 'reportingApp',
      isActive: false
    } );
  }

  if ( false ) {
    topLinks.push( {
      id: 'VR_PROFILES',
      featured: 1,
      desc: t( 'VRP_DESCRIPTION' ),
      label: t( 'VR_PROFILES' ),
      onClick: () => addRedirect( '/forensic/vr/profiles/' ),
      iconType: 'user',
      isActive: false
    } );

    topLinks.push( {
      id: 'FR_PROFILES',
      featured: 1,
      desc: t( 'FR_PROFILES_DESCRIPTION' ),
      label: t( 'FR_PROFILES' ),
      onClick: () => addRedirect( '/forensic/fr/profiles/' ),
      iconType: 'user',
      isActive: false
    } );
  }


  if ( userConsoleSettings?.enabledFeatures?.includes( 'forensic' ) && browserUrlService == '' ) {

    topLinks.push( {
      id: 'FORENSIC',
      featured: 1,
      desc: t( 'FORENSIC_DESCRIPTION' ),
      label: t( 'FORENSIC' ),
      onClick: () => addRedirect( '/forensic/' ),
      iconType: FaceRecognitionSvg,
      isActive: false
    } );
  }

  if ( userConsoleSettings?.enabledFeatures?.includes( 'cwsm' ) && browserUrlService == '' ) {

    topLinks.push( {
      id: 'CWSM',
      featured: 1,
      desc: t( 'CW_SM_DESCRIPTION' ),
      label: t( 'CWSM' ),
      onClick: () => addRedirect( '/cwsm/' ),
      iconType: 'online',
      isActive: false
    } );

  }

  if ( userConsoleSettings?.enabledFeatures?.includes( 'av' ) && browserUrlService == '' ) {

    topLinks.push( {
      id: 'AV',
      featured: 1,
      desc: t( 'AV_DESCRIPTION' ),
      label: t( 'AV' ),
      onClick: () => addRedirect( '/av/' ),
      iconType: 'videoPlayer',
      isActive: false
    } );

  }


  if ( browserUrlService == 'forensic' ) {


    //Todo: Change enabled feature to forensic/profiles
    if ( userConsoleSettings?.enabledFeatures?.includes( 'forensic/vr' ) ) {
      topLinks.push( {
        id: 'FORENSIC_PROFILES',
        featured: 1,
        desc: t( 'FORENSIC_PROFILES_DESCRIPTION' ),
        label: t( 'FORENSIC_PROFILES' ),
        onClick: () => addRedirect( '/forensic/profiles/' ),
        iconType: 'visMetric',
        isActive: false
      } );
    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'forensic/vr' ) ) {
      topLinks.push( {
        id: 'VR_IDENTIFICATION',
        featured: 1,
        desc: t( 'VRI_DESCRIPTION' ),
        label: t( 'VR_IDENTIFICATIONS' ),
        onClick: () => addRedirect( '/forensic/vr/identifications/' ),
        iconType: VoiceRecognitionSvg,
        isActive: false
      } );
    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'forensic/fr' ) ) {

      topLinks.push( {
        id: 'FR_IDENTIFICATIONS',
        featured: 1,
        desc: t( 'FR_IDENTIFICATION_DESCRIPTION' ),
        label: t( 'FR_IDENTIFICATION' ),
        onClick: () => addRedirect( '/forensic/fr/identifications/' ),
        iconType: FaceRecognitionSvg,
        isActive: false
      } );

    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'forensic/image/recognitions' ) ) {

      topLinks.push( {
        id: 'IMAGE_RECOGNITION',
        featured: 1,
        desc: t( 'IR_DESCRIPTION' ),
        label: t( 'IMAGE_RECOGNITION' ),
        onClick: () => addRedirect( '/forensic/image/recognitions/' ),
        iconType: 'image',
        isActive: false
      } );
    }
    //Replace later with F_IE
    if ( userConsoleSettings?.enabledFeatures?.includes( 'forensic/image/enhancement' ) ) {
      topLinks.push( {
        id: 'IMAGE_ENHANCER',
        featured: 1,
        desc: t( 'IE_DESCRIPTION' ),
        label: t( 'IMAGE_ENHANCER' ),
        onClick: () => addRedirect( '/forensic/image/enhancement/' ),
        iconType: 'inspect',
        isActive: false
      } );
    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'forensic/leaks/search' ) ) {
      topLinks.push( {
        id: 'LEAKS_SEARCH',
        featured: 1,
        desc: t( 'LS_DESCRIPTION' ),
        label: t( 'LEAKS_SEARCH' ),
        onClick: () => addRedirect( '/forensic/leaks/search/' ),
        iconType: 'search',
        isActive: false
      } );
    }

    /**
    topLinks.push( {
      id: 'SOCIALWATCH',
      featured: 1,
      desc: t( 'SW_DESCRIPTION' ),
      label: t( 'SOCIALWATCH' ),
      onClick: () => addRedirect( '/monitoring/socialwatch/' ),
      iconType: 'stats',
      isActive: false
    } );


    topLinks.push( {
      id: 'VIDEO_SEARCH',
      featured: 1,
      desc: t( 'VS_DESCRIPTION' ),
      label: t( 'VIDEOSEARCH' ),
      onClick: () => addRedirect( '/monitoring/videos/' ),
      iconType: 'videoPlayer',
      isActive: false
    } );

    topLinks.push( {
      id: 'NEWS_ARTICLES',
      featured: 1,
      desc: t( 'NS_DESCRIPTION' ),
      label: t( 'NEWS_ARTICLES' ),
      onClick: () => addRedirect( '/monitoring/articles/' ),
      iconType: 'glasses',
      isActive: false
    } );
     */
  }


  if ( browserUrlService == 'cwsm' ) {

    if ( userConsoleSettings?.enabledFeatures?.includes( 'cwsm/accounts' ) ) {
      topLinks.push( {
        id: 'CWSM_ACCOUNTS',
        featured: 1,
        desc: t( 'CWSM_ACCOUNTS_DESCRIPTION' ),
        label: t( 'CWSM_ACCOUNTS' ),
        onClick: () => addRedirect( '/cwsm/accounts/' ),
        iconType: 'users',
        isActive: false
      } );
    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'cwsm/actions' ) ) {
      topLinks.push( {
        id: 'CWSM_ACTIONS',
        featured: 1,
        desc: t( 'CWSM_ACTIONS_DESCRIPTION' ),
        label: t( 'CWSM_ACTIONS' ),
        onClick: () => addRedirect( '/cwsm/actions/' ),
        iconType: 'cheer',
        isActive: false
      } );
    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'cwsm/messages' ) ) {
      topLinks.push( {
        id: 'CWSM_MESSAGES',
        featured: 1,
        desc: t( 'CWSM_MESSAGES_DESCRIPTION' ),
        label: t( 'CWSM_MESSAGES' ),
        onClick: () => addRedirect( '/cwsm/messages/' ),
        iconType: 'database',
        isActive: false
      } );
    }
    if ( userConsoleSettings?.enabledFeatures?.includes( 'cwsm/mentions' ) ) {
      topLinks.push( {
        id: 'CWSM_MENTIONS',
        featured: 1,
        desc: t( 'CWSM_MENTIONS_DESCRIPTION' ),
        label: t( 'CWSM_MENTIONS' ),
        onClick: () => addRedirect( '/cwsm/mentions/' ),
        iconType: 'bell',
        isActive: false
      } );
    }
  }

  if ( browserUrlService == 'av' ) {

    if ( userConsoleSettings?.enabledFeatures?.includes( 'av/dvr' ) ) {
      topLinks.push( {
        id: 'AV_DVR',
        featured: 1,
        desc: t( 'AV_DVR_DESCRIPTION' ),
        label: t( 'AV_DVR' ),
        onClick: () => addRedirect( '/av/dvr/' ),
        iconType: 'storage',
        isActive: false
      } );
    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'av/dvr/manager' ) ) {
      topLinks.push( {
        id: 'AV_DVR_MANAGER',
        featured: 1,
        desc: t( 'AV_DVR_MANAGER_DESCRIPTION' ),
        label: t( 'AV_DVR_MANAGER' ),
        onClick: () => addRedirect( '/av/dvr/manager/' ),
        iconType: 'database',
        isActive: false
      } );
    }

    if ( userConsoleSettings?.enabledFeatures?.includes( 'av/distribution' ) ) {
      topLinks.push( {
        id: 'AV_DISTRIBUTION',
        featured: 1,
        desc: t( 'AV_DISTRIBUTION_DESCRIPTION' ),
        label: t( 'AV_DISTRIBUTION' ),
        onClick: () => addRedirect( '/av/distribution/' ),
        iconType: 'wrench',
        isActive: false
      } );
    }
    if ( userConsoleSettings?.enabledFeatures?.includes( 'av/ondemand' ) ) {
      topLinks.push( {
        id: 'AV_ONDEMAND',
        featured: 1,
        desc: t( 'AV_ONDEMAND_DESCRIPTION' ),
        label: t( 'AV_ONDEMAND' ),
        onClick: () => addRedirect( '/av/ondemand/' ),
        iconType: 'videoPlayer',
        isActive: false
      } );
    }
  }

  if ( userConsoleSettings?.features?.includes( 'E_EXPLORER' ) ) {

    topLinks.push( {
      id: 'EXPLORER',
      featured: 1,
      desc: t( 'EXPLORER' ),
      label: t( 'EXPLORER' ),
      onClick: () => addRedirect( '/finder/explorer/' ),
      iconType: 'beaker',
      isActive: false
    } );

    topLinks.push( {
      id: 'EXPLORER',
      featured: 1,
      desc: t( 'FINDER_ENTITIES_DESCRIPTION' ),
      label: t( 'FINDER_ENTITIES' ),
      onClick: () => addRedirect( '/finder/entities/' ),
      iconType: 'beaker',
      isActive: false
    } );
  }



  return topLinks;
};
