import React, { Component, Fragment } from 'react';
import { EuiEmptyPrompt, EuiButton } from '@elastic/eui';
import { withRouter } from 'react-router';

class ErrorBoundary extends Component {
  constructor ( props ) {
    super( props );
    this.state = { hasError: false };
  }

  static getDerivedStateFromError ( error ) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render () {
    const { history } = this.props;

    if ( this.state.hasError ) {
      // You can render any custom fallback UI
      return (
        <EuiEmptyPrompt
          iconType='editorStrike'
          title={<h2>Something went wrong.</h2>}
          body={
            <Fragment>
              <p>We apologize, the app has crashed</p>
              <p>
                Please retry your request and contact support if error continue.
              </p>
            </Fragment>
          }
          actions={
            <Fragment>
              <EuiButton style={{ marginRight: '10px' }} color='parent' fill onClick={() => history.goBack()}>
                back
              </EuiButton>
              <EuiButton color='parent' fill onClick={() => window.location.reload}>
                Retry
              </EuiButton>
            </Fragment>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default withRouter( ErrorBoundary );
