const schema = {
  settings: {
    updateQueryParameter: true,
    parseQueryParameter: true
  },
  fields: [
    {
      name: 'payload.entity',
      type: 'search',
      i18nLabel: 'GENERIC_SEARCH',
      i18nHelp: 'GENERIC_SEARCH_HELP',
      i18nPlaceHolder: 'SW_SEARCH_PLACEHOLDER',
      default: '',
      operator: 'multiBf*',
      showOnMobile: true,
      validatio: {
        presence: true
      }
    },
    {
      name: 'payload.server',
      type: 'select',
      i18nLabel: 'SW_SERVER',
      i18nHelp: 'SW_SERVER_HELP',
      i18nPlaceHolder: 'SW_SERVER_HELP_HOLDER',
      operator: '==',
      default: 'botslayer-guc1a-3',
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'GCC',
            value: 'botslayer-guc1a-4',
          },
          {
            i18nTitle: 'IRAQ',
            value: 'botslayer-guc1a-3'
          }
        ]
      },
      showOnMobile: true
    },
    {
      name: 'payload.type',
      type: 'comboBox',
      i18nLabel: 'SW_ENTITIES_TYPE',
      i18nHelp: 'SW_ENTITIES_TYPE_HELP',
      i18nPlaceHolder: 'SW_ENTITIES_TYPE_HOLDER',
      default: [
        { label: 'Hashtags', value: 'hashtags', disabled: false },
        { label: 'Links', value: 'urls', disabled: false } ],
      operator: 'inv2',
      listSettings: {
        allowExclusions: true,
        class: 'noFlexWrap',
        options: [
          {
            i18nTitle: 'HASHTAGS',
            value: 'hashtags',
            default: true
          },
          {
            i18nTitle: 'TEXT',
            value: 'intext'
          },
          {
            i18nTitle: 'MENTIONS',
            value: 'user_mentions',
            default: true
          },
          {
            i18nTitle: 'URLS',
            value: 'urls',
            default: true
          }
        ]
      },
      showOnMobile: true
    },
    {
      name: 'payload.lastSeen',
      type: 'superDate',
      i18nLabel: 'SW_LAST_SEEN',
      i18nHelp: 'SW_LAST_SEEN_HELP',
      i18nPlaceHolder: 'SW_LAST_SEEN_HOLDER',
      default: 'now-7h>-<now',
      operator: 'between',
      showOnMobile: true
    }
  ]
};

export { schema as default };
