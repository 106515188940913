import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiTitle,
  EuiSpacer,
  EuiLoadingSpinner,
  EuiEmptyPrompt
} from '@elastic/eui';
import moment from 'moment';
import API from '../../../../../js/api';
import { useError } from '../../../../Error';

const HistoryCheck = ( { hc } ) => {
  const [ historyCheckData, setHistoryCheckData ] = useState( null );

  const { t } = useTranslation();
  const addError = useError();

  useEffect( () => {
    ( async () => {
      try {
        const res = await API.query( 'object', hc.query );
        setHistoryCheckData( res.data );
      } catch ( er ) {
        console.log( 'asdasdasd', er );
        addError( {
          title: er.code,
          text: er.message,
          color: 'danger',
          iconType: 'crossInACircleFilled'
        } );
      }
    } )();
  }, [] );

  const Title = () => (
    <EuiTitle size='s'>
      <h4>{t( 'History Check' )}</h4>
    </EuiTitle>
  );

  const noHistory = () => {
    if ( historyCheckData === null ) return <EuiLoadingSpinner />;

    return (
      <Fragment>
        <EuiEmptyPrompt
          iconType='faceHappy'
          title={<h2>{t( 'No Historical Entries' )}</h2>}
          body={
            <Fragment>
              <p>{t( 'LC_NO_PREVIOUS_ENTRY' )}.</p>
            </Fragment>
          }
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Title />
      <EuiSpacer size='m' />

      {historyCheckData && historyCheckData.length ? (
        <EuiListGroup flush={true} bordered={true}>
          {historyCheckData.map( d => (
            <EuiListGroupItem
              label={`${ t( 'ENTRY_ON' ) } ${ moment( d.createdAt ).format(
                'MMMM Do YYYY, h:mm:ss a'
              ) } ${ t( 'FROM' ) } ${ d.payload.country }`}
              key={d.id}
            />
          ) )}
        </EuiListGroup>
      ) : (
          noHistory()
        )}
      <EuiSpacer size='xl' />
    </Fragment>
  );
};

export default HistoryCheck;
