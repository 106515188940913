export default {
  settings: {
    textStyle: 'reverse',

    title: '_reference',
    i18nTitleSuffix: 'DETAILS',
    actions: [
    ]
  },
  fields: [
    {
      field: 'id',

      data: 'id',
      i18nTitle: 'B3_OBJECT_ID',
      sortable: true,
      truncateText: true,
      width: '10%',
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'enrollment_status',

      data: 'payload.metadata.processingStatus',
      i18nTitle: 'ENROLLMENT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    },
    {
      field: 'variation_count',

      data: 'payload.faces',
      i18nTitle: 'VARIATION_COUNT',
      sortable: true,
      type: 'arrayCount',
      showOnMobile: true
    },
    {
      field: 'listFaces',

      data: 'payload',
      i18nTitle: 'FACES_DETAILS',
      sortable: 'false',
      type: 'jsonViewer',
      jsonViewerSettings: {
        collapsed: false
      },
      showOnMobile: true
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'GENERIC_CREATED_AT',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    },
    {
      field: 'record_status',

      data: 'status',
      i18nTitle: 'B3_OBJECT_STATUS',
      sortable: true,
      type: 'status',
      showOnMobile: true
    }
  ]
};
