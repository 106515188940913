import React, { useState, useEffect, Fragment } from 'react';
import { EuiSpacer, EuiFormRow, EuiSelect, EuiTitle } from '@elastic/eui';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import API from '../../../../../js/api';
import {useError} from '../../../../Error';

const mapStateToProps = state => ({
  entryPorts: state.entryPorts
});

const AutoFees = ({ af, setFeesSchema, feesSchema }) => {
  const [autoFeesData, setAutoFeesData] = useState(null);
  const { t } = useTranslation();
  const addError = useError();

  useEffect(() => {
    if (!af || af.enabled === 'false') return;
    (async () => {
      try {
        const res = await API.query('object', af.query);
        setAutoFeesData(res.data);
      } catch (er) {
        console.log(er);
        addError({
          title: er.code,
          text: er.message,
          color: 'danger',
          iconType: 'crossInACircleFilled'
        })
      }
    })();
  }, []);

  const Title = () => (
    <EuiTitle size='s'>
      <h4>{t('LC_AUTO_FEES')}</h4>
    </EuiTitle>
  );

  const entryPortOptions = autoFeesData
    ? autoFeesData.map(port => ({
        value: port.id,
        text: t(port.payload.name.toUpperCase().replace(/\s|:/g, '_'))
      }))
    : [];

  if (af.enabled !== 'false') {
    // const BClistItems = AutoFeesPayload
    return (
      <Fragment>
        <Title />
        <EuiSpacer size='m' />
        <EuiFormRow label={t('Fees Scheme')} display='rowCompressed'>
          <EuiSelect
            hasNoInitialSelection
            data-id='fees_scheme'
            options={entryPortOptions}
            value={feesSchema}
            onChange={e => setFeesSchema(e.target.value)}
            aria-label='Fees Scheme'
            compressed
          />
        </EuiFormRow>
      </Fragment>
    );
  } else {
    return <span />;
  }
};

export default connect(mapStateToProps)(AutoFees);
