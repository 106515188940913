import React, { useState, useEffect } from 'react';
import PageLayout from '../../../ui/PageLayout';
import { IListObjects, FilterBuilder } from '../../../jsFnI';

import {
  setBreadcrumbs,
} from '../../../../js/actions/index';

import { useTranslation } from 'react-i18next';

import listSchema from '../../../../repository/schemas/monitoring/videos/list';
import filterSchema from '../../../../repository/schemas/monitoring/videos/filter';
import describeSchema from '../../../../repository/schemas/monitoring/videos/describe';

import { useError } from '../../../Error';
import store from '../../../../jas/store';


const Page = () => {
  console.log( 'page load' );
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( false );
  const [ forceReload, setForceReload ] = useState( false );
  const [ filters, setFilters ] = useState( {} );
  const addError = useError();

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'MONITORING', href: '/' },
        { i18nText: 'VIDEOSEARCH', href: '/' } ] ) );
  }, [] );


  const filterUpdateHandler = ( v ) => {
    setFilters( v );
  };

  const filterBuilder = <FilterBuilder schema={filterSchema} onUpdate={filterUpdateHandler} />;


  return (
    <PageLayout header={filterBuilder}>
      <IListObjects
        query={listSchema.query}
        filters={filters}
        listSchema={listSchema}
        describeSchema={describeSchema}
        describeSize='m'
        forceReload={forceReload}
      />
    </PageLayout>
  );
};

export { Page as default };
