import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import SignIn from './pages/SignIn';
import borderTransit from './pages/border/transit/Index';
import borderCheckIn from './pages/border/CheckIn';
import borderLandingList from './pages/border/LandingList';
import Dashboard from './pages/DelDashboard';
import Home from './pages/Home';
import ForensicDashboard from './pages/forensic/dashboard';
import ForensicProfiles from './pages/forensic/profiles/profiles';
import ForensicProfilesDesribe from './pages/forensic/profiles/describe';

import vrProfiles from './pages/forensic/voiceRecognition/profiles';
import vrIdentifications from './pages/forensic/voiceRecognition/identifications';
import imageRecognition from './pages/forensic/imageRecognition/imageRecognition';
import imageEnhancement from './pages/forensic/imageEnhancement/imageEnhancement';
import frProfiles from './pages/forensic/faceRecognition/profiles';
import frIdentifications from './pages/forensic/faceRecognition/identifications';
import leaksSearch from './pages/forensic/leaks/search';
import monitoringSocialWatch from './pages/monitoring/socialwatch';
import monitoringVideoSearch from './pages/monitoring/videos';
import monitoringNewsArticles from './pages/monitoring/articles';
import CwSmDashboard from './pages/cw/sm/dashboard';
import cwSmAccounts from './pages/cw/sm/accounts';
import cwSmMessages from './pages/cw/sm/messages';
import cwSmActions from './pages/cw/sm/actions';
import cwSmMentions from './pages/cw/sm/mentions';
import avDashboard from './pages/av/dashboard';
import avLiveDistribution from './pages/av/distribution';
import avOnDemand from './pages/av/onDemand';
import avDvr from './pages/av/dvr';
import avDvrManager from './pages/av/dvrManager';


import finderExplorer from './pages/explorer';
import entitiesExplorer from './pages/explorer/entities';


import { E404 } from './pages/E404';


import PrivateRoute from './PrivateRoute';


const Router = () => (
  <Switch>

    <Route path='/signin' component={SignIn} exact />
    <PrivateRoute>
      <Route path='/' component={Home} exact />
      <Route path='/excs/landing/new/' component={borderTransit} exact />
      <Route path='/excs/landing/check/' component={borderCheckIn} exact />
      <Route path='/excs/landings/' component={borderLandingList} exact />
      <Route path='/forensic/' component={ForensicDashboard} exact />
      <Route path='/forensic/profiles/' component={ForensicProfiles} strict={false} />
      <Route path='/forensic/profiles/:objectId' component={ForensicProfilesDesribe} strict={false} />
      <Route path='/forensic/vr/profiles/' component={vrProfiles} strict={false} />
      <Route path='/forensic/vr/identifications/' component={vrIdentifications} strict={false} />
      <Route path='/forensic/image/recognitions/' component={imageRecognition} strict={false} />
      <Route path='/forensic/image/enhancement/' component={imageEnhancement} strict={false} />
      <Route path='/forensic/fr/profiles/' component={frProfiles} strict={false} />
      <Route path='/forensic/fr/identifications/' component={frIdentifications} strict={false} />
      <Route path='/forensic/leaks/search/' component={leaksSearch} strict={false} />
      <Route path='/monitoring/' component={Dashboard} exact />
      <Route path='/monitoring/socialwatch/' component={monitoringSocialWatch} strict={false} />
      <Route path='/monitoring/videos/' component={monitoringVideoSearch} strict={false} />
      <Route path='/monitoring/articles/' component={monitoringNewsArticles} strict={false} />
      <Route path='/finder/explorer/' component={finderExplorer} strict={false} />
      <Route path='/finder/entities/' component={entitiesExplorer} strict={false} />
      <Route path='/cwsm/' component={CwSmDashboard} exact />
      <Route path='/cwsm/accounts/' component={cwSmAccounts} strict={false} />
      <Route path='/cwsm/messages/' component={cwSmMessages} strict={false} />
      <Route path='/cwsm/actions/' component={cwSmActions} strict={false} />
      <Route path='/cwsm/mentions/' component={cwSmMentions} strict={false} />
      <Route path='/av/' component={avDashboard} exact />
      <Route path='/av/distribution/' component={avLiveDistribution} strict={false} />
      <Route path='/av/ondemand/' component={avOnDemand} strict={false} />
      <Route path='/av/dvr/' component={avDvr} exact />
      <Route path='/av/dvr/manager' component={avDvrManager} strict={false} />
    </PrivateRoute>
    <Route component={E404} />

  </Switch>
);

export default Router;
