import React, { useState, Fragment } from 'react';
import {
  EuiFilePicker
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

import { default as B3 } from '../../../../js/api';
import { useError } from '../../../Error';
import { getConsoleProjects } from '../../../../jas/state/consoleOptions';


const IFileUpload = ( { accept, multiple, isInvalid, returnB3Object, onChange, lockParent } ) => {

  const [ loading, setLoading ] = useState( false );
  const [ locked, setLocked ] = useState( false );
  const [ invalid, setInvalid ] = useState( false );


  const { t } = useTranslation();

  const addError = useError();

  const handleChange = async ( files ) => {
    if ( files.length > 0 ) {
      if ( returnB3Object ) {
        try {
          setLoading( true );
          setLocked( true );
          if ( lockParent ) {
            lockParent( { loading: true, locked: true, i18nText: 'UPLOADING' } );
          }
          let upload = await B3.uploadAttachmentV3( files[ 0 ], { project_id: getConsoleProjects().selected, } );
          setLoading( false );
          if ( lockParent ) {
            lockParent( {} );
          }
          switch ( returnB3Object.response ) {
            case 'idOnly':
              upload = upload.data.id;
              break;
            default:
              upload = upload.data;
          }
          onChange( { target: { value: upload } } );

        }
        catch ( err ) {
          //console.error('IFileUpload Error', err);
          setLoading( false );
          addError( {
            title: t( 'GENERIC_ERROR_UNABLE_TO_UPLOAD' ),
            text: t( err?.message || 'ERROR_NO_MESSAGE' ),
            color: 'danger',
            iconType: 'crossInACircleFilled'
          } );
        }
      } else {
        if ( lockParent ) { lockParent( {} ); }
        setLoading( false );
        onChange( files );
      }
    } else {
      // In case the files were removed to be add another
      if ( lockParent ) { lockParent( {} ); }
      setLoading( false );
      setLocked( false );
      if ( returnB3Object ) {
        onChange( { target: { value: null } } );
      } else {
        onChange();
      }
    }
  };
  return (
    <Fragment>
      <EuiFilePicker
        id='upload'
        onChange={files => handleChange( files )}
        display='large'
        accept={accept}
        multiple={multiple || false}
        isLoading={loading}
        disabled={locked}
        invalid={setInvalid}
      />
    </Fragment>
  );
};

export { IFileUpload as default, IFileUpload };
