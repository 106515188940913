import React, { useState, useEffect, Fragment } from 'react';
import {
  EuiSuperDatePicker,
  EuiSpacer,
  EuiFormControlLayoutDelimited,
  EuiFormLabel,
  EuiPanel,
  EuiText
} from '@elastic/eui';
import dateMath from '@elastic/datemath';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import _ from 'lodash';


const commonlyUsedRanges = [
  { start: 'now/d', end: 'now', label: 'Today' },
  { start: 'now/w', end: 'now', label: 'This week' },
  { start: 'now/M', end: 'now', label: 'This month' },
  { start: 'now/y', end: 'now', label: 'This year' },
  { start: 'now-2d/d', end: 'now-1d/d', label: 'Yesterday' },
  { start: 'now/w', end: 'now', label: 'Week to date' },
  { start: 'now/M', end: 'now', label: 'Month to date' },
  { start: 'now/y', end: 'now', label: 'Year to date' } ];

const IFormFieldSuperDate = ( { config, defaultValue, isInvalid, onChange, isLoading } ) => {

  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ loading, setLoading ] = useState( isLoading || false );

  // console.log( 'IFormFieldSuperDate defaultValue', defaultValue );

  useEffect( () => {
    if ( _.isString( defaultValue ) ) {
      defaultValue = defaultValue.split( '>-<' );
      let tmpStartDate = dateMath.parse( defaultValue[ 0 ] ).toISOString();
      let tmpEndDate = dateMath.parse( defaultValue[ 1 ] ).toISOString();

      console.log( 'IFormFieldSuperDate Time', defaultValue );
      console.log( 'IFormFieldSuperDate Time 2', dateMath.parse( defaultValue[ 0 ] ) );
      setStartDate( tmpStartDate );
      setEndDate( tmpEndDate );
      onChange( { target: { value: `${ tmpStartDate }>-<${ tmpEndDate }` } } );
      console.log( 'IFormFieldSuperDate Time Update', `${ tmpStartDate }>-<${ tmpEndDate }` );
    }
  }, defaultValue );

  const { t } = useTranslation();

  const handleChange = ( { start, end, isQuickSelection, isInvalid } ) => {

    //  console.log( 'IFormFieldSuperDate start', start, 'end', end );
    //   console.log( 'IFormFieldSuperDate start', dateMath.parse( start ), 'end', dateMath.parse( end ) );

    /*     if ( v.isInvalidd ) {
          if ( isInvalid ) {
            isInvalid( true );
          }
        } else {
        }  */

    setStartDate( moment( dateMath.parse( start ) ).toISOString() );
    setEndDate( moment( dateMath.parse( end ) ).toISOString() );

    onChange( { target: { value: `${ moment( dateMath.parse( start ) ).toISOString() }>-<${ moment( dateMath.parse( end ) ).toISOString() }` } } );
    //setLoading( true );

  };

  return (
    <Fragment>
      <EuiSuperDatePicker
        isLoading={loading}
        start={startDate || 'now-15m'}
        end={endDate || 'now'}
        showUpdateButton={true}
        onTimeChange={handleChange}
        commonlyUsedRanges={commonlyUsedRanges}
      // onRefresh={this.onRefresh}
      // isPaused={this.state.isPaused}
      // refreshInterval={this.state.refreshInterval}
      // onRefreshChange={this.onRefreshChange}
      // recentlyUsedRanges={this.state.recentlyUsedRanges}
      />
    </Fragment>
  );
};

export { IFormFieldSuperDate as default, IFormFieldSuperDate };
