import React, { useState, useEffect } from 'react';
import
{
  EuiRange
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';

const IFormFieldRange = ( { config, defaultValue, onChange } ) =>
{

  const [ value, setValue ] = useState( defaultValue );

  const { t } = useTranslation();


  const handleChange = ( v ) =>
  {
    setValue( v.target.value );
    onChange( { target: { value: v.target.value } } );
  };

  return (
    <EuiRange
      min={config.min}
      max={config.max}
      value={value}
      onChange={( v ) => handleChange( v )}
      showLabels={config.showLabel}
      showValue={config.showValue}
      showTicks={config.showTicks}
    />
  );
};

export { IFormFieldRange as default, IFormFieldRange };
