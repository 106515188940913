const schema = {
  settings: {
    i18nTitle: 'IR_NEW',
    refreshOnCreate: true,
    showOnCreate: true,
    reloadUntilActive: {
      name: [ 'payload', 'metadata', 'recognitionStatus' ],
      values: [ 'COMPLETED' ],
      holdTime: 30000, // in ms
      timeOut: 300000 // in ms
    },
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'imageRecognition',
      reference: '$.metadata.reference',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'metadata', 'reference' ],
      type: 'text',
      i18nLabel: 'GENERIC_REFERENCE',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'GENERIC_REFERENCE_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'sourceAttachmentId' ],
      type: 'fileUpload',
      i18nLabel: 'GENERIC_IMAGE_FILE',
      i18nHelp: 'GENERIC_IMAGE_FILE_HELP',
      i18nPlaceHolder: 'GENERIC_IMAGE_FILE_PLACEHOLDER',
      default: '',
      fileUploadSettings: {
        accept: 'image/*',
        multiple: false,
        returnB3Object: {
          response: 'idOnly'
        }
      },
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'advancedProcessing' ],
      type: 'switch',
      i18nLabel: 'IR_ADVANCED_LABEL',
      i18nHelp: 'IR_ADVANCED_HELP',
      i18nPlaceHolder: 'GENERIC_ENABLE',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'crossProcess' ],
      type: 'switch',
      i18nLabel: 'IR_CROSS_PROCESS',
      i18nHelp: 'IR_CROSS_PROCESS_HELP',
      i18nPlaceHolder: 'GENERIC_ENABLE',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'recognitionStatus' ],
      type: 'hidden',
      i18nLabel: '',
      i18nHelp: '',
      i18nPlaceHolder: '',
      default: 'QUEUED'
    }
  ]
};

export { schema as default };
