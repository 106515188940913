import React, { useEffect, Fragment } from 'react';
import PageLayout from '../ui/PageLayout';
import {

} from '@elastic/eui';

import {
  EuiButton, EuiEmptyPrompt
} from '@elastic/eui';
import {
  setBreadcrumbs
} from '../../js/actions/index';
import store from '../../jas/store';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

const E404 = () => {
  const { t } = useTranslation();

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'FORENSIC', href: '/forensic/' },
        { i18nText: '404', href: '/' } ] ) );
  }, [] );


  return (
    <PageLayout >
      <EuiEmptyPrompt
        iconType="editorStrike"
        title={<h2>{t( 'ERROR_404' )}</h2>}
        body={
          <Fragment>
            <p>
              {t( 'ERROR_404_DESCRIPTION' )}
            </p>
          </Fragment>
        }
        actions={
          <EuiButton color="primary" fill href={'/'}>
            {t( 'ERROR_404_BUTTON_HOME' )}
          </EuiButton>
        }
      />
    </PageLayout>
  );
};

export { E404 as default, E404 };
