import React, { useState, useEffect } from 'react';
import PageLayout from '../../../ui/PageLayout';
import { IListObjects, FormBuilder } from '../../../jsFnI';
import {
  EuiButton,
} from '@elastic/eui';

import {
  useRedirect,
} from '../../../../jas/state';

import {
  setBreadcrumbs,
} from '../../../../js/actions/index';

import { useTranslation } from 'react-i18next';


import listSchema from '../../../../repository/schemas/forensic/leaksSearch/list';
import createSchema from '../../../../repository/schemas/forensic/leaksSearch/create';
import describeSchema from '../../../../repository/schemas/forensic/leaksSearch/describe';


import { useError } from '../../../Error';
import store from '../../../../jas/store';


const Page = () => {
  console.log( 'page load' );
  const { t } = useTranslation();
  const [ showAddPopup, setShowAddPopup ] = useState( false );
  const [ forceReload, setForceReload ] = useState( false );
  const addError = useError();

  useEffect( () => {
    store.dispatch(
      setBreadcrumbs( [
        { i18nText: 'FORENSIC', href: '/forensic/' },
        { i18nText: 'LEAKS', href: '/' },
        { i18nText: 'SEARCH', href: '/' }
      ] ) );
  }, [] );



  let flyout = <></>;

  if ( showAddPopup == true ) {
    flyout = <FormBuilder
      schema={createSchema}

      reload={() => setForceReload( true )}
      close={() => setShowAddPopup( false )} />;
  }


  const createButton = ( <EuiButton iconType='listAdd'
    size='s' onClick={() => { setShowAddPopup( true ); }}>
    {t( 'NEW_SEARCH' )}
  </EuiButton> );


  return (
    <PageLayout title={t( 'LEAKS_SEARCH' )} headerRight={createButton}>
      {flyout}
      <IListObjects
        query={listSchema.query}
        listSchema={listSchema}
        describeSchema={describeSchema}
        describeSize='l'
        forceReload={forceReload}
      />
    </PageLayout>
  );
};

export { Page as default };
