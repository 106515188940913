import React, { useState, useEffect, Fragment } from 'react';
import { EuiButton, EuiSpacer, EuiButtonIcon } from '@elastic/eui';
import { BarcodePicker, CameraAccess, ScanSettings, Barcode, CameraSettings } from 'scandit-sdk';
import { SCANDIT_SDK_LICENSE } from '../../../js/config';
import ScanditBarcodeScanner from 'scandit-sdk-react';

const PdfScanner = ({ onScan, openCamera, resultsDecoder, setOpenCamera }) => {
  const [scannerReady, setScannerReady] = useState(false);
  const canAccessCamera = true;
  // const [canAccessCamera, setCanAccessCamera] = useState(true);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       await CameraAccess.getCameras();
  //       setCanAccessCamera(true);
  //       console.log(canAccessCamera);
  //     } catch (err) {
  //       console.log('Camera is disabled: ', err.message);
  //       setCanAccessCamera(true);
  //     }
  //   })();
  // });

  const getScanSettings = () => {
    return new ScanSettings({
      enabledSymbologies: [Barcode.Symbology.PDF417, Barcode.Symbology.QR],

    });
  };

  return (
    <Fragment>
      {canAccessCamera && setOpenCamera && (
        <EuiButton
          onClick={() => setOpenCamera(true)}
          isLoading={!scannerReady && openCamera}
          size='s'
        >
          Scan
        </EuiButton>
      )}
      <EuiSpacer />
      <div className={openCamera ? 'fullscreen-camera' : 'hidden-camera'}>
        {setOpenCamera && <EuiButtonIcon
          onClick={() => setOpenCamera(false)}
          iconType='cross'
          aria-label='Close'
          style={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            zIndex: 1000
          }}
        />}
        {canAccessCamera && openCamera && (
          <ScanditBarcodeScanner
            licenseKey={SCANDIT_SDK_LICENSE}
            engineLocation='https://unpkg.com/browse/scandit-sdk@4.6.2/build/'
            onReady={() => setScannerReady(true)}
            onScan={res => {
              console.log(res)
              const resultArray = resultsDecoder
                ? resultsDecoder(res.barcodes[0].rawData)
                : res.barcodes[0].rawData;

              onScan(resultArray);
            }}
            cameraSettings={{ resolutionPreference: CameraSettings.ResolutionPreference.FULL_HD }}
            enableTapToFocus={true}
            enableTorchToggle={true}
            targetScanningFPS={30}
            // guiStyle={'viewfinder'}
            singleImageMode={{
              desktop: { always: false, allowFallback: true },
              mobile: { always: false, allowFallback: true }
            }}
            vibrateOnScan={true}
            onScanError={er => {BarcodePicker.destroy()}}
            // Picker options
            scanSettings={getScanSettings()}
            playSoundOnScan={true}
          />
        )}
      </div>
    </Fragment>
  );
};

export default PdfScanner;
