const schema = {
  settings: {
    updateQueryParameter: true,
    parseQueryParameter: true
  },
  fields: [

  ]
};

export { schema as default };
