import React, { useEffect, useState } from 'react';
import { EuiProgress } from '@elastic/eui';

import _ from 'lodash';

const HeaderProgress = ( props ) => {
  //console.log( 'header progress' );
  const [ body, setBody ] = useState( null );

  useEffect( () => {
    ( async () => {
      if ( props.isLoading ) {
        setBody( <EuiProgress size="m" color="primary" position="fixed" className="zIndex9001" /> );
      } else {
        setBody( null );
      }
    } )();
  }, [ props.isLoading ] );

  return body;

};

export { HeaderProgress };
