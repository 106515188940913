import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../js/api';
import { FieldParser, GenericLoading } from './helpers';
import { useError } from '../Error';

import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiDescriptionList,
  EuiTitle,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiButton,
  EuiLoadingSpinner
} from '@elastic/eui';
import {
  useParams,
  useLocation
} from 'react-router-dom';

import _ from 'lodash';
import pRetry from 'p-retry';

const IDesribeObj = ( { schema, id, close, type, size } ) => {


  const { t } = useTranslation();
  let { itemId } = useParams();

  const queryParams = new URLSearchParams( useLocation().search );

  if ( id ) {
    itemId = id;
  }

  //TODO: If b3ObjId is provided and b3Obj is null, then use B3 SDK to collect the object and render
  const [ title, setTitle ] = useState( t( 'GENERIC_LOADING' ) );
  const [ body, setBody ] = useState( <GenericLoading /> );
  const addError = useError();

  const retryUntilStatus = async ( itemId, reloadField, reloadValue ) => {
    const res = await API.describe( itemId, { skipCache: true } );

    const retryStatus = _.get( res.data, reloadField );

    if ( !reloadField || !retryStatus ) {
      return res;
    }
    if ( reloadValue.split( ',' ).includes( retryStatus ) ) {
      return res;
    }
    else {
      throw new Error( 'STILL_PROCESSING' );
    }
  };


  useEffect( () => {
    ( async () => {
      if ( itemId ) {
        let res;
        try {
          //Handle if reloadUntilActive is set.
          //Example ?reloadUntilActive=true&reloadField=payload.metadata.enrollmentStatus&reloadValues=ENROLLED,ERROR,loadingMsg=GENERIC_INPROCESS_MSG
          if ( queryParams.get( 'reloadUntilActive' ) == 'true' ) {
            setBody( <GenericLoading tilte={''} message={queryParams.get( 'loadingMsg' ) || 'GENERIC_PROCESSING_MSG'} /> );
            let pRetryFunc = async () => {
              return await retryUntilStatus( id, queryParams.get( 'reloadField' ), queryParams.get( 'reloadValues' ) );
            };
            res = await pRetry( pRetryFunc, {
              retries: Number( queryParams.get( 'retryCount' ) ) || 15,
              maxTimeout: Number( queryParams.get( 'retryTime' ) ) || 3000
            } );
            //console.log( 'res', res );
          } else {
            res = await API.describe( itemId, { skipCache: false } );
          }

          if ( res.data ) {

            let tmpItems = [];
            for ( let item of schema.fields ) {
              tmpItems.push( {
                title: t( item.i18nTitle ),
                description: <FieldParser field={item} data={res.data} />
              } );
            }
            if ( res.data[ schema.settings.title ] ) {
              // @ts-ignore

              setTitle( `${ res.data[ schema.settings.title ] } ${ t( schema.settings.i18nTitleSuffix ) }` );
            } else {
              setTitle( t( schema.settings.i18nTitleSuffix ) );
            }
            setBody( ( <EuiDescriptionList
              textStyle={schema.settings.textStyle}
              listItems={tmpItems}
            /> ) );

          }
        } catch ( er ) {
          console.log( er );
          addError( {
            title: er.message,
            color: 'danger',
            iconType: 'help'
          } );
        }
      };
    } )();
  }, [ itemId ] );

  let flyout = <EuiFlyout
    ownFocus
    onClose={close}
    size={size || 's'}
    aria-labelledby='flyoutSmallTitle'
  >
    <EuiFlyoutHeader hasBorder>
      <EuiTitle size='s'>
        <h2 id='flyoutSmallTitle'>{title}</h2>
      </EuiTitle>
    </EuiFlyoutHeader>
    <EuiFlyoutBody>
      {body}
    </EuiFlyoutBody>
  </EuiFlyout>;


  let modal = <EuiOverlayMask>
    <EuiModal onClose={close} initialFocus='[name=popswitch]'>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle >
      </EuiModalHeader>
      <EuiModalBody>
        {body}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={close}>Cancel</EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal >
  </EuiOverlayMask >;

  let Res = <> </>;
  if ( itemId ) {
    switch ( type ) {
      case 'flyout':
        Res = flyout;
        break;
      case 'modal':
        Res = modal;
        break;
      default:
        Res = flyout;
    }
  }

  return <Fragment>{Res}</Fragment>;
};

export { IDesribeObj as default, IDesribeObj };
