export default {
  settings: {
    maxDisplayLimit: 100,
    row: {
      selectable: false, //Map to isSelectable
      click: {
        type: 'iDescribeObject',
        view: 'flyout',
        schema: 'describeBorderLandingDetails',
        featureRequired: 'VIEW_LANDING'
      }
    },
    responsive: true, // Map to isResponsive
    defaultPageSize: 10, //Map to pageSize
    sortField: 'createdAt', //Map to sortField
    sortDirection: 'asc', //Map to sortDirection
    itemsExandable: false, // For future use BM-83
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'describeBorderLandingDetails'
    },
    actions: {
      name: 'Actions',
      actions: [ 'edit', 'archive' ]
    }
  },
  fields: [
    {
      field: 'id',
      i18nTitle: 'SYSTEM_ID',
      sortable: false,
      truncateText: false,
      type: 'text',
      showOnMobile: true //This resolve to the columns settings in table conf mobileOptions.show
    },
    {
      field: 'payload.given_name',
      i18nTitle: 'FULL_NAME',
      sortable: true,
      type: 'fullname',
      showOnMobile: true
    },
    {
      field: 'payload.id_type',
      i18nTitle: 'ID_TYPE',
      sortable: true,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'payload.id_number',
      i18nTitle: 'ID_NUMBER',
      sortable: false,
      type: 'text',
      showOnMobile: true
    },
    {
      field: 'payload.fees_scheme',
      i18nTitle: 'LC_FEE_SCHEME',
      sortable: true,
      type: 'text'
    },
    {
      field: 'payload.entry_port',
      i18nTitle: 'ENTRY_PORT',
      sortable: true,
      type: 'b3ObjectField'
    },
    {
      field: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'LC_ENTRY_DATE',
      sortable: true,
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a'
    }
  ]
};
