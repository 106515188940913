import React from 'react';
import PageLayout from '../../ui/PageLayout';
import {IListObjects} from '../../jsFnI';
import { useTranslation } from 'react-i18next';
import {listBorderLandings as listSchema, describeBorderLanding as describeSchema} from '../../../repository/schemas';

const query = {
  kind: 'borderEXCSEntry',
  project_id: 'o3WWDQHpek5dtbode',
  operator: 'and',
  query: [
    {
      field: 'status',
      operator: 'IN',
      value: ['active', 'private', 'unlisted']
    }
  ]
};

// const schema = {
//   settings: {
//     rowClick: 'flayout',
//     actionsColumn: {
//       name: 'Actions',
//       actions: ['edit', 'archive']
//     }
//   },
//   columns: [
//     {
//       field: 'payload.family_name',
//       name: 'Family Name',
//       sortable: true
//     },
//     {
//       field: 'payload.father_name',
//       name: 'Father Name',
//       sortable: true
//     },
//     {
//       field: 'payload.given_name',
//       name: 'Given Name',
//       sortable: true
//     }
//   ]
// };

const LandingList = () => {
  const { t } = useTranslation();
  return (
    <PageLayout title={t('Landing Query')} width='full'>
      <IListObjects query={query} listSchema={listSchema} describeSchema={describeSchema} />
    </PageLayout>
  );
};

export default LandingList;
