
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import H5AudioPlayer from 'react-h5-audio-player';
import api from '../../../../js/api';
import { EuiLoadingSpinner, EuiHealth } from '@elastic/eui';


import _ from 'lodash';




const AudioPlayer = ( { src, settings } ) => {

  const { t } = useTranslation();
  const [ render, setRender ] = useState( <EuiLoadingSpinner size='s' /> );



  const loadPlayer = async () => {


    if ( _.isEmpty( src ) ) {
      setRender( <EuiHealth color="danger" textSize="xs"> {t( 'PLAYER_ERROR_NOID' )} </EuiHealth> );

    } else {
      try {
        const a = await api.describeAttachment( src );

        setRender(
          <H5AudioPlayer
            src={a.data.payload.download.url}
            preload={'none'}
            showVolumeControl={_.get( settings, 'settings.volumeControl', false )}
            showLoopControl={_.get( settings, 'settings.volumeControl', false )}
            showSkipControls={_.get( settings, 'settings.volumeControl', false )}
            showJumpControls={_.get( settings, 'settings.volumeControl', false )}
            showDownloadProgress={_.get( settings, 'settings.volumeControl', false )}
            customAdditionalControls={[]}
            customVolumeControls={[]}
          />
        );
      } catch ( err ) {
        setRender( <EuiHealth color="danger" textSize="xs"> {t( 'PLAYER_ERROR' )} </EuiHealth> );
      }
    }


  };


  useEffect( () => {
    loadPlayer();
  }, [] );



  return ( render );

};

export { AudioPlayer };
