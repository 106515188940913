export default {
  settings: {
    textStyle: 'reverse',

    i18nTitle: '${referenceD} DETAILS',
    actions: [
      {
        id: 'action_close',
        data: 'id',
        i18nTitle: 'CLOSE_VIEW',
        type: 'action_close'
      },
      {
        id: 'action_archive',
        data: 'id',
        i18nTitle: 'ARCHIVE_ITEM',
        icon: 'trash',
        sortable: false,
        type: 'action_archive',
        featuredRequired: 'ARCHIVE_LANDING'
      }
    ]
  },
  fields: [
    {
      field: 'id',

      data: '${id}',
      i18nTitle: 'SYSTEM_ID'
    },
    {
      field: 'payload.given_name',

      data: '${payload.family_name} ${payload.father_name} ${payload.given_name}',
      i18nTitle: 'FULL_NAME',
      type: 'fullname'
    },
    {
      field: 'payload.id_type',

      data: '${payload.id_type}',
      i18nTitle: 'ID_TYPE',
      type: 'text',
    },
    {
      field: 'payload.id_number',

      data: '${payload.id_number}',
      i18nTitle: 'ID_NUMBER',
      type: 'text',
    },
    {
      field: 'payload.fees_scheme',
      data: 'payload.fees_scheme',
      i18nTitle: 'LC_FEE_SCHEME',
      type: 'b3ObjectField',

      display_field: '${payload.name}',

    },
    {
      field: 'payload.entry_port',
      data: 'payload.entry_port',
      i18nTitle: 'ENTRY_PORT',
      type: 'b3ObjectField',

      display_field: '${payload.name}',
    },
    {
      id: 'createdAt',
      data: 'createdAt',
      i18nTitle: 'LC_ENTRY_DATE',
      type: 'formatedDate',
      format: 'MMMM Do YYYY, h:mm:ss a',
    }
  ]
};
