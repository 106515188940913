import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect as RRedirect, useLocation } from 'react-router-dom';
import _ from 'lodash';

const useRedirect = () => {
  const dispatch = useDispatch();
  return body => dispatch( setRedirect( body ) );
};

const setRedirect = ( payload ) => {
  return { type: 'SET_REDIRECT', payload };
};

const GetQueryParameter = () => {
  // Using the router location result in location upon document first render.
  // const  queryParams = new URLSearchParams(useLocation().search);
  const queryParams = new URLSearchParams( window.location.href );
  return ( name ) => {
    let queryValue = queryParams.get( name );
    if ( queryValue ) {
      queryValue = queryValue.replace( '+', ' ' );
    }
    return queryValue;
  };
};

const useQuery = () => {
  const dispatch = useDispatch();
  // return (action: 'set' | 'add' | 'remove', name: String, value?: String) => dispatch(setRedirect({ action: action, name: name, value: value }));
  return ( action, name, value ) => dispatch( setRedirect( { action: action, name: name, value: value } ) );
};

const Redirect = ( props ) => {
  const dispatch = useDispatch();
  let redirect = useSelector( state => state.redirect );

  const queryParams = new URLSearchParams( useLocation().search );

  if ( _.isEmpty( queryParams.entries() ) ) {
    queryParams.set( 'pi', 'pie' );
  }

  if ( _.isObject( redirect ) ) {
    switch ( redirect[ 'action' ] ) {
      case 'set':
        queryParams.set( redirect[ 'name' ], redirect[ 'value' ] );
        break;
    }
    redirect = './?' + queryParams.toString();
  }

  if ( redirect ) {
    return (
      <RRedirect to={redirect} push />
    );
  } else {
    return <></>;
  };
};

export { Redirect as default, Redirect, useRedirect, setRedirect, useQuery, GetQueryParameter };
