import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import
{
  BrowserRouter,
  Switch,
  Route,
  Link,
  useParams,
  useLocation
} from 'react-router-dom';

import { setUser, setQueryList } from './js/actions/index';
import App from './App';
import * as serviceWorker from './serviceWorker';

import store from './jas/store';

import ErrorBoundary from './components/ErrorBoundary';

import './js/i18n';


store
  .dispatch( setUser() )
  .then( store.dispatch( setQueryList() ) )
  .then( () =>
  {
    ReactDOM.render(
      <BrowserRouter>
        <Provider store={store}>
          <ErrorBoundary><App /></ErrorBoundary>
        </Provider>
      </BrowserRouter>,
      document.getElementById( 'root' )
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register();
  } )
  .catch( er =>
  {
    //console.log(er.code);
    //console.log('er.code');
  } );

function getUrlVars ()
{
  var vars = {};
  window.location.href.replace( /[?&]+([^=&]+)=([^&]*)/gi, function (
    m,
    key,
    value
  )
  {
    vars[ key ] = value;
  } );
  return vars;
}
