const schema = {
  settings: {
    i18nTitle: 'NEW_LIVE_DISTRIBUTION',
    refreshOnCreate: false,
    showOnCreate: true,
    reloadUntilActive: false,
    responsive: true, // Map to isResponsive
    itemsExpanableSettings: {
      type: 'iDescribeObject',
      scheme: 'none'
    },
    b3Object: {
      type: 'simple', // Can be advanced which will show more options in the form
      kind: 'avLiveHLStream',
      reference: '$.metadata.reference',
      defaultClassification: 'confidential',
      status: 'active'
    }
  },
  fields: [
    {
      name: [ 'payload', 'metadata', 'reference' ],
      type: 'text',
      i18nLabel: 'GENERIC_REFERENCE',
      i18nHelp: 'GENERIC_REFERENCE_HELP',
      i18nPlaceHolder: 'GENERIC_REFERENCE_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'source', 'url' ],
      type: 'text',
      i18nLabel: 'AV_SOURCE_URL',
      i18nHelp: 'AV_SOURCE_URL_HELP',
      i18nPlaceHolder: 'AV_SOURCE_URL_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'metadata', 'customPlayPath' ],
      type: 'text',
      i18nLabel: 'AV_CUSTOM_PLAYPATH',
      i18nHelp: 'AV_CUSTOM_PLAYPATH_HELP',
      i18nPlaceHolder: 'AV_CUSTOM_PLAYPATH_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },

    {
      name: [ 'payload', 'proxy', 'host' ],
      type: 'text',
      i18nLabel: 'PROXY_HOST',
      i18nHelp: 'PROXY_HOST_HELP',
      i18nPlaceHolder: 'PROXY_HOST_HELP_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'proxy', 'port' ],
      type: 'number',
      i18nLabel: 'PROXY_PORT',
      i18nHelp: 'PROXY_PORT_HELP',
      i18nPlaceHolder: 'PROXY_PORT_HELP_PLACEHOLDER',
      default: '',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'cacheSettings', 'defaultTTL' ],
      type: 'number',
      i18nLabel: 'CACHE_DEFAULT_TTL',
      i18nHelp: 'CACHE_DEFAULT_TTL_HELP',
      i18nPlaceHolder: 'DEFAULT_TTL_PLACEHOLDER',
      default: '300',
      showOnMobile: true
    },
    {
      name: [ 'payload', 'cacheSettings', 'distributionClass' ],
      type: 'select',
      i18nLabel: 'CACHE_DISTRIBUTION_NETWORK',
      i18nHelp: 'CACHE_DISTRIBUTION_NETWORK_HELP',
      i18nPlaceHolder: '',
      default: 'premium',
      showOnMobile: true,
      listSettings: {
        allowExclusions: true,
        options: [
          {
            i18nTitle: 'PREMIUM',
            value: 'premium',
          },
          {
            i18nTitle: 'STANDARD',
            value: 'standard',
          },
          {
            i18nTitle: 'PREMIUM_PROXIED',
            value: 'premium-proxied',
          },
          {
            i18nTitle: 'STANDARD_PROXIED',
            value: 'standard-proxied',
          }
        ]
      }
    },
    {
      name: [ 'payload', 'metadata', 'tags' ],
      type: 'comboBox',
      i18nLabel: 'ANNOTATIONS',
      i18nHelp: 'ANNOTATIONS_HELP',
      i18nPlaceHolder: 'TYPE_TO_SEARCH',
      default: [],
      listSettings: {
        allowExclusions: true,
        class: 'noFlexWrap',
        type: 'remote',
        singleSelection: false,
        query: {
          kind: 'entityV2',
          project_id: 'oZgcir8gpkar291tp_8Uu',
          orderBy: 'payload.bsLevel desc',
          operator: 'and',
          //collapse: 'payload.entity',
          query: [
            {
              field: 'status',
              operator: 'IN',
              value: [
                'active',
                'private',
                'unlisted'
              ]
            },
            {
              field: 'payload.name',
              operator: 'multiBf*',
              value: '{{value}}'
            }
          ]
        }
      },
      showOnMobile: true
    },
    {
      name: [ 'payload', 'source', 'headers' ],
      type: 'textarea',
      i18nLabel: 'CUSTOM_HEADERS',
      i18nHelp: 'CUSTOM_HEADER_HELP',
      i18nPlaceHolder: 'CUSTOM_HEADERS_PLACEHOLDER',
      default: '',
      showOnMobile: true
    }
  ]
};

export { schema as default };
